import React, { useState } from 'react'
import MinusCircularIcon from '../icons/MinusCircularIcon'
import AddCircularIcon from '../icons/AddCircularIcon'
import { cn } from '../../../../lib/utils'

const QuntityHandler = ({ className }) => {
  const [count, setCount] = useState(0)
  return (
    <div className={cn('w-[80px] flex items-center gap-2 justify-between', className)}>
      <button className={`${count === 0 ? "cursor-not-allowed pointer-events-none" : ""}`} onClick={() => setCount(count === 0 ? 0 : count - 1)}>
        <MinusCircularIcon className="w-5 h-5 md:w-6 md:h-6 text-primary1" />
      </button>
      <input className='text-base text-blue-950' type='button' value={count} />
      <button onClick={() => setCount(count + 1)}>
        <AddCircularIcon className="w-5 h-5 md:w-6 md:h-6 text-primary1" />
      </button>
    </div>
  )
}

export default QuntityHandler