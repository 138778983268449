import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { cn } from '../../../../lib/utils';
import Card from '../Card';
import StarIcon from '../icons/StarIcon';
import SkilledBadgeIcon from '../icons/badge-icon/SkilledBadgeIcon';
import StudentBadgeIcon from '../icons/badge-icon/StudentBadgeIcon';
import TrustedBadgeIcon from '../icons/badge-icon/TrustedBadgeIcon';
import CustomTabs from '../tabs/CustomTabs';
import SliderTabs from '../tabs/SliderTabs';
import AboutUsDeatils from './AboutUsDeatils';
import BadgeSlider from './BadgeSlider';
import HairTypeList from './HairTypeList';
import JobDetails from './JobDetails';
import PortfolioDetails from './PortfolioDetails';
import Rating from './Rating';
import RatingList from './RatingList';
import ReviewDetails from './ReviewDetails';
import SalonDetails from './SalonDetails';
import SalonSlider from './SalonSlider';
import WorkHoursDetails from './WorkHoursDetails';
import { useQuery } from 'react-query';
import Http from '../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../api/axios';
import { useState } from 'react';


const SalonCard = ({ t, hasHairList,salonInfo, badgeGroupClassName, salonId,iconClass, badgeListData, tabsData, phoneIcon2, heartIcon, badgeTextClass, className, cardTitleClass, ratingItemClassName, ratingTextClassName, hairGroupClassName, hairTitleMainClassName, hairTitleClassName, hairListGropuClassName, hairListMainClassName, hairListItemClassName, cTabGroupClass, cTabListClass, cTabButtonClass, cTabItemClass, cTabPanelsClass, cTabselectedItemClass, sTabGroupClass, sTabListClass, sTabButtonClass, sTabPanelsClass, sTabItemClass, sTabselectedItemClass, sTabSliderClass, badgeSliderBtnClassName,handelClickEvent, colored, ...props }) => {
    
    const [] = useState();
    const { data: fetchedSalonData, isLoading, isError } = useQuery(
        ["salonData", salonId],
        async () => {
            if (salonId) {
                const response = await Http.post(PROFILE_COMPLETE_URL.workerProfile, {
                    salon_id: salonId,
                });
                return response?.data?.data;
            }
        },
        {
            enabled: !salonInfo && !!salonId, // Only run if salonInfo is not provided and salonId is available
            refetchOnWindowFocus: false,
        }
    );

    // Determine which salon data to use
    const salonData = salonInfo || fetchedSalonData; 

      const tabData = tabsData ?? [
        {
            id: "1",
            title: 'Job',
            content: <JobDetails />
        },
        {
            id: "2",
            title: 'About Us',
            content: <AboutUsDeatils salonData={salonData} />
        },
        {
            id: "3",
            title: 'Work Hours',
            content: <WorkHoursDetails />
        },
        // {
        //     id: "2",
        //     title: 'Reviews',
        //     content: <ReviewDetails item={3} />
        // },
        // {
        //     id: "2",
        //     title: 'Portfolio',
        //     content: <PortfolioDetails />
        // },
    ];

    const BadgeList = (salonData?.salon_badges?.length > 0 ? salonData.salon_badges.map((badges, ind) => {
        return {
            id: badges.id.toString(),
            title:badges?.badge?.name,
            badge: badges?.badge?.image ? <img src={badges.badge.image} className='h-[76px] w-[76px]' alt={badges.badge.name} /> : null
        };
    }) : []);

    const resTabData = tabsData ?? [
        {
            id: "1",
            title: 'Profile',
            content: <SalonDetails salonData={salonData}/>
        },
        {
            id: "2",
            title: 'Job',
            content: <JobDetails />
        },
        {
            id: "3",
            title: 'About Us',
            content: <AboutUsDeatils salonData={salonData} />
        },
        {
            id: "4",
            title: 'Reviews',
            content: <ReviewDetails item={3} />
        },
        {
            id: "5",
            title: 'Portfolio',
            content: <PortfolioDetails item={3} />
        },
        {
            id: "6",
            title: 'HairTypes',
            content: <HairTypeList />
        },
    ];

    return (
        <Card className={cn('sm:px-3 sm:pt-2.5 sm:pb-3.5', className)}>
            <div className="grid grid-cols-7 gap-3 mb-2.5">
                <div className="lg:col-span-4 col-span-7">
                    <div className="flex items-center justify-between mb-2">
                        <h2 className={cn('text-[20px] text-blue-950/80 font-bold', cardTitleClass)}>{salonData?.business_name}</h2>
                        <div className="md:hidden flex item center gap-1.5">
                            <span className='-mt-1'><StarIcon className='h-6 w-6 text-success-800' /></span>
                            <p className='text-blue-950 text-base'>4.5/5 (12K.)</p>
                        </div>
                    </div >
                    <div className="grid md:grid-cols-4 grid-cols-1 gap-3 h-[calc(100%-35px)]">
                        <div className="col-span-2">
                            <SalonSlider handelClickEvent={handelClickEvent} heartIcon={heartIcon} salonData={salonData} />
                        </div>
                        <div className="col-span-2">
                            <SalonDetails iconClass={iconClass} className={`md:block hidden ${colored && "bg-light-800"}`} phoneIcon2={phoneIcon2} salonData={salonData} />
                            <SliderTabs
                                tabsData={resTabData}
                                tabGroupClass={cn("md:hidden block", sTabGroupClass)}
                                tabListClass={cn(sTabListClass)}
                                tabButtonClass={cn("!p-1 sm:text-[15px] text-[14px] text-success-900", sTabButtonClass)}
                                tabPanelsClass={cn(sTabPanelsClass)}
                                tabItemClass={cn(sTabItemClass)}
                                selectedItemClass={cn(sTabselectedItemClass)}
                                tabSliderClass={cn(sTabSliderClass)}
                            />
                        </div>
                    </div>
                </div >
                <div className="lg:col-span-3 col-span-7 md:block hidden">
                    <div className="grid grid-cols-12 gap-2.5 h-full">
                        <CustomTabs
                            tabsData={tabData}
                            tabGroupClass={cn("h-full", hasHairList ? "col-span-8 xxl:col-span-9" : "col-span-12", cTabGroupClass)}
                            tabListClass={cn("border border-success-950 mb-2 sm:w-[80%] w-full mx-auto", cTabListClass)}
                            tabButtonClass={cn("!p-1 sm:text-[15px] text-[14px]", cTabButtonClass)}
                            tabItemClass={cn("h-full", cTabItemClass)}
                            tabPanelsClass={cn("h-[calc(100%-35px)]", cTabPanelsClass)}
                            selectedItemClass={cTabselectedItemClass}
                        />
                        {
                            hasHairList && <HairTypeList
                                className={cn("col-span-4 xxl:col-span-3", hairGroupClassName)}
                                titleMainClassName={hairTitleMainClassName}
                                titleClassName={hairTitleClassName}
                                listGropuClassName={hairListGropuClassName}
                                listMainClassName={hairListMainClassName}
                                listItemClassName={hairListItemClassName}
                                colored={colored}
                                hairData={Array.from(new Set(salonData?.workers?.flatMap(worker => worker.hair_type).map(hair => JSON.stringify(hair)))).map(hair => JSON.parse(hair))}
                                handelClickEvent={false}
                            />
                        }
                    </div >
                </div >
            </div>
            <div className="grid lg:grid-cols-7 grid-cols-1 gap-2.5 md:gap-3">
                {
                    hasHairList && <div className="md:hidden"><HairTypeList
                        className={cn(hairGroupClassName)}
                        titleMainClassName={hairTitleMainClassName}
                        titleClassName={hairTitleClassName}
                        listGropuClassName={hairListGropuClassName}
                        listMainClassName={hairListMainClassName}
                        listItemClassName={hairListItemClassName}
                        colored={colored}
                    /></div>
                }
                <div className="lg:col-span-4">
                    <div className="grid md:grid-cols-4 grid-cols-1 gap-3 h-[calc(100%-35px)]">
                        <div className="md:col-span-2 md:block hidden">
                        <Rating readOnly selected={4} rate={salonData?.rating} wrapperClass={`${colored && "bg-light-800"}`} salonData={salonData}/>
                        </div>
                        <div className="md:col-span-2">
                            <RatingList textClassName={cn("gap-0.5", ratingTextClassName)} itemClassName={cn("gap-0.5 xl:gap-1.5", ratingItemClassName)} groupClassName={`${colored && "bg-light-800"}`} rate={salonData?.rating} salonData={salonData}/>
                        </div>
                    </div >
                </div >
                <div className="lg:col-span-3">
                {Array.isArray(BadgeList) && BadgeList.length > 0 && (
                    <BadgeSlider badgelist={BadgeList} badgeGroupClassName={cn(`${colored && "bg-light-800"}`, badgeGroupClassName)} badgeTextClass={badgeTextClass} btnClassName={badgeSliderBtnClassName} salonData={salonData}/>
                )}
                </div>
            </div>
        </Card >
    );
}

export default withTranslation()(SalonCard);
