import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../uiComponents/inputs/LabelWrap';
import InputCheckbox from '../uiComponents/inputs/InputCheckbox';
import RowWrap from '../uiComponents/RowWrap';
import Badge from '../uiComponents/common/Badge';
import InputCheckBoxGroup from '../uiComponents/inputs/InputCheckBoxGroup';
import Divider from '../uiComponents/Divider';
import { DatePicker } from 'antd';
import CalenderIcon from '../uiComponents/icons/CalenderIcon';
import InputWithIcon from '../uiComponents/inputs/InputWithIcon';
import RightArrowIcon from '../uiComponents/icons/RightArrowIcon';
import BottomArrowIcon from '../uiComponents/icons/BottomArrowIcon';
import CountHandler from '../uiComponents/common/CountHandler';
import BadgeEarnedFilter from '../uiComponents/common/BadgeEarnedFilter';
import TrustedBadgeIcon from '../uiComponents/icons/badge-icon/TrustedBadgeIcon';
import StudentBadgeIcon from '../uiComponents/icons/badge-icon/StudentBadgeIcon';
import SkilledBadgeIcon from '../uiComponents/icons/badge-icon/SkilledBadgeIcon';
import ServiceFilterCategoryModal from './ServiceFilterCategoryModal';
import dayjs from 'dayjs';
import InfoFIllIcon from '../uiComponents/icons/InfoFIllIcon';
import BadgeInfoModal from './BadgeInfoModal';
import DateInput from '../uiComponents/inputs/DateInput';
import TimeInput from '../uiComponents/inputs/TimeInput';


const ServicesFilterFindSalon = ({ t, modalOpen, setModalOpen, ...props }) => {

    // const [calenderModalOpen, setCalenderModalOpen] = useState(false)
    const [ServiceFilterCategoryModalOpen, setServiceFilterCategoryModalOpen] = useState(false)
    const [categorySelectItems, setCategorySelectItems] = useState([])


    const [badgeInfoModalOpen, setBadgeInfoModalOpen] = useState(false)


    // --------------------------------checkbox group------------------------------
    const [hairTypeSelectItems, setHairTypeSelectItems] = useState([])
    const [providerType, setProviderType] = useState([])
    const [educationLevelList, setEducationLevelList] = useState([])
    const [pricingList, setPricingList] = useState([])
    const [discount, setDiscount] = useState([])
    const [salonInfo, setSalonInfo] = useState([])
    const [selectedRating, setSelectedRating] = useState([])
    const [selectedBadgeEarned, setSelectedBadgeEarned] = useState([])



    const [menCount, setmenCount] = useState(0)
    const [womenCount, setwomenCount] = useState(0)
    const [boyCount, setboyCount] = useState(0)
    const [girlCount, setgirlCount] = useState(0)

    const [count, setCount] = useState(0)



    // date-time
    const format = 'HH:mm';
    const [time, setTime] = useState(0)




    const handleModalClose = () => {
        if (!ServiceFilterCategoryModalOpen && !badgeInfoModalOpen) {
            // --------------------------------checkbox group to default value------------------------------
            setHairTypeSelectItems([])
            setProviderType([])
            setEducationLevelList([])
            setPricingList([])
            setDiscount([])
            setSalonInfo([])
            setSelectedRating([])
            setSelectedBadgeEarned([])

            setCount(0)
            setmenCount(0)
            setwomenCount(0)
            setboyCount(0)
            setgirlCount(0)

            //------close modal------
            setModalOpen(false);
        }
    }


    const HairType = [
        { label: "Straight" },
        { label: "Afro" },
        { label: "Thick" },
        { label: "Curl" },
        { label: "Kinky" },
        { label: "Thin" },
    ]

    const HairStyle = [
        { label: "Short" },
        { label: "Medium" },
        { label: "OldScool" },
        { label: "Trendy" },
        { label: "Kinky" },
        { label: "Elegnt" },
    ]



    const badgeEarnedData = [
        {
            id: "badge_id_1",
            title: "Trusted",
            icon: <TrustedBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(1 K.)'
        },
        {
            id: "badge_id_2",
            title: "Top Rated",
            icon: <StudentBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(57)'
        },
        {
            id: "badge_id_3",
            title: "Advisor",
            icon: <SkilledBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(23)'
        },
        {
            id: "badge_id_4",
            title: "Skilled",
            icon: <TrustedBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(108)'
        },
        {
            id: "badge_id_5",
            title: "Very Skilled",
            icon: <StudentBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(12)'
        },
        {
            id: "badge_id_6",
            title: "Top Skilled",
            icon: <SkilledBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(88)'
        },
        {
            id: "badge_id_7",
            title: "Relax & Enjoy",
            icon: <TrustedBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(44)'
        },
        {
            id: "badge_id_8",
            title: "Student",
            icon: <SkilledBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(0)'
        },
        {
            id: "badge_id_9",
            title: "Exclusive",
            icon: <TrustedBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(23)'
        },
        {
            id: "badge_id_10",
            title: "Organic",
            icon: <StudentBadgeIcon className="h-[60px] w-[60px]" />,
            count: '(23)'
        }
    ]


    const handleOnChangeTime = (time) => {
        if (time) {
            setTime(dayjs(time).format('HH:mm'));
        } else {
            setTime("")
        }
    }


    return (
        <>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" className={`relative z-[999]`} onClose={handleModalClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-dark-950 bg-opacity-70"
                        />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto" >
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-[1150px]  transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                    <div className="modal-header mb-6">
                                        <h2 className="font-semibold text-xl text-center text-blue-950">{t('services_filter_modal.title')}</h2>
                                        <button
                                            type={"button"}
                                            aria-label="button"
                                            className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                            onClick={handleModalClose}>
                                            <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {/* --------------------About you----------------------------- */}
                                        <div className='w-full'>
                                            <Badge groupClassName={"border-0 text-lg !p-0 mb-2.5"} text={t('services_filter_modal.about_you')} />
                                            <RowWrap className="flex gap-5 flex-col lg:flex-row">
                                                <div className="meetime-form-group ">
                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.gender')} />
                                                    <div className='grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-4 gap-3'>
                                                        <div className="meetime-form-group flex  px-2.5 border border-success-100 rounded-lg py-1.5 justify-center items-center gap-3 ">
                                                            <LabelWrap labelClassName=" w-full text-[15px] mb-0 font-medium" label={t('comman_text.men')} />
                                                            <CountHandler setCount={setmenCount} count={menCount} countClassName="w-[20px]" />
                                                        </div>
                                                        <div className="meetime-form-group flex  px-2.5 border border-success-100 rounded-lg py-1.5 justify-center items-center gap-3 ">
                                                            <LabelWrap labelClassName=" w-full text-[15px] mb-0 font-medium" label={t('comman_text.woman')} />
                                                            <CountHandler setCount={setwomenCount} count={womenCount} countClassName="w-[20px]" />
                                                        </div>
                                                        <div className="meetime-form-group flex  px-2.5 border border-success-100 rounded-lg py-1.5 justify-center items-center gap-3 ">
                                                            <LabelWrap labelClassName=" w-full text-[15px] mb-0 font-medium" label={t('comman_text.boy')} />
                                                            <CountHandler setCount={setboyCount} count={boyCount} countClassName="w-[20px]" />
                                                        </div>
                                                        <div className="meetime-form-group flex  px-2.5 border border-success-100 rounded-lg py-1.5 justify-center items-center gap-3 ">
                                                            <LabelWrap labelClassName=" w-full text-[15px] mb-0 font-medium" label={t('comman_text.girl')} />
                                                            <CountHandler setCount={setgirlCount} count={girlCount} countClassName="w-[20px]" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="meetime-form-group w-full max-w-full md:max-w-[228px] ">
                                                    <LabelWrap labelClassName="w-full text-[15px] font-medium" label={t('form_input_label.service_category')} />
                                                    <ButtonPrimary className={"rounded-md py-1 md:py-1 text-[15px] lg:text-[15px] w-full"} onClick={() => setServiceFilterCategoryModalOpen(true)} size="sm" >{t('form_input_label.selected')}({categorySelectItems.length})</ButtonPrimary>
                                                </div>
                                            </RowWrap>
                                            <RowWrap className="grid  grid-cols-1 md:grid-cols-2  gap-5">
                                                <div className="meetime-form-group w-full mt-2.5" >
                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label="Hair Style" />
                                                    <div className='block'>
                                                        {
                                                            HairStyle.length > 0 ?
                                                                <div>
                                                                    <InputCheckBoxGroup
                                                                        selectValue={hairTypeSelectItems}
                                                                        itemClassName={"lg:text-[15px] text-[15px] w-[80px]  text-start"}
                                                                        setCheckValue={(HairTypeId) => setHairTypeSelectItems(HairTypeId)}
                                                                        data={HairStyle}
                                                                        groupClassName={"gap-5 gap-y-2  w-fit flex flex-wrap border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>
                                                                :
                                                                <span className="text-text_light_gray text-16 text-center">
                                                                    {t('services_filter_modal.hair_type_not_available')}
                                                                </span>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="meetime-form-group w-full mt-2.5" >
                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.hair_type')} />
                                                    <div className='block'>
                                                        {
                                                            HairType.length > 0 ?
                                                                <div>
                                                                    <InputCheckBoxGroup
                                                                        selectValue={hairTypeSelectItems}
                                                                        itemClassName={"lg:text-[15px] text-[15px] w-[80px]  text-start"}
                                                                        setCheckValue={(HairTypeId) => setHairTypeSelectItems(HairTypeId)}
                                                                        data={HairType}
                                                                        groupClassName={"gap-5 gap-y-2  w-fit flex flex-wrap border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>
                                                                :
                                                                <span className="text-text_light_gray text-16 text-center">
                                                                    {t('services_filter_modal.hair_type_not_available')}
                                                                </span>
                                                        }
                                                    </div>
                                                </div>

                                            </RowWrap>
                                        </div>


                                        <Divider className={"my-4"} />


                                        {/* ------------------------------salon provider---------------------------- */}
                                        <Disclosure>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button className=" flex justify-center items-center gap-3 mb-2.5">
                                                        <Badge groupClassName={"border-0 text-lg !p-0 mb-0"} text={t('services_filter_modal.about_salon_provider')} />
                                                        <BottomArrowIcon
                                                            className={`${open ? 'rotate-180 transform' : ''
                                                                } h-4 w-4 text-blue-950`}
                                                        />
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel>
                                                        <div className='w-full'>

                                                            <RowWrap className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-3 sm:gap-5 ">
                                                                <div className="meetime-form-group  col-span-1 lg:col-span-2">
                                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('comman_text.provider_type')} />
                                                                    <InputCheckBoxGroup
                                                                        selectValue={providerType}
                                                                        itemClassName={"text-[15px] lg:text-[15px] justify-between  text-start"}
                                                                        setCheckValue={(providerTypeList) => setProviderType(providerTypeList)}
                                                                        data={[
                                                                            { label: t('comman_text.professional') },
                                                                            { label: t('comman_text.student') },
                                                                        ]}
                                                                        groupClassName={"gap-3 flex justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>
                                                                <div className="meetime-form-group col-span-1 lg:col-span-2">
                                                                    <LabelWrap labelClassName="text-[15px] w-full text-sm font-medium" label={t('comman_text.education')} />
                                                                    <InputCheckBoxGroup
                                                                        selectValue={educationLevelList}
                                                                        itemClassName={"justify-between  text-start"}
                                                                        setCheckValue={(educationList) => setEducationLevelList(educationList)}
                                                                        data={[
                                                                            { label: t('comman_text.low') },
                                                                            { label: "Medium" },
                                                                            { label: t('comman_text.high') },
                                                                        ]}
                                                                        groupClassName={"gap-3 flex justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>
                                                                <div className="meetime-form-group col-span-1">
                                                                    <LabelWrap labelClassName="text-[15px] w-full text-sm font-medium" label={t('form_input_label.pricing')} />
                                                                    <InputCheckBoxGroup
                                                                        selectValue={pricingList}
                                                                        itemClassName={"justify-between  text-start"}
                                                                        setCheckValue={(pricings) => setPricingList(pricings)}
                                                                        data={[
                                                                            { label: "$" },
                                                                            { label: "$$" },
                                                                            { label: "$$$" },
                                                                        ]}
                                                                        groupClassName={"gap-2 flex justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>
                                                                <div className="meetime-form-group col-span-1">
                                                                    <InputWithIcon
                                                                        labelClassName="text-[15px] font-medium"
                                                                        label={t('form_input_label.on_the_go_price')}
                                                                        placeholder={"Max transport price"}
                                                                        size="sm"
                                                                        className="rounded-md bg-light-800  h-[34px]"
                                                                        suffixWrap="Kr."
                                                                    />
                                                                </div>




                                                                <div className="meetime-form-group col-span-1 lg:col-span-2">
                                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label="Date & Time" />
                                                                    <div className='flex gap-x-5 w-full'>
                                                                        <DateInput groupClassName={"w-full"} labelClassName="text-[15px]" className="max-w-full " size="sm" />
                                                                        <TimeInput groupClassName={"w-full"} labelClassName="text-[15px]" className="max-w-full " size="sm" />
                                                                    </div>
                                                                </div>
                                                                <div className="meetime-form-group col-span-1 lg:col-span-2">
                                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.discount')} />
                                                                    <InputCheckBoxGroup
                                                                        selectValue={discount}
                                                                        itemClassName={"justify-between  text-start"}
                                                                        setCheckValue={(discountList) => setDiscount(discountList)}
                                                                        data={[
                                                                            { label: t('check_box_label.student_card') },
                                                                            { label: t('check_box_label.deals') },
                                                                            { label: t('check_box_label.senior') },
                                                                        ]}
                                                                        groupClassName={"gap-3 flex justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>
                                                                <div className="meetime-form-group col-span-1 lg:col-span-2">
                                                                    <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.salon_info')} />
                                                                    <InputCheckBoxGroup
                                                                        selectValue={salonInfo}
                                                                        itemClassName={"justify-between  text-start"}
                                                                        setCheckValue={(salonInfoList) => setSalonInfo(salonInfoList)}
                                                                        data={[
                                                                            { label: t('check_box_label.wheelchair_friendly') },
                                                                            { label: t('check_box_label.child_friendly') },
                                                                        ]}
                                                                        groupClassName={"gap-3 flex justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                                                    />
                                                                </div>



                                                                <div className="meetime-form-group col-span-1">
                                                                    <InputWithIcon
                                                                        disabled
                                                                        labelClassName="text-[15px] font-medium"
                                                                        label={t('form_input_label.offer_request')}
                                                                        size="sm"
                                                                        tooltipText={t('tooltip_text.offer_request_information')}
                                                                        className="rounded-md bg-light-800  h-[34px]"
                                                                        tooltipContentClass="z-[99999]"
                                                                        prefixWrap={
                                                                            <InputCheckbox
                                                                                id="Yes"
                                                                                text="Yes"
                                                                                className={"peer-checked:text-blue-950"}
                                                                            />
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="meetime-form-group col-span-1">
                                                                    <InputWithIcon
                                                                        labelClassName="text-[15px] font-medium"
                                                                        label={t('form_input_label.offer_request_budget')}
                                                                        placeholder={"Max budget"}
                                                                        size="sm"
                                                                        className="rounded-md bg-light-800  h-[34px]"
                                                                        tooltipContentClass="z-[99999]"
                                                                        suffixWrap="Kr."
                                                                    />
                                                                </div>

                                                            </RowWrap>



                                                            <Divider className={"my-4"} />


                                                            {/* -----------minimum rating--------------- */}
                                                            <div className=' my-2.5'>
                                                                <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.minimum_rating')} />
                                                                <RowWrap className="flex flex-col md:flex-row gap-y-2.5 border border-success-100 rounded-md py-1.5 px-2.5 gap-x-3 sm:gap-x-5 md:gap-x-6 " md={2}>
                                                                    <div className="meetime-form-group  px-2.5">
                                                                        <CountHandler setCount={setCount} count={count} star={true} showMaxValue={true} maxValue={5} />
                                                                    </div>
                                                                    <div className="meetime-form-group flex-grow w-full">
                                                                        <InputCheckBoxGroup
                                                                            selectValue={selectedRating}
                                                                            itemClassName={"justify-between  text-start"}
                                                                            setCheckValue={(ratingList) => setSelectedRating(ratingList)}
                                                                            data={[
                                                                                { label: t('check_box_label.service') },
                                                                                { label: t('check_box_label.quality_price') },
                                                                                { label: t('check_box_label.salon') },
                                                                                { label: t('check_box_label.worker') },
                                                                                { label: t('check_box_label.atmosphere') },
                                                                            ]}
                                                                            groupClassName={"gap-3 w-full grid grid-cols-2 xsm:grid-cols-3 sm:flex justify-between px-2.5"}
                                                                        />
                                                                    </div>

                                                                </RowWrap>
                                                            </div>



                                                            {/* -----------Badges list--------------- */}
                                                            <div>
                                                                <LabelWrap labelClassName=" w-full text-[15px] font-medium"
                                                                    className={"flex gap-2"}
                                                                    label={
                                                                        <>
                                                                            {t('form_input_label.badges_earned')}
                                                                            < button onClick={() => setBadgeInfoModalOpen(true)}>
                                                                                <InfoFIllIcon className="h-5 w-5" />
                                                                            </button>
                                                                        </>
                                                                    } />
                                                                <BadgeEarnedFilter checkStart badgeEarnedList={badgeEarnedData} checkedList={selectedBadgeEarned} setCheckedList={setSelectedBadgeEarned} />
                                                            </div>
                                                        </div>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>


                                        <ButtonPrimary size="sm" className="w-full mt-2" onClick={() => setModalOpen(false)}>
                                            {t('button_label.apply_filter')}</ButtonPrimary>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <ServiceFilterCategoryModal
                modalOpen={ServiceFilterCategoryModalOpen}
                setModalOpen={setServiceFilterCategoryModalOpen}
                categorySelectItems={categorySelectItems}
                setCategorySelectItems={setCategorySelectItems}
            />

            <BadgeInfoModal
                modalOpen={badgeInfoModalOpen}
                setModalOpen={setBadgeInfoModalOpen}
            />
        </>
    )
}

export default withTranslation()(ServicesFilterFindSalon)