import { Form } from 'antd';
import dayjs from "dayjs";
import { FormikProvider } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import Http from '../../../../../../Http';
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { authStore } from '../../../../../contexts/AuthProvider';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import Card from '../../../../uiComponents/Card';
import AddHoursListTable from '../../../../uiComponents/common/AddHoursListTable';
import AvailabilityCalendar from '../../../../uiComponents/common/AvailabilityCalendar';
import CloseFillIcon from '../../../../uiComponents/icons/CloseFillIcon';
import RoundFillCheckIcon from '../../../../uiComponents/icons/RoundFillCheckIcon';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import RowWrap from '../../../../uiComponents/RowWrap';


const Availability = ({ t, error,stepsNextClick,refetch, ...props }) => {

    const [form] = Form.useForm();
    const salonId = authStore((state) => state.salonDataId);
    const serviceLocation = authStore((state) => state.service_location);
    const [salonAvailability, setSalonAvailability] = useState([]);
    const [onTheGoAvailability, setOnTheGoAvailability] = useState([]);
    const [salonUnAvailability, setSalonUnAvailability] = useState([]);
    const [removedUnAvailability, setRemovedUnAvailability] = useState([]);
    const {     
        salonUnAvailabilityData,
        salonAvailabilityData,
        onTheGoAvailabilityData,
      } = props;
    

      useEffect(()=>{
        if(salonAvailabilityData?.length > 0){
          salonAvailabilityData?.map((day, index) => {
            day?.slots?.map((slot, slotInd) => {
              form.setFieldsValue({
                ['salon_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                ['salon_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
              })
            })
          });
          setSalonAvailability(salonAvailabilityData);
        }
        if(onTheGoAvailabilityData?.length > 0){
          
          onTheGoAvailabilityData?.map((day, index) => {
            day?.slots?.map((slot, slotInd) => {
              form.setFieldsValue({
                ['onthego_from_' + index + '_' + slotInd]: slot.from ? dayjs.utc(slot.from, 'HH:mm').local() : null,
                ['onthego_to_' + index + '_' + slotInd]: slot.to ? dayjs.utc(slot.to, 'HH:mm').local() : null
              })
            })
          });
          setOnTheGoAvailability(onTheGoAvailabilityData);
        }
        
      },[ 
        salonAvailabilityData,
        onTheGoAvailabilityData])
        
    const handleFinish = async(values)=>{
  
        let data = {};
        data.service_location = 3;
        data.salon = salonAvailability;
        data.onthego = onTheGoAvailability;
        data.holiday_date = salonUnAvailability;
        data.removed_holiday_date = removedUnAvailability;
        // data.salon_id = userProfileData?.data?.id;
        data.salon_id =  salonId; //tmp
        await storeSalonAvailability(data);
    }
    
    const { mutateAsync: storeSalonAvailability } = useMutation(
        async (data) => {
          return await Http.post(PROFILE_COMPLETE_URL.updateSalonAvailability, data);
        },
        {
          onSuccess: ({ data }) => {
            refetch();
            enqueueSnackbar(data.message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
            stepsNextClick();
          },
          onError: (error) => {
            enqueueSnackbar(error?.response?.data?.message, {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          },
        }
      );
    


    return (
        <Card>
            <div className="flex mb-4">
                <h5 className="text-base py-1 px-3 rounded-[24px] font-semibold leading-5 text-center text-success-850 border-[1.5px] !border-success-100">{t("comman_text.salon_hours")}</h5>
            </div>
            <FormikProvider >
            <Form form={form} noValidate onFinish={handleFinish}>
            
                    <RowWrap className={`grid grid-cols-1 border-b border-b-success-100 pb-6 ${serviceLocation === 3 ? "xl:grid-cols-2 gap-8" : "" }`} xl={serviceLocation === 3 ? 2 : ""}>
                      {
                        serviceLocation === 1 || serviceLocation === 3 ?
                        <div className="salon-card">
                            <h6 className="flex items-center gap-1.5 font-medium text-[15px] text-blue-950 mb-4"><RoundFillCheckIcon className="w-6 h-6 text-success-800" /> {t('form_input_label.availability_salon')}</h6>
                            <AddHoursListTable tableClass="min-w-[600px]" salonAvailability={salonAvailability} setSalonAvailability={setSalonAvailability} id='salon' form={form} />
                        </div>                        
                        :
                        <></>
                      }
                      {
                        serviceLocation === 2 || serviceLocation === 3 ?
                        <div className="onthego-card">
                            <h6 className="flex items-center gap-1.5 font-medium text-[15px] text-blue-950 mb-4"><RoundFillCheckIcon className="w-6 h-6 text-success-800" /> {t('form_input_label.availability_on_the_go')}</h6>
                            <AddHoursListTable tableClass="min-w-[600px]" onTheGoAvailability={onTheGoAvailability} setOnTheGoAvailability={setOnTheGoAvailability} id='onthego' form={form}/>
                        </div>
                        :
                        <></>
                      }
                    </RowWrap>
                    <div className="salon-card pt-6">
                        <h6 className="flex items-center gap-1.5 font-semibold text-[15px] text-blue-950 pb-4"><CloseFillIcon className="w-6 h-6 text-success-950" /> {t('form_input_label.unavailability')}</h6>
                        <LabelWrap htmlFor="" label={t('form_input_label.choose_your_dates')} labelClassName="text-[15px] font-bold italic text-blue-950 font-800" />
                    </div>
                            <AvailabilityCalendar  salonUnAvailability={salonUnAvailability} setSalonUnAvailability={setSalonUnAvailability} setRemovedUnAvailability={setRemovedUnAvailability} salonUnAvailabilityData={salonUnAvailabilityData}
                            />
                  
                    <div className="flex items-center justify-end pt-7">
                        <ButtonPrimary size={"sm"} className={"lg:text-[15px] text-[15px] font-bold rounded-[5px]"} >{t('button_label.continue')}</ButtonPrimary>
                    </div>
                </Form>
            </FormikProvider>
        </Card>
    )
}

export default withTranslation()(Availability);