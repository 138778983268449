import React from 'react';
import StarIcon from '../icons/StarIcon';
import { cn } from '../../../../lib/utils';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from '../../../routes/path';


const RatingList = ({ t,groupClassName,salonData, itemClassName, iconClassName, textClassName, ratingNumberClassName, rate, salonReviwes, data, navigateUrl, handelClickEvent, ...props }) => {
    const navigate = useNavigate();
    const defaultData = [
        {
            id: 1,
            rating: rate?.salon ?? 0,
            text: t('salon_invite_modal.salon')
        },
        {
            id: 2,
            rating: rate?.service ?? 0,
            text:  t('salon_invite_modal.service')
        },
        {
            id: 3,
            rating:  rate?.atmosphere ?? 0,
            text:  t('salon_invite_modal.atmosphere')
        },
        {
            id: 4,
            rating: rate?.price_quality ?? 0 ,
            text: t('salon_invite_modal.quality_price')
        },
        {
            id: 5,
            rating: rate?.worker ?? 0,
            text: t('salon_invite_modal.workers')
        },
    ]
    return (
        <>
            {
                salonReviwes ?
                    <div className={cn('border-[1.3px] border-success-100 rounded-[10px] p-1.5 h-full grid xsm:grid-cols-2 grid-cols-1', groupClassName, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(PATH_AUTH.findSalonProfile, { state: { id: salonData?.id, data: salonData } })}>
                        {
                            defaultData.map((item, index) => (
                                <div key={index} className={cn('flex items-center gap-1.5 text-sm text-blue-950 item', itemClassName)}><p className={cn('flex items-center gap-1.5', textClassName)}><span>{item.text}</span><StarIcon className={cn('icon h-4 w-4 flex-shrink-0 text-success-800', iconClassName)} /><span className={cn('min-w-[22px] lg:min-w-[25px]', ratingNumberClassName)}>{item.rating}</span></p></div>
                            ))
                        }
                    </div>
                    :
                    <div className={cn('border-[1.3px] border-success-100 rounded-[10px] p-1.5 h-full grid xsm:grid-cols-2 grid-cols-1', groupClassName, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(PATH_AUTH.findSalonProfile, { state: { id: salonData?.id, data: salonData } })}>
                        {
                            defaultData.map((item, index) => (
                                <div key={index} className={cn('flex items-center gap-1.5 text-sm text-blue-950 item', itemClassName)}><StarIcon className={cn('icon h-4 w-4 flex-shrink-0 text-success-800', iconClassName)} /><p className={cn('flex items-center gap-1.5', textClassName)}><span className={cn('min-w-[22px] lg:min-w-[25px]', ratingNumberClassName)}>{item.rating}</span> {item.text}</p></div>
                            ))
                        }
                    </div>
            }

        </>
    );
}

export default  withTranslation()(RatingList);
