import { Disclosure, Transition } from '@headlessui/react'
import React, { useState } from 'react'
import BottomArrowIcon from '../../../uiComponents/icons/BottomArrowIcon'
import CheckboxList from '../../../uiComponents/common/CheckboxList'

const ServiceAccordianList = ({ serviceTypeData, subCategoryData, setServiceTypeChoose, serviceList, salonService, categoryChoose, setCategoryChoose, SalonDetailsFilterModalOpen, serviceTypeChoose,serviceChoose }) => {
    const [disclosureState, setDisclosureState] = useState(null);

    const handleDisclosureChange = (id, state) => {
        setDisclosureState(state);
        setServiceTypeChoose([id]);
    };
    return (
        <div className=''>
            <ul className='flex flex-col gap-[13px]'>
                {
                    // data.map(({ serviceTitle, serviceIcon, services }, index) => (
                        serviceTypeData?.map(({ id, name, image_name, services }, index) => (                            
                        <li key={index} className='w-full'>
                            <Disclosure as="div" className="border border-borderColor w-full py-1.5 ps-2 pe-4 rounded-lg">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex items-center justify-between gap-3 w-full" onClick={() => handleDisclosureChange(id, index)}>
                                            <span>{image_name}</span>
                                            <h6 className='text-sm text-success-900 font-normal'>{name}</h6>
                                            <span><BottomArrowIcon className={`w-3 h-3 text-success-900 transition-all duration-100 ${open && "rotate-180"}`} /></span>
                                        </Disclosure.Button>
                                        <Transition
                                            show={index === disclosureState}
                                            enter="transition ease-out duration-100" // Animation on open
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75" // Animation on close
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95">
                                            <Disclosure.Panel className="mt-3 pt-2 text-sm text-gray-500 border-t border-borderColor">
                                                <CheckboxList 
                                                    groupClassName={"flex-col gap-1"} 
                                                    inputLableClassName={"w-full flex items-center justify-center border-[1.5] peer-checked:bg-[#A2FFF7] peer-checked:text-success-900 peer-checked:font-[600] py-[4.1px] peer-checked:border-borderColor"} 
                                                    inputGroupClassName={"w-full"} 
                                                    subCategoryData={subCategoryData} 
                                                    serviceList={serviceList} 
                                                    salonService={salonService} 
                                                    setCategoryChoose={setCategoryChoose} 
                                                    categoryChoose={categoryChoose}
                                                    SalonDetailsFilterModalOpen={SalonDetailsFilterModalOpen} 
                                                    serviceTypeChoose={serviceTypeChoose}
                                                    serviceChoose={serviceChoose}
                                                />
                                            </Disclosure.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Disclosure>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default ServiceAccordianList