import React from 'react';
import AboutUsIcon from '../../view/uiComponents/icons/header-footer-icon/AboutUsIcon';
import ContactSupportIcon from '../../view/uiComponents/icons/header-footer-icon/ContactSupportIcon';
import FaqQuestionIcon from '../../view/uiComponents/icons/header-footer-icon/FaqQuestionIcon';
import FindServiceIcon from '../../view/uiComponents/icons/header-footer-icon/FindServiceIcon';
import HomeIcon from '../../view/uiComponents/icons/header-footer-icon/HomeIcon';
import LoginIcon from '../../view/uiComponents/icons/header-footer-icon/LoginIcon';
import PricesIcon from '../../view/uiComponents/icons/header-footer-icon/PricesIcon';
import RegisterIcon from '../../view/uiComponents/icons/header-footer-icon/RegisterIcon';
const WithoutLoginMenuData = [
    {
        Title: 'MAIN MENU',
        list: [
            {
                path: "/login",
                id: '',
                key: '',
                text: "comman_text.login",
                icon: <LoginIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },
            {
                path: "/register",
                id: '',
                key: '',
                text: "comman_text.register",
                icon: <RegisterIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },
            {
                path: "",
                id: '',
                key: '',
                text: "comman_text.find_service",
                icon: <FindServiceIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },
            {
                path: "/",
                id: '',
                key: '',
                text: "comman_text.home",
                icon: <HomeIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },
            {
                path: "",
                id: '',
                key: '',
                text: "comman_text.about_us",
                icon: <AboutUsIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },
            {
                path: "",
                id: '',
                key: '',
                text: "comman_text.contact_support",
                icon: <ContactSupportIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            }
        ]
    },
    {
        Title: 'FOR SERVICE PROVIDERS',
        list: [
            {
                path: "",
                id: '',
                key: '',
                text: "comman_text.prices",
                icon: <PricesIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },
            {
                path: "",
                id: '',
                key: '',
                text: "comman_text.faq",
                icon: <FaqQuestionIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" aria-hidden="true" />,
            },

        ]
    },
    // {
    //     list: [
    //         {
    //             id: '',
    //             key: '',
    //             text: "",
    //             icon: "",
    //             langswitch: <InputLanguageSwitch />
    //         }
    //     ]
    // }
];



export default WithoutLoginMenuData