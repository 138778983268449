import React from 'react'
import SpecialityCard from '../../front/components/SpecialityCard'
import CommonSliderWrapper from '../../../uiComponents/common/CommonSliderWrapper'
import { cn } from '../../../../../lib/utils'

const SpecialitiesCompo = ({ iconArrangeChildLast, workers, colored }) => {

    return (
        <>
            {workers?.length > 0 ?
                <>
                    <CommonSliderWrapper infinite={false} slidesToShow={workers?.length < 2 ? 1 : workers?.length < 3 ? 2 : 2} smSlidesToShow={1} groupClassName="h-full" SliderClassName="h-full !mx-[unset] [&_.slick-track]:!gap-2 slider_flex" arrowIconClass="size-3.5" nextArrowClassName={"top-1/2 -translate-y-1/2 -end-2 size-[26px] bg-primary1 text-white"} prevArrowClassName={"top-1/2 -translate-y-1/2 -start-2 size-[26px] bg-primary1 text-white"}>
                        {
                            workers?.map((nestedData) => (
                                <>
                                    <SpecialityCard iconArrangeChildLast={iconArrangeChildLast} workerSpecialities={nestedData?.services_id} title={nestedData?.provider?.first_name + " " + nestedData?.provider?.last_name} groupClassName={cn(`w-full ${colored && "bg-light-800"}`)} iconClassName="text-blue-950/40" className="w-full h-full" />
                                </>
                            ))
                        }
                    </CommonSliderWrapper>
                </>
                :
                <div className={cn(`border-[1.3px] border-success-100 rounded-[10px] h-full p-0 flex items-center justify-center ${colored && "bg-light-800"}`)}>
                    No data found
                </div>
            }
        </>
    )
}

export default SpecialitiesCompo