import React from 'react'
import { withTranslation } from 'react-i18next';
import Card from '../../../../uiComponents/Card';
import Badge from '../../../../uiComponents/common/Badge';
import InputWithIcon from '../../../../uiComponents/inputs/InputWithIcon';
import RowWrap from '../../../../uiComponents/RowWrap';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import { Form, FormikProvider, useFormik } from 'formik';
import { authStore } from '../../../../../contexts/AuthProvider';
import * as Yup from "yup"
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { useMutation } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import Http from '../../../../../../Http';
import PasswordInput from '../../../../uiComponents/inputs/PasswordInput';



const AccountSettings = ({ t, ...props }) => {
    const user = authStore((state) => state.user);
    const isAuthenticated = authStore((state) => state.isAuthenticated);
    
    let accountSetingValidation = Yup.object().shape({
        current_password: Yup.string()
          .required(t("owner_profile_validation.current_password")),
        new_password: Yup.string()
          .required(t("owner_profile_validation.new_password"))
          .min(8, t("owner_profile_validation.min_password"))
          .max(16, t("owner_profile_validation.max_password"))
          .matches(
            /[A-Z]+/,
            t("owner_profile_validation.upper_case_password")
          )
          .matches(
            /[a-z]+/,
            t("owner_profile_validation.lower_case_password")
          )
          .matches(/[0-9]+/, t("owner_profile_validation.one_number_password"))
          .matches(
            /(?=.*?[#?!@$%^&*-])/,
            t("owner_profile_validation.special_charachter_password")
        ),
    });

    const { mutateAsync: changePassowrd } = useMutation(

        async (data) => {
          return await Http.post(
            PROFILE_COMPLETE_URL.changePassword,
            data,
          );
        },
        {
          onSuccess: ({ data }) => {
            const message = data?.message;
            enqueueSnackbar(message, {
              variant: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
            resetForm()
          },
          onError: (error) => {
            const errorData = error?.response?.data?.errors;
            if (errorData) {
              Object.keys(errorData).forEach((key) => {
                setFieldError(key, errorData[key]);
              });
            }
          },
        }
      );

        
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            current_password: "",
            new_password: "",
        },
        validationSchema: accountSetingValidation,
        onSubmit: async (values) => {
        values.old_password = values.current_password;
        await changePassowrd(values);

        },
    });
    const {
        errors,
        handleSubmit,
        touched,
        resetForm,
        getFieldProps,
        setFieldError,
    } = formik;

    console.log({errors});

    return (
        <FormikProvider value={formik}>
            <Form onSubmit={handleSubmit} autoComplete="off">
                <Card>
                    <Badge text={t('owner_profile.account_settings')} />
                    <RowWrap className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-x-6 lg:gap-x-8 md:gap-y-5 mb-6" >
                        <InputWithIcon
                            size="sm"
                            require
                            labelClassName={"text-[15px] font-medium"}
                            label={t('form_input_label.email_address')}
                            placeholder={"romainbatta1234@gmail.com"}
                            groupClassName="w-full"
                            value={isAuthenticated ? user.email : ''}
                            disabled={true}
                        />
                    </RowWrap>
                    <Badge text={t('owner_profile.change_password')} />
                    <RowWrap className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-x-6 lg:gap-x-8 md:gap-y-5" md={2} >
                        <PasswordInput
                            size="sm"
                            require
                            labelClassName={"text-[15px] font-medium"}
                            label={t('owner_profile.current_password')}
                            placeholder={t('input_filed_placeholder.current_password')}
                            groupClassName="w-full"
                            {...getFieldProps('current_password')}
                            errorType={Boolean(touched.current_password && errors.current_password) && "error"}
                            errorData={touched.current_password && errors.current_password}
                        />
                        <PasswordInput
                            size="sm"
                            require
                            labelClassName={"text-[15px] font-medium"}
                            label={t('owner_profile.new_password')}
                            placeholder={t('input_filed_placeholder.new_password')}
                            groupClassName="w-full"
                            {...getFieldProps('new_password')}
                            errorType={Boolean(touched.new_password && errors.new_password) && "error"}
                            errorData={touched.new_password && errors.new_password}

                        />
                    </RowWrap>
                    <div className="flex items-center justify-end gap-4 mt-5">
                        <ButtonPrimary size={"sm"} className={"w-full sm:max-w-[250px] font-bold rounded-[5px]"} type='submit'>save</ButtonPrimary>
                    </div>
                </Card>
            </Form>
        </FormikProvider>

    )
}

export default withTranslation()(AccountSettings);