import React, { useEffect, useState } from 'react'
import Card from '../../../../uiComponents/Card'
import { withTranslation } from 'react-i18next';
import Divider from '../../../../uiComponents/Divider';
import RowWrap from '../../../../uiComponents/RowWrap';
import DropZone from '../../../../uiComponents/inputs/DropZone';
import DragandDropIcon from '../../../../uiComponents/icons/DragandDropIcon';
import UploadCertificateIcon from '../../../../uiComponents/icons/UploadCertificateIcon';
import InputWithIcon from '../../../../uiComponents/inputs/InputWithIcon';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import CalendarIcon from '../../../../uiComponents/icons/CalenderIcon';
import CloseFillIcon from '../../../../uiComponents/icons/CloseFillIcon';
import CloseIcon from '../../../../uiComponents/icons/CloseIcon';
import EducationIcon from '../../../../uiComponents/icons/EducationIcon';
import LeftSmallArrowIcon from '../../../../uiComponents/icons/LeftSmallArrowIcon';
import RightSmallArrowIcon from '../../../../uiComponents/icons/RightSmallArrowIcon';
import SchoolIcon from '../../../../uiComponents/icons/SchoolIcon';
import { DatePicker } from 'antd'
import dayjs from "dayjs";
import * as Yup from "yup";
import { FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { IMAGE_UPLOAD, PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { useMutation, useQueryClient } from 'react-query';
import { isEmpty } from 'lodash';
import Http from '../../../../../../Http';
import { PATH_BOTH, PATH_PROVIDER, PATH_WORKER } from '../../../../../routes/path';
import { useNavigate } from 'react-router-dom';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import InputRadioBox from '../../../../uiComponents/inputs/InputRadioBox';
import { authStore } from '../../../../../contexts/AuthProvider';

const { RangePicker } = DatePicker;


const AddEducationExpertiseCard = ({ t, ...props }) => {
    const [keywordDatas, setKeywordDatas] = useState([])
    const [showKeyword, setShowKeyword] = useState(0)
    const [educationDate, setEducationDate] = useState('');
    const [fileList, setFileList] = useState([]);

    const profileType = authStore((state) => state?.profileType)
    const queryClient = useQueryClient();
    const navigate = useNavigate()

    let educationValidation = Yup.object().shape({
        name_of_study: Yup.string().required(t("validation_message.name_of_study_req")),
        keywords: Yup.string().required(t("validation_message.keywords_is_req")),
        school_name: Yup.string().required(t("validation_message.school_name_req")),
        school_address: Yup.string().required(t("validation_message.school_address_req")),
        is_student: Yup.string().required(t("validation_message.this_field_req")),
        start_date: Yup.date().required(t("validation_message.start_date_req")),
        end_date: Yup.date().required(t("validation_message.end_date_req")),
        education_date: Yup.array()
            .of(Yup.date().required('Date is required'))
            .required(t("validation_message.date_req")),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name_of_study: "",
            school_name: "",
            school_address: "",
            is_student: "",
            start_date: "",
            end_date: "",
            education_date: "",
            // image: "",
            // back_image: "",
            keywords: "",
            keywords_tmp: ""
        },
        validationSchema: educationValidation,
        onSubmit: async (values, { resetForm }) => {
            // setLodingBtn(true);

            if (fileList.length == 0) {
                enqueueSnackbar(t("validation_message.certificate_req"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                // setLodingBtn(false);
                return false
            }
            try {
                if (!isEmpty(values) && fileList.length > 0) {

                    const formData = new FormData();
                    formData.append("model", "worker_education");
                    formData.append("front_image", fileList[0].image);

                    const imageUploadData = await imageUpload(formData);
                    values.front_image = imageUploadData.name?.front_image;
                    // values.back_image = imageUploadData.name?.back_image;
                }
                values.keywords = keywordDatas
                const educationData = await workerEducationData(values);
                if (educationData.data.success) {
                    resetForm();
                    setKeywordDatas([])
                    setEducationDate([])
                    // setLodingBtn(false);
                    setFileList([])
                    // setBackList([])
                    setFieldValue('front_image', null);
                    // setFieldValue('back_image', null);
                    setFieldValue("is_student", "");
                } else {
                    // setLodingBtn(false);
                }
            } catch (error) {
                // setLodingBtn(false);
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                } else {
                    enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        },
    });

    const { mutateAsync: imageUpload } = useMutation(async (formData) => {
        formData.env = "test";
        return await Http.post(IMAGE_UPLOAD.uploadImage, formData).then(
            (res) => res.data
        );
    });

    const { mutateAsync: workerEducationData } = useMutation(
        async (data) => {
            return await Http.post(PROFILE_COMPLETE_URL.workerEducation, data);
        },
        {
            onSuccess: ({ data }) => {
                queryClient.invalidateQueries(['workerProfileData']);

                if (profileType === 4) {
                    navigate(PATH_BOTH.bothProfile, { state: { selectedTab: 9 } })
                } else
                    navigate(PATH_WORKER.profile, { state: { selectedTab: 4 } })

                enqueueSnackbar(data.message, {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            },
            onError: (error) => {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.keys(errorData).forEach((key) => {
                        setFieldError(key, errorData[key]);
                    });
                }
            },
        }
    );

    const disabledDate = (current) => {
        if (values?.is_student == 0) {
            return current && current > dayjs().subtract(0, "day");
        }
    };

    const onChangeEducationDate = (value) => {

        if (value && value.length === 2 && values?.is_student == 0) {
            const [startDate, endDate] = value;

            if (values?.student == 0) {

                // Check if start date is in the future or same as current date
                if (startDate.isAfter() || startDate.isSame()) {
                    setFieldError('education_date', t("validation_message.not_start_date_before"));
                    return;
                }

                // Check if end date is in the future or same as current date
                if (endDate.isAfter() || endDate.isSame()) {
                    setFieldError('education_date', t("validation_message.not_end_date_before"));
                    return;
                }
            }

            if (values?.student == 1) {

                // Check if start date is in the future or same as current date
                if (startDate.isAfter() || startDate.isSame()) {
                    setFieldError('education_date', t("validation_message.student_start_date"));
                    return;
                }

                // Check if end date is in the future or same as current date
                if (endDate.isBefore() || endDate.isSame()) {
                    setFieldError('education_date', t("validation_message.student_end_date"));
                    return;
                }

            }

            // Check if end date is before start date
            if (endDate.isSame(startDate) || endDate.isBefore(startDate)) {
                setFieldError("education_date", t("validation_message.end_after_start"));
                return;
            }
        }

        setEducationDate(value)
        formik.setFieldValue("education_date", value)
        formik.setFieldValue("start_date", value !== null ? value[0].format('YYYY-MM-DD') : value);
        formik.setFieldValue("end_date", value !== null ? value[1].format('YYYY-MM-DD') : value);
    };

    const hangleChangeKeyword = (e) => {
        if (keywordDatas?.includes(formik.values.keywords_tmp)) {
            return false;
        }
        setKeywordDatas(prev => [...prev, formik.values.keywords_tmp])
        setFieldValue("keywords_tmp", "")
        setFieldValue("keywords", formik.values.keywords_tmp)
        setFieldError("keywords", "")
        formik.setFieldTouched("keywords", false)
    }
    const removeKeyword = (index) => {
        const filteredList = keywordDatas.filter((val, ind) => ind !== index);
        formik.setFieldValue("keywords", filteredList.length ? filteredList : '')
        setKeywordDatas(filteredList)
        if (showKeyword === index && index !== 0) {
            setShowKeyword(showKeyword - 1)
        }
    }
    const handleRemoveImage = (index) => {
        setFileList([]);
    }

    useEffect(() => {
        if (keywordDatas.length > 0) {
            setFieldError('keywords', "");
            formik.setFieldTouched('keywords', false);
        }
    }, [keywordDatas])

    const handleBackClick = () => {
        if (profileType == 1) {
            navigate(PATH_WORKER.profile, { state: { selectedTab: 4 } })
        } else if (profileType == 4) {
            navigate(PATH_BOTH.bothProfile, { state: { selectedTab: 9 } })
        }
    }

    const {
        errors,
        handleSubmit,
        touched,
        resetForm,
        getFieldProps,
        setFieldValue,
        setFieldError,
        values
    } = formik;

    return (
        <>
            <div className="page-title mb-3.5 flex items-center justify-center gap-2">
                <LeftSmallArrowIcon className='h-8 w-8 text-white cursor-pointer' onClick={() => handleBackClick()} />
                <h1 className="text-[40px] font-bold leading-[1.2] text-white">{t('comman_text.education')}</h1>
            </div>
            <FormikProvider value={formik}>
                <form onSubmit={handleSubmit}>
                    <Card>
                        <div className="meetime-form-group">
                            <LabelWrap require labelClassName="font-medium text-[15px] mb-4" label={t("comman_text.are_you_student")} prefixIcon={<EducationIcon className="w-6 h-6" />} />
                            <div className="flex items-center flex-wrap gap-4 sm:gap-6 xl:gap-8" {...getFieldProps("is_student")}>
                                <InputRadioBox
                                    name="is_student"
                                    id={t('radio_label.yes') + "student"}
                                    text={t("radio_label.yes")}
                                    labelClassName={"text-sm"}
                                    value={1}
                                    onClick={() => {
                                        resetForm();
                                        setFileList([])
                                        setEducationDate([])
                                        setKeywordDatas([])
                                        setFieldValue("is_student", false);
                                    }}
                                />
                                <InputRadioBox
                                    name="is_student"
                                    id={t('radio_label.no') + "student"}
                                    text={t("radio_label.no")}
                                    labelClassName={"text-sm"}
                                    value={0}
                                    onClick={() => {
                                        resetForm();
                                        setFileList([])
                                        setEducationDate([])
                                        setKeywordDatas([])
                                        setFieldValue("is_student", false);
                                    }}
                                />
                            </div>
                            {touched.is_student && <p className={"error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium"}>{errors.is_student}</p>}
                        </div>
                        <Divider className="my-4" />
                        <RowWrap className="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 lg:gap-x-8 gap-y-5" >
                            <div>
                                <LabelWrap
                                    require
                                    labelClassName="font-medium mb-2"
                                    label={values.is_student == true ? "Upload School Card" : t("form_input_label.upload_certificate")}
                                    prefixIcon={<UploadCertificateIcon className="w-6 h-6" />}
                                />
                                <DropZone labelClassName="p-2 min-h-[103px] bg-light-950 py-5" labelTitleClass="mt-1.5 inline" preBr br
                                    icon={<DragandDropIcon className="w-10 h-10 text-primary1" />}
                                    fileSelected={fileList} setFileSelected={setFileList}
                                    single={true}
                                />
                            </div>
                            {
                                fileList?.length > 0 ?
                                    <div className='h-full flex items-end justify-start '>
                                        <div className='w-full max-h-[136px] max-w-[156px] border border-success-100 rounded-lg overflow-hidden relative'>
                                            <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center' onClick={handleRemoveImage}><CloseIcon className="w-4 h-4 text-white" /></button>
                                            <img src={fileList[0]?.file} className='h-full w-full object-cover' alt="" />
                                        </div>
                                    </div>
                                    : <></>
                            }
                        </RowWrap>
                        <Divider className="my-5" />
                        <RowWrap className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8 gap-4 lg:gap-y-5 mb-10" lg={3}>
                            <InputWithIcon
                                require
                                labelClassName="font-medium font-[15px] mb-2"
                                label={t("job_manager.name_of_study")}
                                {...getFieldProps("name_of_study")}
                                errorType={Boolean(
                                    touched.name_of_study &&
                                    errors.name_of_study
                                ) && "error"}
                                placeholder={t("job_manager.name_of_study")}
                                errorData={errors.name_of_study}
                            />
                            <div>
                                <LabelWrap label={t("form_input_label.start_end_date")} labelClassName={"font-medium text-[15px] mb-2"} require />
                                <RangePicker
                                    onChange={onChangeEducationDate}
                                    value={educationDate}
                                    disabledDate={disabledDate}
                                    onBlur={() => {
                                        formik.setFieldTouched('education_date', true);
                                    }}
                                    format="YYYY-MM-DD"
                                    className="w-full border-success-100 [&>.ant-picker-input]:font-normal hover:border-success-100 min-h-10 bg-light-950" suffixIcon={<CalendarIcon className="w-5 h-5 text-primary1" />} />
                                {touched.education_date && <p className={"error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium"}>{errors.education_date}</p>}
                            </div>
                            <InputWithIcon
                                require
                                labelClassName={"font-medium text-[15px] mb-2"}
                                labelIcon={<SchoolIcon className="w-5 h-5" />}
                                label={t("form_input_label.school_name")}
                                placeholder={t("form_input_label.school_name")}
                                {...getFieldProps("school_name")}
                                errorType={Boolean(
                                    touched.school_name &&
                                    errors.school_name
                                ) && "error"}
                                errorData={errors.school_name}
                            />
                            <InputWithIcon
                                require
                                labelClassName={"font-medium text-[15px] mb-2"}
                                label={t("form_input_label.school_address")}
                                placeholder={t("input_filed_placeholder.address")}
                                {...getFieldProps("school_address")}
                                errorType={Boolean(
                                    touched.school_address &&
                                    errors.school_address
                                ) && "error"}
                                errorData={errors.school_address}
                            />
                            <InputWithIcon
                                require
                                labelClassName={"font-medium text-[15px] mb-2"}
                                label={t("form_input_label.keywords")}
                                placeholder="Short keywords"
                                tooltipText="Lorem ipsum"
                                suffixWrap={
                                    <ButtonPrimary size={"sm"} type={"button"} className={"font-medium  py-1 !text-sm"} onClick={() => hangleChangeKeyword()}>ADD</ButtonPrimary>
                                }
                                {...getFieldProps("keywords_tmp")}
                                errorType={Boolean(
                                    touched.keywords &&
                                    errors.keywords
                                ) && "error"}
                                errorData={errors.keywords}
                            />
                            <div className='flex gap-3 justify-end items-end'>
                                {
                                    showKeyword !== 0 &&
                                    <button
                                        type='button'
                                        onClick={() => setShowKeyword(showKeyword - 1)} className='bg-white rounded-full flex-shrink-0 h-9 w-9 mb-1 flex items-center justify-center border border-success-100'><LeftSmallArrowIcon className="w-4 h-4 text-blue-950" /></button>
                                }
                                {keywordDatas.length > 0 ?
                                    <InputWithIcon
                                        label={`Added Keywords (${showKeyword + 1}/${keywordDatas.length})`}
                                        suffixIcon={
                                            <CloseFillIcon className="w-6 h-6" />
                                        }
                                        handleSuffixIconClick={() => removeKeyword(showKeyword)}
                                        disabled
                                        value={keywordDatas[showKeyword]}
                                        groupClassName="w-full"
                                    /> : <></>}
                                {
                                    (showKeyword !== keywordDatas.length - 1 && keywordDatas?.length > 0) ?
                                        <button onClick={() => setShowKeyword(showKeyword + 1)}
                                            type="button"
                                            className='bg-white rounded-full flex-shrink-0 h-9 w-9 mb-1 flex items-center justify-center border border-success-100'><RightSmallArrowIcon className="w-4 h-4 text-blue-950" /></button> : <></>
                                }
                            </div>
                        </RowWrap>
                        <div className="flex items-center justify-end gap-4">
                            <ButtonPrimary onClick={() => navigate(PATH_WORKER.profile, { state: { selectedTab: 4 } })} size={"sm"} variant={"outline"} className={" font-bold rounded-[5px]"} type='button' >Back</ButtonPrimary>
                            <ButtonPrimary size={"sm"} className={"w-full max-w-[200px] font-bold rounded-[5px]"}  >{t('button_label.continue')}</ButtonPrimary>
                        </div>
                    </Card>
                </form>
            </FormikProvider>
        </>
    )
}

export default withTranslation()(AddEducationExpertiseCard)