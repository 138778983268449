import React from 'react'
import Divider from '../../../uiComponents/Divider'
import MaleIconNew from '../../../uiComponents/icons/MaleIconNew'
import ChildNewIcon from '../../../uiComponents/icons/ChildNewIcon'
import FeMaleIcon from '../../../uiComponents/icons/FeMaleIcon'
import { cn } from '../../../../../lib/utils'
import MaleIcon from '../../../uiComponents/icons/MaleIcon'

const SpecialityCard = ({ className, listClassName, listItemClassName, listTextClassName, titleClassName, groupClassName, title, iconClassName, iconArrangeChildLast, workerSpecialities }) => {
    let data = [];
    if (!workerSpecialities) {
        data = [
            {
                text: "Haircut",
                feMaleColor: 2
            },
            {
                text: "Color",
                maleColor: 1,
                feMaleColor: 2,
                firstChildColor: 3,
                secChildColor: 4
            },
            {
                text: "Permanemt",
                maleColor: 1,
                firstChildColor: 3,
            },
            {
                text: "Styling",
                feMaleColor: 2,
                secChildColor: 4
            },
        ]

    }
    else {
        workerSpecialities?.forEach(service => {

            data.push({
                text: service.category?.name,
                dk_title: service?.dk_title,
                service_for: service?.service_for
            });
        });

    }

    return (
        (workerSpecialities && workerSpecialities.length > 0) && (
            <div className={cn("p-2 border-[1.3px] border-success-100 rounded-[10px] relative h-full", groupClassName)}>
                <div className="card-head">
                    <h5 className={cn('text-[15px] text-blue-950 text-center', titleClassName)}>{title}</h5>
                </div>
                <Divider className={"my-2"} />
                <div className="h-[calc(100%-38px)] card-body">
                    <ul className={cn('flex flex-col justify-between h-full', listClassName)}>
                        {
                            data?.map((item, index) => {
                                return (
                                    <li key={index} className={cn('flex items-center gap-2 justify-between', listItemClassName)}>
                                        <p className={cn('text-sm font-normal leading-none text-blue-950', listTextClassName)}>
                                            {/* {item.text} */}
                                            {item?.dk_title ? item?.dk_title : ""}
                                        </p>

                                        <div className='flex justify-end items-end gap-0 xl:gap-0.5 py-1'>
                                            <MaleIcon className={`${item.service_for?.includes('1') ? "text-[#3BDFFD]" : "text-gray-400"} h-4 xl:h-5`} />
                                            <FeMaleIcon className={`${item.service_for?.includes('2') ? "text-[#FE7AB6]" : "text-gray-400"} h-4 xl:h-5`} />
                                            <ChildNewIcon className={`${item.service_for?.includes('3') ? "text-[#3BDFFD]" : "text-gray-400"} w-3 xl:w-3.5`} />
                                            <ChildNewIcon className={`${item.service_for?.includes('4') ? "text-[#FE7AB6]" : "text-gray-400"} w-3 xl:w-3.5`} />
                                        </div>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    );
};

export default SpecialityCard