import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../../uiComponents/icons/CloseIcon';
import LeftSmallArrowIcon from '../../uiComponents/icons/LeftSmallArrowIcon';
import OfferRequestFirstStep from './OfferRequestFirstStep';
import OfferRequestSecoundStep from './OfferRequestSecoundStep';

const OfferRequestModal = ({ t, modalOpen, setModalOpen, serviceList, servicesIds,salonInfo,salonId, salonService, promotionType, selectCategoryTpes }) => {

    const [activeStep, setActiveStep] = useState(1);

    const handleModalClose = () => {
        setActiveStep(1)
        setModalOpen(false)
    }


    return (

        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1200px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950 flex gap-2 justify-center items-center">
                                        <span className={`${activeStep <= 1 && "hidden"}`}>
                                            <LeftSmallArrowIcon className='h-5 w-5 cursor-pointer' onClick={() => setActiveStep(activeStep - 1)} />
                                        </span>
                                        {activeStep === 1 && t('comman_text.offer_request_step1')}
                                        {activeStep === 2 && t('comman_text.offer_request_step2')}
                                    </h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body ">
                                    {/* -----------------------------------step 1 ---------------------------- */}
                                    <OfferRequestFirstStep 
                                        activeStep={activeStep} 
                                        setActiveStep={setActiveStep} 
                                        handleModalClose={handleModalClose} 
                                        serviceList={serviceList} 
                                        servicesIds={servicesIds}
                                        salonService={salonService}
                                        promotionType={promotionType}
                                        selectCategoryTpes={selectCategoryTpes}
                                        salonInfo={salonInfo}
                                        salonId={salonId}
                                    />

                                    {/* -----------------------------------step 2 ---------------------------- */}
                                    <OfferRequestSecoundStep activeStep={activeStep} setActiveStep={setActiveStep} handleModalClose={handleModalClose} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(OfferRequestModal)
