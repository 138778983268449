import { Divider } from 'antd'
import React, { useState } from 'react'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from "moment";
import ButtonPrimary, { buttonVariance } from '../buttons/ButtonPrimary';
import LeftSmallArrowIcon from '../icons/LeftSmallArrowIcon';
import RightSmallArrowIcon from '../icons/RightSmallArrowIcon';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import BottomArrowIcon from '../icons/BottomArrowIcon';
import Badge from './Badge';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../inputs/LabelWrap';
import { cn } from '../../../../lib/utils';

dayjs.extend(customParseFormat);

function ProviderScheduleBookingCalender({ t, ...props }) {



    const [formattedDate, setFormattedDate] = useState(dayjs().format("D MMMM YYYY"));

    const handlerPrevDay = () => {
        const newDate = dayjs(formattedDate).subtract(1, 'day');
        setFormattedDate(newDate.format("D MMMM YYYY"));
    };

    const handlerNextDay = () => {
        const newDate = dayjs(formattedDate).add(1, 'day');
        setFormattedDate(newDate.format("D MMMM YYYY"));
    };


    const generateTimeOptions = () => {
        const morningOptions = [];
        const afternoonOptions = [];
        const eveningOptions = [];

        for (let hour = 8; hour <= 23; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const formattedHour = hour.toString().padStart(2, '0');
                const formattedMinute = minute.toString().padStart(2, '0');
                const time = `${formattedHour}:${formattedMinute}`;

                if (hour < 12) {
                    morningOptions.push(time);
                } else if (hour < 17) {
                    afternoonOptions.push(time);
                } else {
                    eveningOptions.push(time);
                }
            }
        }
        eveningOptions.push('23:45');

        return {
            morning: morningOptions,
            afternoon: afternoonOptions,
            evening: eveningOptions
        };
    };

    const timeOptions = generateTimeOptions();

    return (
        <div className={`customer_booking_calender border-[1.3px] p-3 border-success-100 rounded-[15px] `}>
            <div className="flex items-center justify-center gap-6 lg:gap-9  ">
                <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2"
                    onClick={() => handlerPrevDay()}
                >
                    <LeftSmallArrowIcon className="w-3 h-3" />
                </ButtonPrimary>
                <h6 className="mb-0 text-[15px] font-semibold text-blue-950">
                    {formattedDate}
                </h6>
                <ButtonPrimary variant={"outline"} type={"button"} role={"button"} className="outline-0 border flex-shrink-0 border-blue-950/10 text-blue-950 p-2 md:p-2"
                    onClick={() => handlerNextDay()} F
                >
                    <RightSmallArrowIcon className="w-3 h-3" />
                </ButtonPrimary>
            </div>
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={"Morning"} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    timeOptions?.morning?.map((data, ind) => {
                                        return (
                                            <li className={cn(buttonVariance({ variant: "outline", size: "sm" }), "text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950")}>
                                                {data}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={"AfterNoon"} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    timeOptions?.afternoon?.map((data, ind) => {
                                        return (
                                            <li className={cn(buttonVariance({ variant: "outline", size: "sm" }), "text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950")}>
                                                {data}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
            <Disclosure defaultOpen={true}>
                {({ open }) => (
                    <div className='flex flex-col sm:flex-row gap-3 gap-y-0 mt-3 justify-start items-start  border-t-[1.3px] border-success-100 sm:border-t-0'>
                        <Disclosure.Button className=" flex justify-between items-center gap-3 py-3 w-full max-w-[100px] ">
                            <LabelWrap labelClassName="text-[15px] font-medium mb-0"
                                className={"flex gap-2"}
                                label={"Evening"} />
                            <BottomArrowIcon
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-4 w-4 text-blue-950`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className={"w-full border-t-0 sm:border-t-[1.3px] border-success-100 pt-1 pb-2 sm:pt-3.5"}>
                            <ul className='w-full flex flex-wrap gap-x-1 md:gap-x-3 gap-y-2 ' >
                                {
                                    timeOptions?.evening?.map((data, ind) => {
                                        return (
                                            <li className={cn(buttonVariance({ variant: "outline", size: "sm" }), "text-[13px] md:text-[15px] lg:text-sm py-0 md:py-0 font-medium  px-0 md:px-0 w-14 md:w-16 border rounded-full text-blue-950")}>
                                                {data}
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </Disclosure.Panel>
                    </div>
                )}
            </Disclosure>
        </div>
    )
}

export default withTranslation()(ProviderScheduleBookingCalender)
