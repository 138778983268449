import React, { useState } from 'react'
import SalonCard from '../../../uiComponents/common/SalonCard'
import { withTranslation } from 'react-i18next'
import Card from '../../../uiComponents/Card'
import CustomeTabsUpdated from '../../../uiComponents/tabs/CustomeTabsUpdated'
import SalonDetailsServices from '../components/SalonDetailsServices'
import SalonCardResponsive from '../../../uiComponents/common/SalonCardResponsive'
import CustomeTabsSliderUpdated from '../../../uiComponents/tabs/CustomeTabsSliderUpdated'
import SalonDetailsPortfolio from '../components/SalonDetailsPortfolio'
import SalonDetailsWorkers from '../components/SalonDetailsWorkers'
import SalonRatingAndReviews from '../components/SalonRatingAndReviews'
import AboutUsDeatils from '../../../uiComponents/common/AboutUsDeatils'
import WorkHoursDetails from '../../../uiComponents/common/WorkHoursDetails'
import SalonDetailsProfile from '../components/SalonDetailsProfile'
import StudentBadgeIcon from '../../../uiComponents/icons/badge-icon/StudentBadgeIcon'
import TrustedBadgeIcon from '../../../uiComponents/icons/badge-icon/TrustedBadgeIcon'
import { useLocation } from 'react-router-dom'

const FindSalonProfile = (props) => {
    const { t } = props
    const { state } = useLocation();
    const salonData = state?.data
    const [selectedTabId, setSelectdTabId] = useState(null);
    const mainTabs = [
        { tabId: "profile", tabName: "Profile" },
        { tabId: "services", tabName: "Services" },
        { tabId: "portfolio", tabName: "Portfolio" },
        { tabId: "workers", tabName: "Workers" },
        { tabId: "reviews", tabName: "Reviews" },
    ]
    const tabData = [
        {
            id: "2",
            title: 'About Us',
            content: <AboutUsDeatils groupClassName={"min-h-[198px] md:min-h-full p-3"} text={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas, obcaecati? Accusantium, veniam natus sequi nihil a amet molestiae ad est placeat nam laboriosam quidem expedita, ullam nobis. Facere, odio excepturi."} salonData={salonData?.about_us}/>
        },
        {
            id: "3",
            title: 'Work Hours',
            content: <WorkHoursDetails salonData={salonData}/>
        },
    ]

    // const BadgeList = [
    //     {
    //         id: "1",
    //         title: 'Student',
    //         badge: <StudentBadgeIcon className='h-[60px] w-[60px]' />
    //     },
    //     {
    //         id: "2",
    //         title: 'Trusted',
    //         badge: <TrustedBadgeIcon className='h-[60px] w-[60px]' />
    //     },
    //     {
    //         id: "3",
    //         title: 'Trusted',
    //         badge: <TrustedBadgeIcon className='h-[60px] w-[60px]' />
    //     },
    //     {
    //         id: "4",
    //         title: 'Trusted',
    //         badge: <TrustedBadgeIcon className='h-[60px] w-[60px]' />
    //     },
    // ];

    const BadgeList = (salonData?.salon_badges?.length > 0 ? salonData.salon_badges.map((badges, ind) => {
        return {
            id: badges.id.toString(),
            title:badges?.badge?.name,
            badge: badges?.badge?.image ? <img src={badges.badge.image} className='h-[76px] w-[76px]' alt={badges.badge.name} /> : null
        };
    }) : []);

    console.log("in findsalonprofile",salonData);

    return (
        <section className='pb-20'>
            <div className="container">
                <h1 className='text-center text-white text-[40px] font-700 py-5'>{t("comman_text.my_search")}</h1>
                <div className={"mb-2.5 md:mb-4"}>
                <SalonCard badgeListData={BadgeList} tabsData={tabData} colored cardTitleClass="text-blue-950" heartIcon={true} phoneIcon2={true} hasHairList={ salonData?.workers && salonData.workers.some(worker => worker.hair_type_ids && worker.hair_type_ids.length > 0) ? true: false} ratingItemClassName={"text-[13px]"} className="md:block hidden" salonInfo={salonData} handelClickEvent={false}/>
                    <Card className={'sm:px-3 sm:pt-2.5 sm:pb-3.5 md:hidden block'}>
                        <SalonCardResponsive ServiceFacilityClassName={selectedTabId !== "profile" && "hidden"} heartIcon={true} phoneIcon2={true} hasHairList={ salonData?.workers && salonData.workers.some(worker => worker.hair_type_ids && worker.hair_type_ids.length > 0) ? true: false} />
                        <CustomeTabsSliderUpdated wrapperClassName={"mt-2.5 md:hidden block"} tabs={mainTabs} selectedTabId={selectedTabId ?? mainTabs[0].tabId} onTabChange={setSelectdTabId} />
                    </Card>
                </div>
                <Card className={"mb-2.5 md:mb-4 md:block hidden sm:px-2.5 sm:py-3"}>
                    <CustomeTabsUpdated tabs={mainTabs} defaultTabId={"profile"} selectedTabId={selectedTabId ?? mainTabs[0].tabId} onTabChange={setSelectdTabId} />
                </Card>
                <div className="tab-content-wrapper">
                    {
                        selectedTabId === "profile" && <SalonDetailsProfile badgeListData={BadgeList} tabsData={tabData} handelClickEvent={false} salonData={salonData}/>
                    }
                    {
                        selectedTabId === "services" && <SalonDetailsServices salonInfo={state?.data}/>
                    }
                    {
                        selectedTabId === "portfolio" && <SalonDetailsPortfolio workerData={salonData?.workers}/>
                    }
                    {
                        selectedTabId === "workers" && <SalonDetailsWorkers workerData={salonData} />
                    }
                    {
                        selectedTabId === "reviews" && <SalonRatingAndReviews handelClickEvent={false} salonData={salonData}/>
                    }
                </div>
            </div>
        </section>
    )
}

export default withTranslation()(FindSalonProfile)