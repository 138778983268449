import React from 'react'

function CloseFillIcon(props) {
    return (
        <svg {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_37_19)">
                <path d="M20.2386 2.55143C10.5316 2.55143 2.66248 10.4203 2.66248 20.127C2.66248 29.8337 10.5316 37.7026 20.2386 37.7026C29.9456 37.7026 37.8147 29.8337 37.8147 20.127C37.8147 10.4203 29.9456 2.55143 20.2386 2.55143Z" fill="#FF0000" stroke="#FF0000" strokeWidth="1.40785" />
                <path d="M40 19.9872C40 31.0263 31.0031 40.023 19.9635 39.9987C9.02131 39.9987 -0.0242672 30.9534 4.89102e-05 20.0115C0.024365 8.94803 8.997 0 20.0608 0C31.0517 0 40 8.97234 40 19.9872ZM36.4499 20.0115C36.4499 11.0391 28.9848 3.47709 20.0608 3.57435C10.7964 3.67161 3.57451 10.7717 3.62314 20.0358C3.67178 29.689 11.2098 36.327 20.0365 36.4243C29.0091 36.5216 36.4499 28.9838 36.4499 20.0115Z" fill="#FF0000" />
                <path d="M13.3518 28.3523C12.5494 28.3523 12.0387 28.0362 11.7713 27.4283C11.4308 26.6746 11.601 25.9937 12.1846 25.4102C13.6679 23.9026 15.1026 22.3708 16.6588 20.9605C17.5342 20.1581 17.4126 19.6718 16.6102 18.9423C15.1998 17.6536 13.8868 16.2676 12.5494 14.906C11.4551 13.8118 11.2849 12.8149 12.0631 12.0611C12.8898 11.283 13.7652 11.4046 14.8837 12.4988C16.2454 13.8361 17.6557 15.1248 18.8959 16.5594C19.7469 17.5563 20.2819 17.3861 21.0843 16.5108C22.3731 15.1005 23.7591 13.7875 25.0965 12.4501C26.0691 11.4775 27.139 11.3559 27.8685 12.0854C28.598 12.7905 28.4521 13.8847 27.5038 14.8573C26.1421 16.2433 24.8047 17.6536 23.37 18.9666C22.5676 19.6961 22.446 20.1824 23.3214 20.9848C24.8047 22.3221 26.1664 23.7567 27.5524 25.1913C28.4764 26.1396 28.5737 27.1122 27.8685 27.866C27.1633 28.5955 26.1421 28.5225 25.1937 27.5986C23.6861 26.1153 22.1785 24.6321 20.7196 23.1245C20.1603 22.5653 19.7956 22.541 19.2363 23.1245C17.7773 24.6321 16.2697 26.1153 14.7865 27.5986C14.3731 28.0119 13.9111 28.401 13.3518 28.3523Z" fill="white" stroke="white" strokeWidth="0.228568" />
            </g>
            <defs>
                <clipPath id="clip0_37_19">
                    <rect width={40} height={40} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default CloseFillIcon