import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import ChooseBookingCard from '../../uiComponents/common/ChooseBookingCard';
import CommonSliderWrapper from '../../uiComponents/common/CommonSliderWrapper';
import CalendarIcon from '../../uiComponents/icons/CalenderIcon';
import CloseIcon from '../../uiComponents/icons/CloseIcon';
import FilterIcon from '../../uiComponents/icons/FilterIcon';
import UserGroupIcon from '../../uiComponents/icons/UserGroupIcon';
import InputCheckbox from '../../uiComponents/inputs/InputCheckbox';
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import CalendarModal from './CalendarModal';
import PortfolioFilterModal from './PortfolioFilterModal';

const AddPortfolioModal = ({ t, modalOpen, setModalOpen, ...props }) => {

    const sliderRef = useRef(null);
    const [calenderModalOpen, setCalenderModalOpen] = useState(false)
    const [portfolioFilterModalOpen, setPortfolioFilterModalOpen] = useState(false)
    const [selectedWorkerList, setSelectedWorkerList] = useState([])
    const [selectAllWorker, setSelectAllWorker] = useState(false)
    const [selectedRows, setSelectedRows] = useState([]);



    const handleModalClose = () => {
        if (!portfolioFilterModalOpen && !calenderModalOpen) {
            setSelectedRows([])
            setSelectedWorkerList([])
            setSelectAllWorker(false)
            setModalOpen(false);
        }
    }




    const handleWorkerSelection = (option) => {
        if (selectedWorkerList?.includes(option)) {
            setSelectedWorkerList(selectedWorkerList?.filter(item => item !== option))
        } else {
            setSelectedWorkerList([...selectedWorkerList, option]);
        }
    };

    const handleAllWorkerSelect = (e) => {
        setSelectAllWorker(e.target.checked)
        if (e.target.checked) {
            //set all worker id on setSelectedWorkerList
        }
    };



    return (
        <>
            <Transition appear show={modalOpen} as={Fragment}>
                <Dialog as="div" className={`relative z-[999]`} onClose={handleModalClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-dark-950 bg-opacity-70"
                        />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto" >
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-[940px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                    <div className="modal-header pe-7 mb-6">
                                        <h2 className="font-semibold text-xl text-center text-blue-950">Add a Portfolio</h2>
                                        <button
                                            type={"button"}
                                            aria-label="button"
                                            className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                            onClick={handleModalClose}>
                                            <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='w-full mb-6 xl:mb-7'>
                                            <LabelWrap labelClassName={"text-[15px] font-medium"} label={t('form_input_label.choose_worker')} />
                                            <div className="w-full flex flex-col md:flex-row gap-3">
                                                <InputCheckbox
                                                    name="workerList"
                                                    id={`allWorker`}

                                                    groupClassName={"flex-grow  w-full max-w-auto sm:max-w-[180px] flex-shrink-0 h-full p-1"}
                                                    className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden  py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                                    text={
                                                        <>
                                                            <div className='w-10 h-10 md:h-[55px] md:w-[55px] flex justify-center items-center overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                                                <UserGroupIcon className="md:w-8 md:h-8 w-7 h-7" />
                                                            </div>
                                                            <span className="flex-grow text-base xl:text-lg text-center block break-words text-success-900 font-medium">All</span>
                                                        </>
                                                    }
                                                    checked={selectAllWorker}
                                                    onChange={(e) => handleAllWorkerSelect(e)}
                                                />
                                                <div className='w-full md:w-[calc(100%-180px)]'>
                                                    <CommonSliderWrapper
                                                        ref={sliderRef}
                                                        defaultArrows={false}
                                                        sildNoShow={true}
                                                        nextArrowClassName="top-9 -end-20 border-[1.6px] !bg-primary1 !border-primary1 !text-white"
                                                        prevArrowClassName="!border-primary1 top-9 !start-auto !-end-10 border-[1.6px] !bg-primary1 !text-white"
                                                        groupClassName="sm:max-w-[calc(100%-110px)] sm:flex sm:gap-2 sm:items-center"
                                                        slidesToShow={3}
                                                        xsSlidesToShow={1}
                                                        mdSlidesToShow={2}
                                                        lgSlidesToShow={2}
                                                        arrowBtnGroupClassName={"mt-0 gap-2"}
                                                        countClassName="min-w-[28px] text-center"
                                                    >
                                                        {
                                                            [...Array(8)].map((item, index) => (
                                                                <InputCheckbox
                                                                    key={index}
                                                                    name="workerList"
                                                                    id={`worker_id_____${index + 1}`}
                                                                    groupClassName={"flex-grow w-full w-full h-full p-1"}
                                                                    className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                                                    text={
                                                                        <>
                                                                            <div className='w-10 h-10 md:h-[55px] md:w-[55px] overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                                                                <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover' alt="" />
                                                                            </div>
                                                                            <span className="text-base xl:text-lg flex-grow text-center block break-words">Michael J.</span>
                                                                        </>
                                                                    }
                                                                    value={`worker_id_____${index + 1}`}
                                                                    checked={selectedWorkerList.includes(`worker_id_____${index + 1}`) ? true : false}
                                                                    onChange={(item) => handleWorkerSelection(item.target.value)}
                                                                />
                                                            ))
                                                        }
                                                    </CommonSliderWrapper>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-6">
                                            <div className='w-full flex flex-col md:flex-row justify-between items-center gap-2 mb-2.5'>
                                                <LabelWrap labelClassName="text-[15px] mb-0" label={"Choose the concerned booking"} />
                                                <div className='flex gap-3'>
                                                    <ButtonPrimary size={"sm"} type={"button"}
                                                        icon={<FilterIcon className="w-5 h-5 text-white" />}
                                                        className={"text-[15px] lg:text-[15px] font-bold !leading-[1] px-2 py-1 rounded-md"}
                                                        onClick={() => setPortfolioFilterModalOpen(true)}
                                                    >Filter</ButtonPrimary>
                                                    <ButtonPrimary size={"sm"} type={"button"}
                                                        onClick={() => setCalenderModalOpen(true)}
                                                        icon={<CalendarIcon className="w-5 h-5 !text-white" />}
                                                        className={"text-[15px] lg:text-[15px] font-bold !leading-[1] px-2 py-1 rounded-md text-white"} >Calendar</ButtonPrimary>
                                                </div >
                                            </div>
                                            <div className='choose_concerning_card_list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 lg:gap-2.5 md:gap-4 gap-3'>
                                                {
                                                    [...Array(3)].map((item, index) => (
                                                        <ChooseBookingCard showCheckBox={true} id={"_id_of_data_" + index} selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        <ButtonPrimary size="sm" className="w-full mt-2" onClick={() => setModalOpen(false)}>Continue</ButtonPrimary>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <CalendarModal
                modalOpen={calenderModalOpen}
                setModalOpen={setCalenderModalOpen}
            />
            <PortfolioFilterModal
                modalOpen={portfolioFilterModalOpen}
                setModalOpen={setPortfolioFilterModalOpen}
            />
        </>
    )
}

export default withTranslation()(AddPortfolioModal)