import React, { useState, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import CheckboxList from '../../uiComponents/common/CheckboxList';
import ServiceTableWithSelect from '../../uiComponents/common/ServiceTableWithSelect';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import ChoosenServicesList from '../../uiComponents/common/ChoosenServicesList';
import InputCheckbox from '../../uiComponents/inputs/InputCheckbox';
import CommonSliderWrapper from '../../uiComponents/common/CommonSliderWrapper';
import PlusLineIcon from '../../uiComponents/icons/PlusLineIcon';
import Divider from '../../uiComponents/Divider';
import ProviderScheduleBookingCalender from '../../uiComponents/common/ProviderScheduleBookingCalender';
import WorkerCard from '../../pages/customer_panel/components/WorkerCard';

const AddNewBookingSecoundStep = ({ t, editBookingId, activeStep, setActiveStep, handleModalClose }) => {
    const sliderRef = useRef(null);
    const [selectedWorkerList, setSelectedWorkerList] = useState([])



    const handleWorkerSelection = (option) => {
        if (selectedWorkerList?.includes(option)) {
            setSelectedWorkerList(selectedWorkerList?.filter(item => item !== option))
        } else {
            setSelectedWorkerList([...selectedWorkerList, option]);
        }
    };


    return (

        <div className={`modal-body ${activeStep === 2 ? "block" : 'hidden'}`}>
            <ChoosenServicesList label={t('form_input_label.choose_services')} />

            <div className='w-full mt-3'>
                <LabelWrap labelClassName={"font-medium mb-0"} label={t('form_input_label.choose_worker')} />
                <CommonSliderWrapper
                    ref={sliderRef}
                    defaultArrows={false}
                    sildNoShow={true}
                    groupClassName="sm:max-w-[calc(100%-100px)] sm:flex sm:gap-2 sm:items-center"
                    slidesToShow={5}
                    xsSlidesToShow={1}
                    smSlidesToShow={2}
                    mdSlidesToShow={3}
                    lgSlidesToShow={4}
                    arrowBtnGroupClassName={"mt-0 gap-2"}
                    countClassName="min-w-[28px] text-center"
                >
                    {
                        [...Array(8)].map((item, index) => (
                            <InputCheckbox
                                key={index}
                                name="workerList"
                                groupClassName={"flex-grow w-full w-full h-full p-1"}
                                className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                text={
                                    <>
                                        <div className='w-10 h-10 md:h-[55px] md:w-[55px] overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                            <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover' alt="" />
                                        </div>
                                        <span className="text-base xl:text-lg flex-grow text-center block break-words">Michael J.</span>
                                    </>
                                }
                                value={`worker_id_____${index + 1}`}
                                checked={selectedWorkerList.includes(`worker_id_____${index + 1}`) ? true : false}
                                onChange={(item) => handleWorkerSelection(item.target.value)}
                            />
                        ))
                    }
                </CommonSliderWrapper>

            </div>


            <div className='py-4'>
                <WorkerCard badgeSlider={true} />
            </div>



            <ButtonPrimary variant={"outline"} className={"w-full mt-3"} icon={<PlusLineIcon className="w-5 h-5" />} size="sm">Worker</ButtonPrimary>


            <Divider className={"my-4"} />


            <div className='w-full mt-3'>
                <LabelWrap labelClassName={"font-medium mb-0"} label={t('form_input_label.choose_date_time')} />
                <ProviderScheduleBookingCalender />
            </div>


            <div className='w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3 mt-3'>
                <ButtonPrimary size="sm" className="w-full !bg-danger-950 border-danger-950 hover:border-danger-950 " onClick={handleModalClose}>Cancle</ButtonPrimary>
                <ButtonPrimary size="sm" className="w-full" onClick={handleModalClose} >Save</ButtonPrimary>
            </div>

        </div>
    )
}

export default withTranslation()(AddNewBookingSecoundStep)
