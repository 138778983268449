import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ServiceSpecialistTable from '../pages/vendor_panel/profiles/component/ServiceSpecialistTable';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CheckboxList from '../uiComponents/common/CheckboxList';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import LabelWrap from '../uiComponents/inputs/LabelWrap';
// const { Column, ColumnGroup } = Table;

import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import Http from '../../../Http';
import { PROFILE_COMPLETE_URL } from '../../api/axios';
import { authStore } from '../../contexts/AuthProvider';
import CategoryCheckBoxList from '../uiComponents/common/CategoryCheckBoxList';

const ChooseSpecialitiesModal = ({ t, modalOpen, setModalOpen, selectedServices, setSelectedServices, selectedIds, setSelectedIds }) => {

    const [categoryChoose, setCategoryChoose] = useState([]);
    const [serviceChoose, setServiceChoose] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [serviceType, setServiceTypeData] = useState([]);

    const salonId = authStore((state) => state.salonDataId);


    const subCategory = async (id) => {
        return await Http.post(PROFILE_COMPLETE_URL.categories, {
            service_type_ids: id,
            salonId: salonId ?? null
        }).then((res) => setCategoryData(res.data.data));
    };

    const { mutateAsync: serviceTypeData } = useMutation(
        async (data) => {
            return await Http.get(PROFILE_COMPLETE_URL.serviceTypes).then(
                (res) => res.data
            );
        },
        {
            onSuccess: async ({ data }) => {
                setServiceTypeData(data);
                if (data.length) {
                    subCategory([data[0].id]);
                }
            },
            onError: (error) => {
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            },
        }
    );

    const { mutateAsync: salonService } = useMutation(
        async (data) => {
            return await Http.post(PROFILE_COMPLETE_URL.servicesFilter, {
                salon_id: salonId,
                service_type_ids: data?.service_type_ids,
                category_ids: data?.category_ids,
            }).then((res) => res.data);
        },
        {
            onSuccess: async (res) => {
                setServiceList(res.data.data);

            },
            onError: (error) => {
                enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                    variant: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
            },
        }
    );

    useEffect(() => {
        if (modalOpen == true) {
            serviceTypeData();
            salonService();
        }
    }, [modalOpen]);

    useEffect(() => {
        if (serviceChoose.length && categoryChoose.length) {
            salonService({
                service_type_ids: serviceChoose,
                category_ids: categoryChoose,
            });
        }
    }, [categoryChoose]);

    return (

        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <form id={"add-bulk-address"} className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1100px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">{t("worker_info.choose_specialities")}</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body ">
                                    <div className="form-group mb-4">
                                        <LabelWrap labelClassName={"font-medium mb-1 text-[15px]"} label={t("form_input_label.service_type")}></LabelWrap>
                                        {/* <CheckboxList selectAllId="one" serviceType={serviceType} /> */}

                                        <CheckboxList selectAllId="one" id="service_types"
                                            serviceType={serviceType}
                                            categoryChoose={categoryChoose}
                                            setServiceChoose={setServiceChoose}
                                            setCategoryChoose={setCategoryChoose}
                                            subCategory={subCategory}
                                            salonService={salonService} />
                                    </div>
                                    <div className="form-group mb-5">
                                        <LabelWrap labelClassName={"font-medium mb-1 text-[15px]"} label={t("form_input_label.categories")}></LabelWrap>
                                        <CategoryCheckBoxList
                                            selectAllId="two"
                                            category={categoryData}
                                            setCategoryChoose={setCategoryChoose}
                                            salonService={salonService}
                                            serviceChoose={serviceChoose}
                                            groupClassName="flex-grow"
                                            id="categories" />
                                    </div>

                                    <div className="category_table_wrapper mb-6">

                                        <ServiceSpecialistTable tableClass="min-w-[800px]" serviceList={serviceList} salonService={salonService} serviceChoose={serviceChoose} categoryChoose={categoryChoose} setSelectedServices={setSelectedServices} selectedServices={selectedServices} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
                                    </div>

                                    <div className='flex flex-col justify-center items-center w-full'>
                                        <ButtonPrimary size="sm" children={t("button.save")} className="w-full max-w-[300px]" onClick={() => setModalOpen(false)} type="button" />
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </form>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(ChooseSpecialitiesModal)
