import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import ServiceDetailInfo from '../pages/vendor_panel/salonProfile/component/ServiceDetailInfo'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import ImageIcon from '../uiComponents/icons/ImageIcon'
import MaleIcon from '../uiComponents/icons/MaleIcon'
import ChildNewIcon from '../uiComponents/icons/ChildNewIcon'
import FeMaleIcon from '../uiComponents/icons/FeMaleIcon'
import ImageIconSingle from '../uiComponents/icons/ImageIconSingle'
import Divider from '../uiComponents/Divider'
import InputCheckBoxGroup from '../uiComponents/inputs/InputCheckBoxGroup'
import InputCheckbox from '../uiComponents/inputs/InputCheckbox'
import Badge from '../uiComponents/common/Badge'
import CommonSliderWrapper from '../uiComponents/common/CommonSliderWrapper'

const ServiceMoreInfoModal = ({ t, modalOpen, setModalOpen, ...props }) => {

    const sliderRef = useRef(null)

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[1180px] transform rounded-[10px] bg-white lg:p-5 p-3 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-1">
                                    <h2 className="font-semibold text-xl text-center text-blue-950 mb-2">Woman Haircut</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {/* <ServiceDetailInfo /> */}
                                    <div className="border-[1.5px] border-success-100 rounded-2xl p-3 mb-2.5">
                                        <div className='flex justify-center items-center'>
                                            <span className='w-full fle justify-start max-w-[30px] items-start'><ImageIconSingle className="w-6 h-6 text-blue-950/40" /></span>
                                            <span className='text-sm text-blue-950/40 font-medium w-full justify-center items-center text-center'>Salon & On The Go</span>
                                            <div className="flex justify-end items-end w-full max-w-[50px]">
                                                <MaleIcon className="w-3 md:w-3.5" />
                                                <ChildNewIcon className="w-2.5 md:w-3 text-blue-950/15 " />
                                                <FeMaleIcon className="w-3 md:w-3.5" />
                                                <ChildNewIcon className="w-2.5 md:w-3 text-blue-950/15" />
                                            </div>
                                        </div>
                                        <h5 className='text-center text-sm font-normal text-blue-950 mt-3'>Haircut, wash & Styling, those are the services that will be part Haircut, wash & Styling, those are the services that will be part Haircut, wash & Styling, those are the best.</h5>


                                        <Divider className="w-full my-2.5 md:my-3.5" />


                                        <div className='w-full flex flex-col justify-center items-center'>
                                            <h4 className='text-blue-950 text-base font-semibold mb-1 md:mb-2'>Price</h4>
                                            <ul className='flex flex-col xsm:flex-row gap-y-1 border-[1.5px] border-success-100 py-1 px-0 rounded-lg w-full xsm:w-fit xsm:rounded-full '>
                                                <InputCheckbox
                                                    size={"lg"}
                                                    titleClass="text-12 sm:text-[13px] xl:text-[15px]"
                                                    className={"flex items-start ps-3 peer-checked:before:bg-success-800 before:mt-[2px] lg:before:mt-[0.5px]  peer-checked:before:border-success-800 text-base lg:text-base before:absolute before:end-3 before:rounded-full peer-checked:after:!start-[unset] peer-checked:after:end-[18px] md:peer-checked:after:end-5 peer-checked:after:!top-1 border-e-0 xsm:border-e-[1.5px]  justify-start min-w-full xsm:min-w-[100px] lg:min-w-[120px]"}
                                                    text={"Normal"}
                                                />
                                                <InputCheckbox
                                                    size={"lg"}
                                                    titleClass="text-12 sm:text-[13px] xl:text-[15px]"
                                                    className={"flex items-start ps-3 peer-checked:before:bg-success-800 before:mt-[2px] lg:before:mt-[0.5px]  peer-checked:before:border-success-800 text-base lg:text-base before:absolute before:end-3 before:rounded-full peer-checked:after:!start-[unset] peer-checked:after:end-[18px] md:peer-checked:after:end-5 peer-checked:after:!top-1 border-e-0 xsm:border-e-[1.5px]  justify-start min-w-full xsm:min-w-[100px] lg:min-w-[120px]"}
                                                    text={"Student"}
                                                />
                                                <InputCheckbox
                                                    size={"lg"}
                                                    titleClass="text-12 sm:text-[13px] xl:text-[15px]"
                                                    className={"flex items-start ps-3 peer-checked:before:bg-success-800 before:mt-[2px] lg:before:mt-[0.5px]  peer-checked:before:border-success-800 text-base lg:text-base before:absolute before:end-3 before:rounded-full peer-checked:after:!start-[unset] peer-checked:after:end-[18px] md:peer-checked:after:end-5 peer-checked:after:!top-1 justify-start min-w-full xsm:min-w-[130px] lg:min-w-[150px]"}
                                                    text={"Senior (+65)"}
                                                />
                                            </ul>

                                            <p className='text-[15px] text-text_light_gray py-1 md:py-2'>(Original 600)</p>
                                            <Badge text={"-20%"} groupClassName={"border-danger-950 bg-danger-950 mb-0 text-white text-sm md:text-base py-[0.5px] px-2"} />
                                            <p className='text-[15px] md:text-[17px] font-500 text-danger-950 pt-1 md:py-2'>400 Dkk</p>
                                        </div>

                                        <Divider className="w-full my-2.5 md:my-3.5" />

                                        <CommonSliderWrapper
                                            centerMode={false}
                                            ref={sliderRef}
                                            groupClassName="max-w-full flex sm:gap-2 items-start w-full"
                                            slidesToShow={7}
                                            xxsSlidesToShow={1}
                                            xsSlidesToShow={2}
                                            smSlidesToShow={3}
                                            mdSlidesToShow={4}
                                            lgSlidesToShow={5}
                                            xlSlidesToShow={6}
                                            xxlSlidesToShow={7}
                                            infinite={false}
                                            prevArrowClassName={"absolute top-1/2 z-10 -translate-y-1/2 -start-2 rounded-full bg-primary1 text-white border-primary1"}
                                            nextArrowClassName={"absolute top-1/2 -translate-y-1/2 -end-2 rounded-full bg-primary1 text-white border-primary1"}
                                            arrowBtnGroupClassName={"mt-2 gap-2"}
                                            SliderClassName={"slider_flex gap_14 h-full [&_.slick-track]:mx-0"}
                                        >
                                            {
                                                [...Array(5)].map((item, index) => (
                                                    <div className='h-[193px] w-[145px] overflow-hidden rounded-lg object-cover object-center bg-white flex-shrink-0'>
                                                        <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                ))
                                            }
                                        </CommonSliderWrapper>
                                    </div>


                                    <div className='w-full flex flex-col xsm:flex-row justify-between gap-x-3 md:gap-x-5 gap-y-1.5'>
                                        <ButtonPrimary size="sm" variant="outline" className="  text-base    lg:text-lg w-full">Close</ButtonPrimary>
                                        <ButtonPrimary size="sm" className="  text-base  lg:text-lg w-full" >Add to Booking</ButtonPrimary>
                                    </div>
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(ServiceMoreInfoModal)