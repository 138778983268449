import React from 'react'
import Divider from '../Divider'
import { cn } from '../../../../lib/utils'
import { withTranslation } from 'react-i18next';

const WorkHoursList = ({ t, listHeadingIcon, hours, listHeading, titleClassName, itemClassName, textDayClassName, textTimeClassName, itemGroupClassName, className }) => {
    return (
        <div className={cn("work-hour-list", className)}>
            <div className="list-heading">
                <h4 className={cn('text-sm flex items-center justify-center gap-2', titleClassName)}>{listHeadingIcon ? <span>{listHeadingIcon}</span> : ""} {listHeading}</h4>
            </div>
            <Divider className={"my-1.5"} />
            <div className="list-body">
                <ul className={cn("[&_li:not(:last-child)]:mb-0.5", itemGroupClassName)}>
                    {hours?.map((dayInfo, index) => (
                        <li key={index} className={cn("flex items-center gap-2 justify-between", itemClassName)}>
                            <span className={cn('text-sm text-blue-950', textDayClassName)}>{t(dayInfo.day)}</span>
                            <span className={cn('flex items-center gap-1 text-sm text-blue-950', textTimeClassName)}>
                                {dayInfo.slots.map((slot, idx) => (
                                    <React.Fragment key={idx}>
                                        <span>{slot.from}</span>
                                        <span> - </span>
                                        <span>{slot.to}</span>
                                    </React.Fragment>
                                ))}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default withTranslation()(WorkHoursList)