import { Dialog, Transition } from '@headlessui/react'
import { enqueueSnackbar } from 'notistack'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import Http from '../../../Http'
import { IMAGE_UPLOAD, PROFILE_COMPLETE_URL } from '../../api/axios'
import { authStore } from '../../contexts/AuthProvider'
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary'
import AlertIcon from '../uiComponents/icons/AlertIcon'
import CloseIcon from '../uiComponents/icons/CloseIcon'
import DragandDropIcon from '../uiComponents/icons/DragandDropIcon'
import DropZone from '../uiComponents/inputs/DropZone'

const IdentificationModal = ({ identificationModalOpen, setIdentificationModalOpen, refetchPersonalInfoData, t }) => {
    const salonId = authStore((state) => state.salonDataId);
    const updateDocumentStatus = authStore((state) => state.updateDocumentStatus);
    const [fileList, setFileList] = useState([]);
    const userData = authStore((state) => state.user);
    const createdDate = new Date(userData?.created_at);
    const currentDate = new Date();
    const differenceInTime = currentDate.getTime() - createdDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    const [documentError, setDocumentError] = useState(false);
    const { mutateAsync: imageUpload } = useMutation(async (formData) => {
        formData.env = "test";
        return await Http.post(IMAGE_UPLOAD.uploadImage, formData).then(
            (res) => res.data
        );
    });
    const values = {};
    const setOnDone = async (e) => {
        if (fileList.length > 0) {
            setDocumentError(false);
            const formData = new FormData();
            formData.append("model", "provider_document");
            formData.append("document", fileList[0].image);
            const imageUploadData = await imageUpload(formData);
            values.document = imageUploadData.name;
            values.salon_id = salonId;
            await updateDocFile(values);
            setIdentificationModalOpen(false);
            refetchPersonalInfoData();

        } else {
            setDocumentError(true);
            // enqueueSnackbar("Please upload an id document", {
            //     variant: "error",
            //     anchorOrigin: { vertical: "top", horizontal: "right" },
            //     autoHideDuration: 2000,
            // });
        }

    }

    const handleCloseClick = () => {
        setIdentificationModalOpen(false);
        setDocumentError(false);
        setFileList([]);
    }

    useEffect(() => {
        if (fileList.length > 0) {
            setDocumentError(false);
        }
    })


    const { mutateAsync: updateDocFile } = useMutation(
        async (data) => {
            return await Http.post(
                PROFILE_COMPLETE_URL.updateDocument, data);
        },
        {
            onSuccess: ({ data }) => {
                updateDocumentStatus(1);
                enqueueSnackbar("Doc file updated succesfully.", {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                // setModalOpen(false)
            },
            onError: (error) => {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                }
            },
        }
    );

    return (

        <Transition appear show={identificationModalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[9999]" onClose={() => setIdentificationModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[560px] transform rounded-[10px] bg-white lg:p-8 sm:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header mb-6">
                                    <div className="flex items-center justify-center gap-2">
                                        <AlertIcon className="w-6 h-6" />
                                        <h2 className="text-[22px] leading-[26px] font-semibold text-blue-950">{t("upload_document_modal.verify_identity_needed")}</h2>
                                    </div>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={handleCloseClick}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body text-center">
                                    <div className="text-[15px] leading-normal text-blue-950 font-normal">
                                        <p className='mb-3'>({t('upload_document_modal.required_by_dansh_laws')}.)</p>
                                        <p className=''>{t('upload_document_modal.you_have')} <span className='font-semibold'> {t('upload_document_modal.7_days')} </span> {t('upload_document_modal.to_upload_the_required_documents')}</p>
                                        <p className='mb-3 text-danger-950 font-semibold'>{t('upload_document_modal.due_date')} 12.01.24</p>
                                        <p className='mb-3 font-medium'>{t('upload_document_modal.upload_document_to_receive_booking')}</p>
                                    </div>
                                    <DropZone
                                        id="document_upload"
                                        className="flex-row gap-10"
                                        fileSelected={fileList}
                                        setFileSelected={setFileList}
                                        icon={<DragandDropIcon className="h-7 w-7 text-primary1" />}
                                        groupClassName="mb-4"
                                        labelClassName="max-w-[325px] mx-auto p-4 xl:p-6 bg-light-950"
                                        single={true}
                                    />
                                    {documentError && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{t('validation_message.upload_document')}</p>}
                                    <ButtonPrimary type="button" size="sm" children={t("button_label.done")} className="w-full my-4 max-w-[325px] mx-auto" onClick={() => setOnDone()} />
                                    {differenceInDays < 7 ? (
                                        <ButtonPrimary
                                            onClick={handleCloseClick}
                                            type="button"
                                            size="sm"
                                            variant="outline"
                                            children={t('button_label.remind_me_later')}
                                            className="w-full mb-4 max-w-[325px] mx-auto"
                                        />
                                    ) : (
                                        <ButtonPrimary
                                            onClick={handleCloseClick}
                                            size="sm"
                                            type="button"
                                            variant="outline"
                                            children={t("comman_text.contact_support")}
                                            className="w-full max-w-[325px] mx-auto"
                                        />
                                    )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(IdentificationModal)