import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Card from '../../../../uiComponents/Card'
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap'
import CheckboxList from '../../../../uiComponents/common/CheckboxList'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon'
import ServiceTable from './ServiceTable'
import { PATH_PROVIDER } from '../../../../../routes/path'
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import { useMutation } from 'react-query'
import Http from '../../../../../../Http'
import { enqueueSnackbar } from 'notistack'
import CategoryCheckBoxList from '../../../../uiComponents/common/CategoryCheckBoxList'
import { useNavigate } from 'react-router-dom'
import { authStore } from '../../../../../contexts/AuthProvider'
import { isEmpty } from 'lodash'

const Services = ({ t, stepsNextClick,profile_setup,filterData, ...props }) => {

  const updateSalonProfileStep = authStore((state) => state.updateSalonProfileStep);
  const is_profile_complete = authStore((state) => state.is_profile_complete);
  const salonProfileStep = authStore((state) => state.salonProfileStep);

  const [categoryData, setCategoryData] = useState([]);
  const [serviceType, setServiceTypeData] = useState([]);
  const [serviceChoose, setServiceChoose] = useState([]);
  const [categoryChoose, setCategoryChoose] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serviceTotal, setServiceTotal] = useState(0);

  const salonId = authStore((state) => state.salonDataId);
  const navigate = useNavigate()

  const subCategory = async (id) => {
    return await Http.post(PROFILE_COMPLETE_URL.categories, {
      service_type_ids: id,
      salonId: salonId ?? null
    }).then((res) => setCategoryData(res.data.data));
  };

  const { mutateAsync: serviceTypeData } = useMutation(
    async (data) => {
      return await Http.get(PROFILE_COMPLETE_URL.serviceTypes).then(
        (res) => res.data
      );
    },
    {
      onSuccess: async ({ data }) => {
        setServiceTypeData(data);
        if (data.length) {
          subCategory([data[0].id]);
        }
      },
      onError: (error) => {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      },
    }
  );

  const { mutateAsync: salonService } = useMutation(
    async (data) => {
      if(profile_setup === true) {
        return await Http.post(PROFILE_COMPLETE_URL.servicesFilter, {
          salon_id: salonId,
          service_type_ids: data?.service_type_ids,
          category_ids: data?.category_ids,
          promotions: filterData?.promotions
        }).then((res) => res?.data);
      }else {
        data = {...filterData,service_type_ids : serviceChoose,category_ids : categoryChoose,salon_id:salonId}
        return await Http.post(PROFILE_COMPLETE_URL.servicesFilterModal, data).then((res) => res?.data)
      }
    },
    {
      onSuccess: async (res) => {
        // setLoadingScreen(false)
        setServiceTotal(res?.data.total);
        setServiceList(res?.data.data);

      },
      onError: (error) => {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      },
    }
  );

  const handleAddServices = () => {
    navigate(PATH_PROVIDER.addService, { state: { salonId: salonId, profile_setup: profile_setup } })
  }
  const { mutateAsync: serviceStepUpdate } = useMutation(
    async (data) => {
      return await Http.post(PROFILE_COMPLETE_URL.serviceStepUpdates, {
        salon_id: salonId

      }).then(
        (res) => res.data
      );
    },
    {
      onSuccess: async ({ data }) => {
        if (is_profile_complete == 0 && salonProfileStep == 2 && profile_setup == true) {
          updateSalonProfileStep(3);
          stepsNextClick();
        }
      },
      onError: (error) => {
        enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      },
    }
  );


  const stepClick = () => {

    if (is_profile_complete == 0 && salonProfileStep == 2 && profile_setup == true) {
      serviceStepUpdate()
    } else {
      stepsNextClick();
    }
  };

  useEffect(() => {
    serviceTypeData();
    salonService();
  }, [salonId]);

  useEffect(() => {
    
    if(filterData && !isEmpty(filterData)){
      // serviceTypeData();
      salonService();
    }
  }, [filterData]);

  useEffect(() => {
    if (serviceChoose.length && categoryChoose.length) {
      salonService({
        service_type_ids: serviceChoose,
        category_ids: categoryChoose,
      });
    }
  }, [categoryChoose]);

  return (
    <Card>
      <div className="mb-4">
        <LabelWrap labelClassName={"font-medium mb-2"} label={t("comman_text.service_type_name")}></LabelWrap>
        <CheckboxList selectAllId="one" id="service_types"
          serviceType={serviceType}
          categoryChoose={categoryChoose}
          setServiceChoose={setServiceChoose}
          setCategoryChoose={setCategoryChoose}
          subCategory={subCategory}
          salonService={salonService} />
      </div>
      <div className="mb-4">
        <LabelWrap labelClassName={"font-medium mb-2"} label={t('form_input_label.categories')}></LabelWrap>
        <div className="flex items-center justify-start flex-wrap gap-2">
          <CategoryCheckBoxList
            selectAllId="two"
            category={categoryData}
            setCategoryChoose={setCategoryChoose}
            salonService={salonService}
            serviceChoose={serviceChoose}
            groupClassName="flex-grow"
            id="categories" />
          <ButtonPrimary className={"flex-shrink-0 lg:text-base text-base font-bold rounded-[5px] py-1 px-3"} onClick={handleAddServices} size={"sm"} icon={<PlusLineIcon className="w-5 h-5" />} >{t("check_box_label.service")}</ButtonPrimary>
        </div>
      </div>
      <ServiceTable serviceList={serviceList} salonService={salonService} serviceChoose={serviceChoose} categoryChoose={categoryChoose} />
      <div className="flex items-center justify-end sm:flex-row flex-col gap-2.5">
        {/* <ButtonPrimary size={"sm"} className={"w-full sm:w-[200px]"} variant={"outline"}>Skip</ButtonPrimary> */}

        {serviceTotal != 0 ? (
          <ButtonPrimary size={"sm"} className={"w-full sm:w-[200px]"} onClick={() => { stepClick(); }} type="button">Continue</ButtonPrimary>
        ) : (
          ""
        )}

      </div>
    </Card>
  )
}

export default withTranslation()(Services)