import { useEffect, useState } from "react";
import create from "zustand";
import createContext from "zustand/context";
import { devtools, persist } from "zustand/middleware";
import { AUTH_API_URL, CUSTOMER_AUTH_API_URL, FRONT_API_URL } from "../api/axios";
import Http from "../../Http";
import { isEmpty } from "lodash";
const { Provider, useStore } = createContext();
const middleware_ = (f) =>
  create(devtools(persist(f, { name: "auth-storage" })));
// const middleware_ = (f) => create(devtools(f, { name: "auth-storage" }));

const authStore = middleware_((set, get) => ({
  isAuthenticated: false,
  isInitialized: false,
  accessToken: "",
  method: "jwt",
  rememberToken: "",
  user: "",
  profileType: "",
  documentStatus: "",
  salonDataId: "",
  service_location: "",
  salonProfileStep: 0,
  providerId: 0,
  becomeWorkerStep: [],
  isRefreshing: false,
  selectedAuthTab: 1,
  is_profile_complete: 0,
  is_registred: 0,
  become_worker_profile: 0,
  becomeWorkerStatus: false,
  is_become_worker: 0,
  loginStep: 0,
  sideBarToggle: false,

  setInitialized: () => set({ isInitialized: true }),

  login: async (loginObj) => {
    try {

      const response = await Http.post(AUTH_API_URL.login, loginObj);
      if (response?.data?.success) {
        Http.setBearerToken(response?.data?.data?.token);
        localStorage.setItem("conversation", JSON.stringify([1]))
        localStorage.setItem("document_status", response?.data?.data.document_status)

        set({
          accessToken: response?.data?.data?.token,
          profileType: response?.data?.data.type,
          documentStatus: response?.data?.data.document_status,
          service_location: response?.data?.data.service_location,
          user: response?.data?.data,
          profileId: response?.data?.data.id,
          salonProfileStep: response?.data?.data.step,
          becomeWorkerStep: response?.data?.data.become_worker_step,
          loginStep: response?.data?.data.step,
          is_profile_complete: response?.data?.data.is_profile_complete,
          become_worker_profile: response?.data?.data.become_worker_profile,
          isAuthenticated: true,
        });
      }
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerLogin: async (loginObj) => {
    try {
      const response = await Http.post(CUSTOMER_AUTH_API_URL.login, loginObj);
      if (response?.data?.success) {
        Http.setBearerToken(response?.data?.data?.token);
        set({
          accessToken: response?.data?.data?.token,
          profileId: response?.data?.data.id,
          user: response?.data?.data,
          isAuthenticated: true,
          loginStep: response?.data?.data.step,

        });
      }
      return [response?.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  register: async (signUpObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.register, signUpObj);
      if (response.data) {
        if (!isEmpty(signUpObj?.social_id)) {
          Http.setBearerToken(response?.data?.data?.token);
          set({
            accessToken: response?.data?.data?.token,
            profileType: response?.data?.data.type,
            documentStatus: response?.data?.data.document_status,
            service_location: response?.data?.data.service_location,
            user: response?.data?.data,
            salonProfileStep: response?.data?.data.step,
            becomeWorkerStep: response?.data?.data.become_worker_step,
            loginStep: response?.data?.data.step,
            is_profile_complete: response?.data?.data.is_profile_complete,
            become_worker_profile: response?.data?.data.become_worker_profile,
            isAuthenticated: true,
          });
        } else {
          set({
            rememberToken: response?.data?.data?.remember_token,
          });
        }
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerRegister: async (signUpObj) => {
    try {
      const response = await Http.post(
        CUSTOMER_AUTH_API_URL.register,
        signUpObj
      );
      if (response.data) {
        if (!isEmpty(signUpObj?.social_id)) {
          Http.setBearerToken(response?.data?.data?.token);
          set({
            accessToken: response?.data?.data?.token,
            user: response?.data?.data,
            profileId: response?.data?.data.id,
            rememberToken: "",
            isAuthenticated: true,
          });
        } else {
          set({
            rememberToken: response?.data?.data?.remember_token,
          });
        }
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  otpVerify: async (otpDataObj) => {
    const otpObj = {
      otp: otpDataObj.otp,
    };
    try {
      const response = await Http.post(AUTH_API_URL.otpVerify, otpObj, {
        headers: { "remember-token": otpDataObj.rememberToken },
      });
      if (response?.data) {
        Http.setBearerToken(response?.data?.data?.token);
        set({
          accessToken: response?.data?.data?.token,
          profileType: response?.data?.data.type,
          documentStatus: response?.data?.data.document_status,
          service_location: response?.data?.data?.service_location,
          user: response?.data?.data,
          salonProfileStep: response?.data?.data.step,
          becomeWorkerStep: response?.data?.data.become_worker_step,
          loginStep: response?.data?.data.step,
          profileId: response?.data?.data.id,
          is_profile_complete: response?.data?.data.is_profile_complete,
          become_worker_profile: response?.data?.data.become_worker_profile,
          isAuthenticated: true,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerOtpVerify: async (otpDataObj) => {
    const otpObj = {
      otp: otpDataObj.otp,
    };
    try {
      const response = await Http.post(
        CUSTOMER_AUTH_API_URL.otpVerify,
        otpObj,
        {
          headers: { "remember-token": otpDataObj.rememberToken },
        }
      );
      if (response?.data) {
        Http.setBearerToken(response?.data?.data?.token);
        set({
          accessToken: response?.data?.data?.token,
          user: response?.data?.data,
          profileId: response?.data?.data.id,
          rememberToken: "",
          isAuthenticated: true,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  resendOtp: async (otpDataObj) => {
    try {
      const response = await Http.post(AUTH_API_URL.resendOtp, [], {
        headers: { "remember-token": otpDataObj.rememberToken, user_type: 2 },
      });
      if (response?.data) {
        set({
          rememberToken: response?.data?.data?.remember_token,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerResendOtp: async (otpDataObj) => {
    try {
      const response = await Http.post(CUSTOMER_AUTH_API_URL.resendOtp, [], {
        headers: { "remember-token": otpDataObj.rememberToken },
      });
      if (response?.data) {
        set({
          rememberToken: response?.data?.data?.remember_token,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  forgetOtpVerify: async (otpDataObj) => {
    const otpObj = {
      otp: otpDataObj.otp,
    };
    try {
      const response = await Http.post(
        AUTH_API_URL.forgotPasswordOtpVerify,
        otpObj,
        {
          headers: {
            "remember-token": otpDataObj.verifyOtpToken,
          },
        }
      );
      if (response?.data) {
        set({
          user: response?.data?.data,
          verifyOtpToken: response?.data?.data?.token,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerForgetOtpVerify: async (otpDataObj) => {
    const otpObj = {
      otp: otpDataObj.otp,
    };
    try {
      const response = await Http.post(
        CUSTOMER_AUTH_API_URL.forgotPasswordOtpVerify,
        otpObj,
        {
          headers: {
            "remember-token": otpDataObj.verifyOtpToken,
          },
        }
      );
      if (response?.data) {
        set({
          user: response?.data?.data,
          verifyOtpToken: response?.data?.data?.token,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  forgetPassword: async (forgetPwdObj) => {
    try {
      const response = await Http.post(
        AUTH_API_URL.forgotPassword,
        forgetPwdObj
      );
      if (response.data) {
        set({
          verifyOtpToken: response?.data?.data?.remember_token,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerForgetPassword: async (forgetPwdObj) => {
    try {
      const response = await Http.post(
        CUSTOMER_AUTH_API_URL.forgotPassword,
        forgetPwdObj
      );
      if (response.data) {
        set({
          verifyOtpToken: response?.data?.data?.remember_token,
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  resetPassword: async (forgetPwdObj) => {
    try {
      const response = await Http.post(
        FRONT_API_URL.resetPassword,
        forgetPwdObj,
        {
          headers: { "remember-token": forgetPwdObj.token },
        }
      );
      if (response.data) {
        set({
          verifyOtpToken: "",
          rememberToken: "",
          user: "",
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerRsetPassword: async (forgetPwdObj) => {
    try {
      const response = await Http.post(
        CUSTOMER_AUTH_API_URL.resetPassword,
        forgetPwdObj,
        {
          headers: { "remember-token": forgetPwdObj.token },
        }
      );
      if (response.data) {
        set({
          verifyOtpToken: "",
          rememberToken: "",
          user: "",
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  logout: async () => {
    try {
      const response = await Http.post(AUTH_API_URL.logout);
      if (response.data) {
        set({
          user: {},
          rememberToken: "",
          verifyOtpToken: "",
          isAuthenticated: false,
          accessToken: "",
          profileType: "",
          documentStatus: "",
          service_location: "",
          salonDataId: "",
          loginStep: 0,
          is_profile_complete: 0,
          is_become_worker: 0,
          becomeWorkerStep: [],
          salonProfileStep: 0,
          become_worker_profile: 0,
          is_registred: 0
        });
      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  customerLogout: async () => {
    try {
      const response = await Http.post(CUSTOMER_AUTH_API_URL.logout);
      if (response.data) {
        set({
          user: {},
          rememberToken: "",
          verifyOtpToken: "",
          isAuthenticated: false,
          accessToken: "",
          profileType: "",
          documentStatus: "",
          service_location: "",
          is_become_worker: 0,
          salonDataId: "",
        });
        localStorage.clear();

      }
      return [response.data, null];
    } catch (err) {
      return [null, err.response.data];
    }
  },

  updateUserType: (data) => {
    set({
      profileType: data,
    });
  },

  updateDocumentStatus: (data) => {
    set({
      documentStatus: data,
    });
  },

  updateSalonServiceLocation: (data) => {
    set({
      service_location: data,
    });
  },

  updateBecomeWorker: (data) => {
    set({
      is_become_worker: data,
    });
  },

  updateSalonProfileStep: (data) => {
    set({
      salonProfileStep: data,
    });
  },

  updateProviderId: (data) => {
    set({
      providerId: data,
    });
  },

  updateBecomeWorkerStep: (data) => {
    set({
      becomeWorkerStep: data,
    });
  },

  updateBecomeWorkerStatus: (data) => {
    set({
      becomeWorkerStatus: data,
    });
  },

  updateLoginStep: (data) => {
    set({
      loginStep: data,
    });
  },

  updateSaloneDataId: (data) => {
    set({
      salonDataId: data,
    });
  },

  ProfileCompleteStep: (data) => {
    set({
      is_profile_complete: data,
    });
  },

  BecomeWorkerCompleteStep: (data) => {
    set({
      become_worker_profile: data,
    });
  },

  updateUserProfile: (data) => {
    set({
      user: data,
    });
  },

  isRegistred: (data) => {
    set({
      is_registred: data,
    });
  },

  setSelectedTab: (tabVal) => {
    set({ selectedAuthTab: tabVal });
  },
  setSideBarToggle: (value) => {
    set({ sideBarToggle: value });
  },
  // refreshToken: async () => {
  //   try {
  //     const response = await axios.get(AUTH_API_URL.refreshToken, {
  //       withCredentials: true,
  //     });
  //     set({ accessToken: response?.data?.accessToken });
  //     return [response?.data?.accessToken, undefined];
  //   } catch (error) {
  //     return [undefined, true];
  //   }
  // },
}));

const AuthProvider = ({ children }) => {
  const setInitialized = authStore((state) => state.setInitialized);
  useEffect(() => {
    setInitialized();
  }, []);
  return <Provider createStore={() => authStore}>{children}</Provider>;
};

const refreshStore = create((set, get) => ({
  isRefreshing: false,
}));

export { AuthProvider, authStore, refreshStore };
export default useStore;
