import React from 'react'
import Divider from '../../../uiComponents/Divider'
import BadgeSlider from '../../../uiComponents/common/BadgeSlider'
import CommonSliderWrapper from '../../../uiComponents/common/CommonSliderWrapper'
import StudentBadgeIcon from '../../../uiComponents/icons/badge-icon/StudentBadgeIcon'
import { cn } from '../../../../../lib/utils'

const CustomerSkills = ({ colored, BadgeList, className, groupClassName, educationCardClass, edCardTitle, edCardText, certiTitleClass, certiIconClass }) => {
    return (
        <div className={cn(`border border-borderColor rounded-[10px] p-2 ${colored && "bg-light-800"}`, groupClassName)}>
            <div className={cn("text-center", educationCardClass)}>
                <h5 className={cn('text-[15px] text-blue-950', edCardTitle)}>Name of study <span className="opacity-40">(4 Years - 2012)</span></h5>
                <Divider className="my-1.5" />
                <div className="flex items-center justify-center h-[calc(100%-34px)]">
                    <p className={cn('font-light text-sm text-blue-950', edCardText)}>No Education</p>
                </div>
            </div>
            <Divider className="my-2" />
            <div className="relative">
                <CommonSliderWrapper sildNoShow slidesToShow={1} arrowButton={"w-6 h-6"} arrowIconClass={"w-3.5 h-3.5"} arrowBtnGroupClassName="justify-start mt-0" defaultArrows={false} groupClassName="grid grid-cols-2 items-center" SliderClassName="ms-auto">
                    {
                        [...Array(2)].map((items, index) => (
                            <div className="!flex items-center flex-col gap-1">
                                <span className={cn('text-[15px] font-medium text-center text-blue-950', certiTitleClass)}>Certificate</span>
                                <StudentBadgeIcon className={cn('h-[60px] w-[60px]', certiIconClass)} />
                            </div>
                        ))
                    }
                </CommonSliderWrapper>
            </div>
        </div>
    )
}

export default CustomerSkills