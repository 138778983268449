import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Card from '../../../../uiComponents/Card'
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary'
import PlusLineIcon from '../../../../uiComponents/icons/PlusLineIcon'
import RowWrap from '../../../../uiComponents/RowWrap'
import AddWorkerIcon from '../../../../uiComponents/icons/AddWorkerIcon'
// import WorkerAdd from '../WorkerAdd'
import { PATH_PROVIDER } from '../../../../../routes/path'
import Http from '../../../../../../Http'
import { PROFILE_COMPLETE_URL } from '../../../../../api/axios'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { authStore } from '../../../../../contexts/AuthProvider'
import { enqueueSnackbar } from 'notistack'

const AddWorker = ({ t, stepsNextClick, profileSetup, ...props }) => {

  const navigate = useNavigate();
  const salon_id = authStore((state) => state?.salonDataId)
  const updateSalonProfileStep = authStore((state) => state?.updateSalonProfileStep)
  const salonStep = authStore((state) => state?.salonProfileStep)
  const is_profile_complete = authStore((state) => state?.is_profile_complete);
  const profile_setup = profileSetup ?? is_profile_complete == 0 ? true : false;
  const { mutateAsync: stepUpdate } = useMutation(
    async (data) => {
      return await Http.post(PROFILE_COMPLETE_URL.workerStepStore, data);
    },
    {
      onSuccess: ({ data }) => {
        enqueueSnackbar("Countinue", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
        updateSalonProfileStep(4);
        stepsNextClick();
      },
      onError: (error) => {

        enqueueSnackbar("Something", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      },
    }
  );
  const handleSkip = async () => {
    if (is_profile_complete == 0) {
      await stepUpdate({ salon_id: salon_id })
    } else {
      enqueueSnackbar("Something", {
        variant: "danger",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  }
  return (
    <div className="">
      <Card className={"mt-2.5 flex justify-center items-center"}>
        <div className='w-full max-w-[750px] flex flex-col gap-6'>
          <div className='w-full flex justify-center items-center'>
            <div className='h-[150px] sm:h-[200px] lg:h-[260px] w-[150px] sm:w-[200px] lg:w-[260px] flex items-center justify-center border border-success-100 rounded-full'>
              <AddWorkerIcon className="w-[120px] sm:w-[160px] lg:w-[220px] h-[120px] sm:h-[160px] lg:h-[220px]" />
            </div>
          </div>
          <RowWrap className="grid md:grid-cols-2 gap-4 md:gap-6 w-full" >
            <Card className={"flex flex-col justify-between gap-3 sm:py-3.5 sm:px-5"}>
              <h5 className='text-center text-base text-blue-950'>{t('add_worker_or_create_job_portal.add_workers_text')}</h5>
              <ButtonPrimary size="sm" type="button" className={"w-full"}
                onClick={() => navigate(PATH_PROVIDER.addWorker, { state: { profile_setup: profile_setup } })} icon={<PlusLineIcon className="w-5 h-5" />}>{t('comman_text.add_worker')}</ButtonPrimary>
            </Card>
            <Card className={"flex flex-col justify-between gap-3 sm:py-3.5 sm:px-5"}>
              <h5 className='text-center text-base text-blue-950'>{t('add_worker_or_create_job_portal.create_job_portal_text')}</h5>
              <ButtonPrimary size="sm" type="button" className={"w-full"}
                onClick={() => navigate(PATH_PROVIDER.createJobPost, { state: { profile_setup: profile_setup } })}>{t('button_label.Job_portal')}</ButtonPrimary>
            </Card>
          </RowWrap>
          {salonStep >= 4 ?
            <ButtonPrimary variant={"outline"} className={"w-full"} size="sm" onClick={stepsNextClick} type="button">{t("button.continue")}</ButtonPrimary> :
            <ButtonPrimary variant={"outline"} className={"w-full"} size="sm" onClick={handleSkip} type="button">{t("button.skip")}</ButtonPrimary>}
        </div>
      </Card>
    </div>
  )
}

export default withTranslation()(AddWorker)