import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import ChooseSpecialitiesModal from '../../../../modals/ChooseSpecialitiesModal';
import Card from '../../../../uiComponents/Card';
import Divider from '../../../../uiComponents/Divider';
import RowWrap from '../../../../uiComponents/RowWrap';
import ButtonPrimary from '../../../../uiComponents/buttons/ButtonPrimary';
import Badge from '../../../../uiComponents/common/Badge';
import CloseIcon from '../../../../uiComponents/icons/CloseIcon';
import DragandDropIcon from '../../../../uiComponents/icons/DragandDropIcon';
import LocationPin2 from '../../../../uiComponents/icons/LocationPin2';
import LocationPin3 from '../../../../uiComponents/icons/LocationPin3';
import SalonChairIcon from '../../../../uiComponents/icons/SalonChairIcon';
import DateInput from '../../../../uiComponents/inputs/DateInput';
import DropZone from '../../../../uiComponents/inputs/DropZone';
import InputRadioBox from '../../../../uiComponents/inputs/InputRadioBox';
import InputWrap from '../../../../uiComponents/inputs/InputWrap';
import LabelWrap from '../../../../uiComponents/inputs/LabelWrap';
import CustomTabs from '../../../../uiComponents/tabs/CustomTabs';
import * as Yup from "yup";
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import Http from '../../../../../../Http';
import { IMAGE_UPLOAD, PROFILE_COMPLETE_URL } from '../../../../../api/axios';
import { isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { PATH_BOTH, PATH_WORKER } from '../../../../../routes/path';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utcPlugin from 'dayjs/plugin/utc';
import { authStore } from '../../../../../contexts/AuthProvider';

dayjs.extend(utcPlugin);
dayjs.extend(customParseFormat);


const ProtfolioOutsideMeeTime = ({ t, portFolliEditData, isDummy, ...props }) => {

    const [specialitiesModalOpen, setSpecialitiesModalOpen] = useState(false)
    const [imageList, setImageList] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [specialitiesList, setSpecialitiesList] = useState([])
    const [specialitiesIds, setSpecialitiesIds] = useState([])
    const navigate = useNavigate();
    const profileType = authStore((state) => state?.profileType)

    const dateChange = (selectedDate) => {
        setFieldValue('booking_date', dayjs(selectedDate).format('YYYY-MM-DD'))
    }
    const handleRemoveImage = (index) => {
        if (imageList[index].file_name && imageList[index].file_name !== "") {
            setDeletedImages([...deletedImages, imageList[index].id])
        }
        const newImages = [...imageList];
        newImages.splice(index, 1);
        setImageList(newImages);

    };

    const imageTabChange = (selected, index) => {
        let data = [...imageList];
        data[index]['portfolioType'] = selected;
        setImageList(data);
    }

    let portFolioValidation = Yup.object().shape({
        work_done_at: Yup.number().required(t("validation_message.this_field_req")),
        gender: Yup.number().required(t("validation_message.this_field_req")),
        booking_date: Yup.date().required(t("validation_message.booking_date_req")),
        service_location: Yup.number().required(t("validation_message.service_location_required")),
        description: Yup.string().required(t("validation_message.description_required")),
        services_title: Yup.string().required(t("validation_message.service_title_req")),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            work_done_at: 1,
            gender: portFolliEditData?.gender ?? "",
            booking_date: portFolliEditData?.booking_date ? dayjs(portFolliEditData?.booking_date) : null,
            service_location: portFolliEditData?.service_location ?? "",
            services_title: portFolliEditData?.services_title ?? "",
            deleted_images: [],
            description: portFolliEditData?.description ?? "",
            location: portFolliEditData?.location ?? ""
        },
        validationSchema: portFolioValidation,
        onSubmit: async (values) => {
            try {

                if (!isEmpty(imageList)) {
                    const new_images = imageList.filter(item => !item.hasOwnProperty('file_name'));
                    if (new_images.length > 0) {

                        const formData = new FormData();
                        formData.append("model", "worker_portfolio");
                        new_images.map((image) => {
                            formData.append("images[]", image.image);
                        });
                        const imageUploadData = await imageUpload(formData);
                        let imageData = imageUploadData?.name?.map((item, index) => ({ name: item, type: imageList[index]?.portfolioType }))
                        values.images = imageData;
                    }
                } else {
                    enqueueSnackbar(t("validation_message.image_req"), {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                    return false;
                }

                if (deletedImages) {
                    values.deleted_images = deletedImages;
                }
                values.booking_date =dayjs(values.booking_date).format('YYYY-MM-DD');

                const PortFolioData = await workerPortFolioData(values);
                if (PortFolioData.data.success) {
                    if(profileType != 4 ){
                        navigate(PATH_WORKER.profile, { state: { selectedTab: 4 } })
                    }else{
                        navigate(PATH_BOTH.bothProfile, { state: { selectedTab: 9 } })
                    }
                    // stepsPrevClickstepsPrevClick()
                }

            } catch (error) {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.values(errorData).forEach((value) => {
                        enqueueSnackbar(value, {
                            variant: "error",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                            autoHideDuration: 2000,
                        });
                    });
                } else {
                    console.log(error)
                    enqueueSnackbar(t("snackbar_message.something_went_wrong"), {
                        variant: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                        autoHideDuration: 2000,
                    });
                }
            }
        },
    });

    const { mutateAsync: workerPortFolioData } = useMutation(
        async (data) => {
            if (portFolliEditData) {
                return await Http.put(PROFILE_COMPLETE_URL.updatePortfolio.replace(":id", portFolliEditData.id), data);
            } else {
                return await Http.post(PROFILE_COMPLETE_URL.workerPortfolio, data);
            }
        },
        {
            onSuccess: ({ data }) => {
                resetForm();
                enqueueSnackbar(data.message, {
                    variant: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                    autoHideDuration: 2000,
                });
                // window.location.reload();
            },
            onError: (error) => {
                const errorData = error?.response?.data?.errors;
                if (errorData) {
                    Object.keys(errorData).forEach((key) => {
                        setFieldError(key, errorData[key]);
                    });
                }
            },
        }
    );
    const { mutateAsync: imageUpload } = useMutation(async (formData) => {
        formData.env = "test";
        return await Http.post(IMAGE_UPLOAD.uploadImage, formData).then(
            (res) => res.data
        );
    });
    const {
        errors,
        handleSubmit,
        touched,
        resetForm,
        getFieldProps,
        setFieldValue,
        setFieldError,
        values
    } = formik;


    useEffect(() => {

        if (portFolliEditData) {
            const data = [];
            portFolliEditData?.images?.map((item, index) => {
                data.push({
                    id: item?.id,
                    file: item?.image?.file,
                    file_name: item?.image?.file_name,
                    portfolioType: item?.type,
                });
            })
            setImageList(data);
        }

    }, [portFolliEditData])

    return (
        <Card>
            <FormikProvider value={formik}>
                <form onSubmit={handleSubmit}>

                    <Badge groupClassName={"mb-5"} text={t('portfolio.details_about_work')} />
                    <RowWrap className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-x-8 lg:gap-y-5 mb-5" lg={3}>

                        <div className="meetime-form-group">
                            <LabelWrap labelClassName="text-[15px] mb-4 w-full" label={t("worker_info.client_gender")} />
                            <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                                <InputRadioBox className="text-[15px]" text={t('comman_text.men')} name={"gender"} {...getFieldProps('gender')} onChange={() => setFieldValue("gender", 1)} checked={values.gender == 1 ? true : false} />
                                <InputRadioBox className="text-[15px]" text={t('comman_text.woman')} name={"gender"} {...getFieldProps('gender')} onChange={() => setFieldValue("gender", 2)} checked={values.gender == 2 ? true : false} />
                                <InputRadioBox className="text-[15px]" text={t('comman_text.boy')} name={"gender"} {...getFieldProps('gender')} onChange={() => setFieldValue("gender", 4)} checked={values.gender == 4 ? true : false} />
                                <InputRadioBox className="text-[15px]" text={t('comman_text.girl')} name={"gender"} {...getFieldProps('gender')} onChange={() => setFieldValue("gender", 3)} checked={values.gender == 3 ? true : false} />
                            </div>
                            {touched?.gender && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors?.gender}</p>}
                        </div>
                        <div className="">
                            <DateInput labelClassName="text-[15px]" label={t("comman_text.booking_date")} className="max-w-full" size="md" name="booking_date" onChange={(selectedDate) => dateChange(selectedDate)} value={values?.booking_date ? dayjs(values?.booking_date) : ""} maxDate={dayjs()} />
                            {touched?.booking_date && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors?.booking_date}</p>}
                        </div>
                        <div className="meetime-form-group">
                            <LabelWrap require labelClassName="text-[15px] mb-4 w-full"
                                label={t('form_input_label.service_location')}
                                prefixIcon={<LocationPin2 className="w-5 h-5" />}
                                tooltipText={"Service your customers at the Salon"}
                            />
                            <div className="flex items-center justify-between flex-wrap w-full">
                                <InputRadioBox
                                    name="service_location"
                                    className="text-[15px]"
                                    id={t('check_box_label.salon')}
                                    prefixIcon={<SalonChairIcon className="w-5 h-5" />}
                                    text={t('check_box_label.salon')}
                                    {...getFieldProps('service_location')}
                                    onChange={() => setFieldValue('service_location', 1)}
                                    checked={values?.service_location == 1 ? true : false}
                                />
                                <InputRadioBox
                                    name="service_location"
                                    className="text-[15px]"
                                    id={t('check_box_label.on_the_go')}
                                    prefixIcon={<LocationPin3 className="w-5 h-5" />}
                                    text={t('check_box_label.on_the_go')}
                                    {...getFieldProps('service_location')}
                                    onChange={() => setFieldValue('service_location', 2)}
                                    checked={values?.service_location == 2 ? true : false}
                                />
                                {touched?.service_location && <p className="error-label text-start mt-1 peer-invalid:block text-danger-950 text-sm font-medium">{errors?.service_location}</p>}
                            </div>
                        </div>
                        <div className='flex gap-3 w-full justify-end items-end'>
                            <InputWrap
                                require
                                size="sm"
                                label={t("check_box_label.service")}
                                labelClassName="text-[15px]"
                                groupClassName="w-full"
                                {...getFieldProps('services_title')}
                                errorType={touched?.services_title && "error"}
                                errorData={touched?.services_title && errors?.services_title}
                            />
                            <ButtonPrimary type="button" className={"flex-shrink-0 text-base font-semibold"} onClick={() => setSpecialitiesModalOpen(isDummy === 1 ? false : true)} size="sm" disabled={isDummy === 1 ? true : false} >Choose</ButtonPrimary>


                        </div>
                        <div className='flex gap-3 w-full justify-end items-end'>
                            <InputWrap
                                require
                                size="sm"
                                label={t("comman_text.description")}
                                labelClassName="text-[15px]"
                                groupClassName="w-full"
                                {...getFieldProps('description')}
                                errorType={touched?.description && "error"}
                                errorData={touched?.description && errors?.description}
                            />
                        </div>
                    </RowWrap>
                    <Divider className={"my-6"} />
                    <RowWrap className="flex flex-col md:flex-row gap-x-5 gap-y-0 justify-start items-start mb-5" >
                        <DropZone groupClassName={"max-w-[300px] flex-grow w-full"} labelClassName="p-2 min-h-[164px] bg-light-950 py-5"
                            heading={<LabelWrap labelClassName="text-[15px]"
                                label={<>Upload pictures <span className="text-light-450"> (Max 4)</span></>} />}
                            labelTitleClass="mt-1.5 inline" preBr
                            icon={<DragandDropIcon className="w-10 h-10 text-primary1" />}
                            fileSelected={imageList}
                            setFileSelected={setImageList}
                            limit={4}
                            multiple={true}
                        />
                        <div className='grid grid-cols-2 sm:grid-cols-4 md:grid-cols-2 lg:grid-cols-4 gap-4 min-h-[164px] sm:mt-7'>

                            {
                                imageList?.length > 0 ? imageList?.map((image, index) => {
                                    return <CustomTabs tabGroupClass="flex flex-col-reverse" tabListClass="border-[1.3px] border-primary1 mb-0 mt-1.5" tabButtonClass="!py-0 !text-sm !font-500"
                                        selectedIndex={image?.portfolioType}
                                        onChange={(selected) => imageTabChange(selected, index)}
                                        tabsData={[
                                            {
                                                id: "1_" + index,
                                                title: "After",
                                                content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                                    <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center' type="button" id={"1_" + index}
                                                        onClick={() => handleRemoveImage(index)}>
                                                        <CloseIcon className="w-4 h-4 text-white"
                                                        /></button>
                                                    <img src={image?.file} className='h-full w-full object-cover' alt="" />
                                                </div>
                                            },
                                            {
                                                id: "2_" + index,
                                                title: "Before",
                                                content: <div className='w-full max-h-[136px] max-w-[156px] rounded-lg overflow-hidden relative'>
                                                    <button className='bg-primary1 absolute top-2 end-2 p-1 w-6 h-6 rounded-md flex justify-center items-center' type="button" id={"1_" + index}
                                                        onClick={() => handleRemoveImage(index)}><CloseIcon className="w-4 h-4 text-white"
                                                        /></button>
                                                    <img src={image?.file} className='h-full w-full object-cover' alt="" />
                                                </div>
                                            }
                                        ]} />
                                }) : <></>
                            }


                        </div>

                    </RowWrap>
                    <div className="flex items-center justify-end gap-4">
                        <ButtonPrimary size={"sm"} className={"w-full sm:max-w-[250px] font-bold rounded-[5px]"}  >save</ButtonPrimary>
                    </div>

                </form>
            </FormikProvider>
            <ChooseSpecialitiesModal
                modalOpen={specialitiesModalOpen}
                setModalOpen={setSpecialitiesModalOpen}
                setSelectedServices={setSpecialitiesList} selectedServices={specialitiesList} selectedIds={specialitiesIds} setSelectedIds={setSpecialitiesIds}
            />
        </Card>
    )
}

export default withTranslation()(ProtfolioOutsideMeeTime)