import React, { useState, Fragment, useRef, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import CheckboxList from '../../uiComponents/common/CheckboxList';
import ServiceTableWithSelect from '../../uiComponents/common/ServiceTableWithSelect';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import ChoosenServicesList from '../../uiComponents/common/ChoosenServicesList';
import InputCheckbox from '../../uiComponents/inputs/InputCheckbox';
import CommonSliderWrapper from '../../uiComponents/common/CommonSliderWrapper';
import InputRadioBoxGroup from '../../uiComponents/inputs/InputRadioBoxGroup';
import AvailabilityCalendar from '../../uiComponents/common/AvailabilityCalendar';
import OfferRequestCalender from '../../uiComponents/common/OfferRequestCalender';
import TimeSlider from '../../uiComponents/common/TimeSlider';
import RowWrap from '../../uiComponents/RowWrap';
import WorkerCard from '../../pages/customer_panel/components/WorkerCard';

const OfferRequestSecoundStep = ({ t, activeStep, setActiveStep, handleModalClose }) => {
    const sliderRef = useRef(null);
    const [selectedWorkerList, setSelectedWorkerList] = useState([])
    const [workerPrefrence, setWorkerPrefrence] = useState()



    const handleWorkerSelection = (option) => {
        if (selectedWorkerList?.includes(option)) {
            setSelectedWorkerList(selectedWorkerList?.filter(item => item !== option))
        } else {
            setSelectedWorkerList([...selectedWorkerList, option]);
        }
    };




    useEffect(() => {
        setSelectedWorkerList([])
    }, [workerPrefrence])
    return (

        <div className={`modal-body ${activeStep === 2 ? "block" : 'hidden'}`}>
            <ChoosenServicesList label={"Selected service(s)"} />

            <div className="meetime-form-group w-full my-3">
                <LabelWrap labelClassName=" w-full text-[15px] font-medium mb-1" label={t('comman_text.offer_request_step3')} />

                <RowWrap className="grid grid-cols-1  sm:grid-cols-7 gap-4 lg:gap-8 border-[1.3px] rounded-[15px] py-5" sm={2}>
                    <div className='col-span-1 sm:col-span-5'>
                        <OfferRequestCalender />
                    </div>
                    <div className='col-span-1 sm:col-span-2'>
                        <TimeSlider />
                    </div>
                </RowWrap>
            </div>

            <div className="meetime-form-group w-full ">
                <LabelWrap labelClassName=" w-full text-[15px] font-medium mb-0" label={t("form_input_label.worker_preference")} />
                <InputRadioBoxGroup
                    selectValue={workerPrefrence}
                    itemMainClassName={"w-full"}
                    itemClassName={"lg:text-[15px] text-[15px] justify-between  text-start w-full"}
                    setCheckValue={(value) => setWorkerPrefrence(value)}
                    data={[
                        { label: t("radio_label.salon_choose_best_suited") },
                        { label: t("radio_label.would_like_choose") },
                    ]}
                    groupClassName={"gap-x-10 gap-y-2 w-full flex flex-col md:flex-row  flex-wrap justfy-start  py-1.5"}
                />
            </div>



            {
                workerPrefrence === t("radio_label.would_like_choose") &&
                <div className='mt-3'>
                    <div className='w-full mb-6 xl:mb-7'>
                        <LabelWrap labelClassName={"text-[15px] mb-1 font-medium"} label={t('form_input_label.choose_worker')} />

                        <div className='w-full '>
                            <CommonSliderWrapper
                                ref={sliderRef}
                                defaultArrows={false}
                                sildNoShow={true}
                                nextArrowClassName="top-9 -end-20 border-[1.6px] !bg-primary1 !border-primary1 !text-white"
                                prevArrowClassName="!border-primary1 top-9 !start-auto !-end-10 border-[1.6px] !bg-primary1 !text-white"
                                groupClassName="sm:max-w-[calc(100%-110px)] sm:flex sm:gap-2 sm:items-center"
                                slidesToShow={5}
                                xsSlidesToShow={1}
                                smSlidesToShow={2}
                                mdSlidesToShow={3}
                                lgSlidesToShow={4}
                                arrowBtnGroupClassName={"mt-2 sm:mt-0 gap-2"}
                                countClassName="min-w-[28px] text-center"
                            >
                                {
                                    [...Array(8)].map((item, index) => (
                                        <InputCheckbox
                                            key={index}
                                            name="workerList"
                                            id={`worker_id_____${index + 1}`}
                                            groupClassName={"flex-grow w-full w-full h-full p-1"}
                                            className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                            text={
                                                <>
                                                    <div className='w-10 h-10 md:h-[55px] md:w-[55px] overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                                        <img src="../../../../../../assets/images/user4.jpg" className='h-full w-full object-cover' alt="" />
                                                    </div>
                                                    <span className="text-base xl:text-lg flex-grow text-center block break-words">Michael J.</span>
                                                </>
                                            }
                                            value={`worker_id_____${index + 1}`}
                                            checked={selectedWorkerList.includes(`worker_id_____${index + 1}`) ? true : false}
                                            onChange={(item) => handleWorkerSelection(item.target.value)}
                                        />
                                    ))
                                }
                            </CommonSliderWrapper>

                        </div>
                    </div>

                    <WorkerCard groupClassName={"bg-light-800"} badgeSlider/>
                </div>
            }
            <div className='w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3 mt-3'>
                <ButtonPrimary size="sm" className="w-full" onClick={handleModalClose} >Send Request</ButtonPrimary>
            </div>

        </div>
    )
}

export default withTranslation()(OfferRequestSecoundStep)
