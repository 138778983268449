function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const WORKER_AUTH = "/worker";
const PROVIDER_AUTH = "/provider";
const PROVIDER_BOTH_AUTH = "/both";
const SALON_AUTH_PROFILE = "/salon";
const CUSTOMER_AUTH = "/customer";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  mySearch: path(ROOTS_AUTH, "my-search"),
  findSalon: path(ROOTS_AUTH, "find-salon"),
  findSalonProfile: path(ROOTS_AUTH, "find-salon-profile"),
  shareSalonProfile: path(ROOTS_AUTH, "find-salon-profile/:salonId"),
  login: path(ROOTS_AUTH, "login"),
  register: path(ROOTS_AUTH, "register"),
  forgetPassword: path(ROOTS_AUTH, "forget-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  checkMailReset: path(ROOTS_AUTH, "check-mail"),
  congratulations: path(ROOTS_AUTH, "congratulations"),
  otpVerification: path(ROOTS_AUTH, "otp-verification"),
  prices: path(ROOTS_AUTH, "prices"),
  faq: path(ROOTS_AUTH, "faq"),
  applyForJob: path(ROOTS_AUTH, "apply-for-job"),
  registerProfile: path(ROOTS_AUTH, "register-profile"),
  createBusiness: path(ROOTS_AUTH, "create-business"),
  aboutUs: path(ROOTS_AUTH, "about-us"),
  contactUs: path(ROOTS_AUTH, "contact-us"),
  termAndCondiotion: path(ROOTS_AUTH, "/terms-of-use"),
  privacyPolicy: path(ROOTS_AUTH, "/privacy-policy"),

  editSalonProfile: path(ROOTS_AUTH, "/edit-salon-profile"),
};

export const PATH_WORKER = {
  dashboard: path(WORKER_AUTH, "/dashboard"),
  profile: path(WORKER_AUTH, "/profile"),
  messages: path(WORKER_AUTH, "/messages"),
  chatMessages: path(WORKER_AUTH, "/messages/:chatId"),
  badges: path(WORKER_AUTH, "/badges"),
  jobPosted: path(WORKER_AUTH, "/job-posted"),
  applyForJob: path(WORKER_AUTH, "/apply-for-job"),
  bookingCalendar: path(WORKER_AUTH, "/booking-calendar"),
  notification: path(WORKER_AUTH, "/notification"),
  contactSupport: path(WORKER_AUTH, "/contact-support"),
  TicketReplies: path(WORKER_AUTH, "/ticket-replies"),
  salonAndWorker: path(WORKER_AUTH, "/salon-and-worker"),
  addSalons: path(WORKER_AUTH, "/add-salon/:salonId"),
  changePassword: path(WORKER_AUTH, "/change-password"),
  salonProfile: path(WORKER_AUTH, "/salon-profile"),
  applyJobWorker: path(WORKER_AUTH, "/apply-job"),
  addEducation: path(WORKER_AUTH, '/education'),
  addPortfolio: path(WORKER_AUTH, '/portfolio')
}

export const PATH_PROVIDER = {
  dashboard: path(PROVIDER_AUTH, "/dashboard"),
  profile: path(PROVIDER_AUTH, "/profile"),
  salonProfile: path(PROVIDER_AUTH, "/salon-profile"),
  salonProfileTest: path(PROVIDER_AUTH, "/salon-profile"),
  becomeWorker: path(PROVIDER_AUTH, "/become-worker"),
  addService: path(SALON_AUTH_PROFILE, "/add-service"),
  editService: path(SALON_AUTH_PROFILE, "/edit-service"),
  service: path(SALON_AUTH_PROFILE, "/service"),
  request: path(SALON_AUTH_PROFILE, "/request"),
  workersEducation: path(SALON_AUTH_PROFILE, "/workers-education"),
  badges: path(SALON_AUTH_PROFILE, "/badges"),
  notification: path(WORKER_AUTH, "/notification"),
  addWorker: path(SALON_AUTH_PROFILE, "/add-worker"),
  addSalonWorker: path(SALON_AUTH_PROFILE, "/add-worker"),
  // addSalon: path(SALON_AUTH_PROFILE, "/add-salon"),
  // addSalons: path(SALON_AUTH_PROFILE, "/add-salon/:salonId"),
  addSalon: path(PROVIDER_AUTH, "/add-salon"),
  editSalon: path(SALON_AUTH_PROFILE, "/edit/:salonId"),
  editAvailablity: path(SALON_AUTH_PROFILE, "/edit-availability"),
  updateSalonWorker: path(SALON_AUTH_PROFILE, "/add-worker/:salonId"),
  salonAndWorker: path(PROVIDER_AUTH, "/salon-and-worker"),
  myCustomer: path(PROVIDER_AUTH, "/my-customer"),
  jobPosted: path(PROVIDER_AUTH, "/job-posted"),
  createJobPost: path(PROVIDER_AUTH, "/create-job-post"),
  notification: path(PROVIDER_AUTH, "/notification"),
  subscription: path(PROVIDER_AUTH, "/subscription"),
  contactSupport: path(PROVIDER_AUTH, "/contact-support"),
  messages: path(PROVIDER_AUTH, "/messages"),
  chatMessages: path(PROVIDER_AUTH, "/messages/:chatId"),
  bookingCalendar: path(PROVIDER_AUTH, "/booking-calendar"),
  addBooking: path(PROVIDER_AUTH, "/add-booking"),
  workerProfile: path(PROVIDER_AUTH, "/worker-profile"),
  TicketReplies: path(PROVIDER_AUTH, "/ticket-replies"),
  congratulations: path(SALON_AUTH_PROFILE, "/congratulations"),

}

export const PATH_CUSTOMER = {
  dashboard: path(CUSTOMER_AUTH, "/dashboard"),
  notification: path(CUSTOMER_AUTH, "/notification"),
  contactSupport: path(CUSTOMER_AUTH, "/contact-support"),
  newticket: path(CUSTOMER_AUTH, "/contact-support/new-ticket"),
  ticket: path(CUSTOMER_AUTH, "/contact-support/ticket"),
  TicketReplies: path(CUSTOMER_AUTH, "/ticket-replies"),
  profile: path(CUSTOMER_AUTH, "/profile"),
  findService: path(CUSTOMER_AUTH, "/find-service"),
  salonProfile: path(CUSTOMER_AUTH, "/salon-profile"),
  salonProfileView: path(CUSTOMER_AUTH, "/salon-details"),
  bookingProcess: path(CUSTOMER_AUTH, "/booking-process/:salonId"),
  favorites: path(CUSTOMER_AUTH, "/favorites"),
  myBooking: path(CUSTOMER_AUTH, "/my-booking"),
  paymentInfo: path(CUSTOMER_AUTH, "/payment-info"),
  yourOrder: path(CUSTOMER_AUTH, "/your-order"),
  customerRatingReview: path(CUSTOMER_AUTH, "/customer-rating-review"),
  messages: path(CUSTOMER_AUTH, "/messages"),
  chatMessages: path(CUSTOMER_AUTH, "/messages/:chatId"),

}
export const PATH_APPLYJOB = {
  applyJob: path(ROOTS_AUTH, "apply-job"),
  applyJobWorker: path(WORKER_AUTH, "/apply-job"),

}
export const PATH_BOTH = {
  bothProfile: path(PROVIDER_BOTH_AUTH, "/profile"),
  // applyJobWorker: path(WORKER_AUTH, "/apply-job"),

}