import React from 'react';
import Card from '../Card';
import { cn } from '../../../../lib/utils';

const Badge2 = ({ groupClassName, iconClassName, className, showTitle = true, data, contentClassName, ...props }) => {
    return (
        <div className={cn('border-[1.3px] border-success-100 rounded-[10px] p-5 py-1.5 h-full flex items-center gap-4', groupClassName)}>
            <div className={cn('icon flex-shrink-0', iconClassName)}>
                {data?.badge || data?.icon}
            </div>  
            <div className={cn("name text-center", contentClassName)}>
                {showTitle && <p className={cn(className)}>{data?.title}</p>}
                {/* {data.count && <p className={cn(className)}>{data?.count}</p>} */}
            </div>
        </div>
    );
}

export default Badge2;
