import React from 'react'
import Card from '../../../uiComponents/Card'
import { cn } from '../../../../../lib/utils'
import Rating from '../../../uiComponents/common/Rating'
import RatingList from '../../../uiComponents/common/RatingList'
import BadgeSlider from '../../../uiComponents/common/BadgeSlider'
import HairTypeList from '../../../uiComponents/common/HairTypeList'
import CustomTabs from '../../../uiComponents/tabs/CustomTabs'
import SalonDetails from '../../../uiComponents/common/SalonDetails'
import { PATH_AUTH } from '../../../../routes/path'
import { Navigate } from 'react-router-dom'

const SalonDetailsProfile = ({ badgeListData, tabsData,handelClickEvent,salonData }) => {
    return (
        <Card className={cn('sm:px-3 sm:pt-2.5 block sm:pb-3.5 md:hidden')}>
            <div className='flex flex-col gap-y-2'>
                <SalonDetails />
                <BadgeSlider btnClassName={"bg-white border-[1.5px] border-borderColor text-blue-950"} badgelist={badgeListData} badgeGroupClassName={"bg-light-800"} onClick={() => handelClickEvent === false ? "" : Navigate(PATH_AUTH.findSalonProfile, { state: { id:  salonData?.id, data: salonData } })}/>
                <CustomTabs
                    tabsData={tabsData}
                    tabGroupClass={cn("h-full col-span-8 xxl:col-span-9")}
                    tabListClass={cn("border border-success-950 text-success-900 mb-2 w-full mx-auto")}
                    tabButtonClass={cn("!p-1 text-[15px] font-medium")}
                    tabItemClass={cn("h-full")}
                    tabPanelsClass={cn("h-[calc(100%-35px)]")}
                />
                <div className={cn("hair-type-list h-full")}>
                    <div className={cn(`list-heading flex items-center justify-center px-2 py-1 border border-borderColor rounded-lg mb-2 bg-light-800`)}>
                        <h4 className={cn('leading-[1] text-success-900 font-medium text-base')}>Hair Types</h4>
                    </div>
                    <div className={`list-body border-[1.3px] px-4 border-success-100 rounded-[10px] h-[calc(100%-35px)] p-2 flex items-center justify-center bg-light-800`}>
                        <div className={" text-center text-sm text-blue-950"}>
                            <p>Afro - Curl - Thick - Curl - Thick - Curl - Thick - Afro - Curl - Thick - Curl - Thick - Curl - Thick</p>
                        </div>
                    </div>
                </div>
                <RatingList
                    textClassName={cn("gap-1.5")}
                    itemClassName={cn("gap-1.5 xl:gap-1.5")}
                    groupClassName={"bg-light-800 rounded-[14px] px-5"}
                />
            </div>
        </Card >
    )
}

export default SalonDetailsProfile