import React, { useState } from 'react'
import { cn } from '../../../../lib/utils'
import { withTranslation } from 'react-i18next'
import LabelWrap from '../inputs/LabelWrap'
import CloseFillIcon from '../icons/CloseFillIcon'
import CommonSliderWrapper from './CommonSliderWrapper'

const ChoosenServicesList = ({ t, wrapperClassName, groupClassName, label, showPrice = true, cancleIconShow = true, ...props }) => {

    const [selectedServicesList, setSelectedServicesList] = useState([
        {
            id: 1,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        {
            id: 2,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        {
            id: 3,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        {
            id: 4,
            category: "haircut",
            gender: "Woman",
            duration: "40min",
            price: "430 Dkk."
        },
        // {
        //     id: 5,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // },
        // {
        //     id: 6,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // },
        // {
        //     id: 7,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // },
        // {
        //     id: 8,
        //     category: "haircut",
        //     gender: "Woman",
        //     duration: "40min",
        //     price: "430 Dkk."
        // }
    ])

    const handeRemoveService = (id) => {
        setSelectedServicesList(selectedServicesList.filter(item => item.id !== id))
    }

    const sliderRef = React.useRef(null)


    return (
        <div className={cn("w-full", wrapperClassName)}>
            <LabelWrap labelClassName={"font-medium mb-1"} label={label} />
            {/* <div className={cn("w-full grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3", groupClassName)}>
                {
                    selectedServicesList.map((service, index) => (
                        <ul className='border-[1.5px] text-[15px] flex flex-col gap-1 border-success-100 rounded-[15px] bg-light-950 relative w-full text-blue-950 p-3'>
                            <CloseFillIcon className="h-6 w-6 absolute end-1 top-1 cursor-pointer" onClick={() => handeRemoveService(service.id)} />
                            <li>
                                HairCut {service.id}
                            </li>
                            <li>
                                Woman
                            </li>
                            <li>
                                70 min
                            </li>
                            <li>
                                430 Dkk.
                            </li>
                        </ul>
                    ))
                }
            </div> */}

            <CommonSliderWrapper
                centerMode={false}
                ref={sliderRef}
                groupClassName="max-w-full flex sm:gap-2 items-start w-full"
                slidesToShow={5}
                xsSlidesToShow={1}
                smSlidesToShow={2}
                mdSlidesToShow={3}
                lgSlidesToShow={4}
                infinite={false}
                prevArrowClassName={"absolute top-1/2 z-10 -translate-y-1/2 -start-2 rounded-full bg-primary1 text-white"}
                nextArrowClassName={"absolute top-1/2 -translate-y-1/2 -end-2 rounded-full bg-primary1 text-white"}
                arrowBtnGroupClassName={"mt-2 gap-2"}
                SliderClassName={"slider_flex gap_14 h-full [&_.slick-track]:mx-0"}
            >
                {
                    selectedServicesList.map((service, index) => (
                        <ul className='border-[1.5px] text-[15px] flex flex-col gap-1 border-success-100 rounded-[15px] bg-light-950 relative w-full text-blue-950 p-3'>
                            {cancleIconShow && <CloseFillIcon className="h-6 w-6 absolute end-1 top-1 cursor-pointer" onClick={() => handeRemoveService(service.id)} />}
                            <li>
                                HairCut {service.id}
                            </li>
                            <li>
                                Woman
                            </li>
                            <li>
                                70 min
                            </li>
                            {showPrice &&
                                <li>
                                    430 Dkk.
                                </li>}
                        </ul>
                    ))
                }
            </CommonSliderWrapper>
        </div >
    )
}

export default withTranslation()(ChoosenServicesList)