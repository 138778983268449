import React from 'react'
import { withTranslation } from 'react-i18next'
import { useState } from 'react'
import InputCheckbox from '../inputs/InputCheckbox'
import MaleIcon from '../icons/MaleIcon'
import FeMaleIcon from '../icons/FeMaleIcon'
import BabyBoyIcon from '../icons/BabyBoyIcon'
import BabyGirlIcon from '../icons/BabyGirlIcon'
import Table from '../table/Table'
import THead from '../table/THead'
import TR from '../table/TR'
import TH from '../table/TH'
import TBody from '../table/TBody'
import TD from '../table/TD'

const ServiceTableWithSelect = ({ t, ...props }) => {

    const [selectedRows, setSelectedRows] = useState([]);


    const handleRowSelect = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };

    return (
        <div className="category-table-wrapper border-[1.3px] bg-light-800 border-success-100 rounded-xl p-3 pt-0 mb-5">
            <div className="overflow-auto">
                <Table className={"text-[15px] text-blue-950 w-full border-separate border-spacing-y-1.5"}>
                    <THead>
                        <TR>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 text-start !ps-16"}>Service</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>Duration</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5"}>For</TH>
                            <TH className={"font-medium border-b-0 py-0 px-3 md:px-5 flex flex-col"}>Price <span className='text-[13px]'> Standard/Student/Senio</span></TH>
                        </TR>
                    </THead>
                    <TBody className={""}>
                        <TR className={"rounded-lg mb-5 overflow-hidden"}>
                            <TD className={"px-5 py-2 border-b-0 font-semibold leading-[20px] border  rounded-md text-success-900  bg-success-150 text-center"}
                                colSpan={7}>Haircut</TD>
                        </TR>
                        {
                            [...Array(2)].map((item, ind) => (
                                <TR className={"[&_.td-item]:last:border-1  [&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={ind}>
                                    <TD className={"px-1 py-1 ps-5 border-b-0 border border-e-0 rounded-tl-md rounded-bl-md xl:min-w-[300px] flex gap-3  justify-start items-center"}>
                                        <InputCheckbox
                                            size={"xl"}
                                            className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                                            value={`data_id_${ind + 1}`}
                                            checked={selectedRows.includes(`data_id_${ind + 1}`)}
                                            onChange={() => handleRowSelect(`data_id_${ind + 1}`)}
                                        />
                                        <p className="text-blue-950 font-medium">Short hair</p>
                                    </TD>
                                    <TD className={"text-center border-b-0 border-y border-success-100 px-1 py-1 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">40 min.</p></TD>
                                    <TD className={"px-1 py-1 border-b-0 border-y border-success-100 w-[100px] xl:w-[unset]"}>
                                        <ul className="flex items-end text-center justify-center gap-1">
                                            <li className="flex-shrink-0 leading-none"><MaleIcon className="h-5" /></li>
                                            <li className="flex-shrink-0 leading-none"><FeMaleIcon className="h-5" /></li>
                                            <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="h-3.5" /></li>
                                            <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="h-3.5" /></li>
                                        </ul>
                                    </TD>
                                    <TD className={"px-1 border-b-0 border-y rounded-tr-md rounded-br-md border-e border-success-100  py-1 w-[220px] xl:w-[unset]"}>
                                        <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                                            <li className="w-1/3 text-danger-700">295 Dkk.</li>
                                            <li>/</li>
                                            <li className="w-1/3">150 Dkk.</li>
                                            <li>/</li>
                                            <li className="w-1/3">295 Dkk.</li>
                                        </ul>
                                    </TD>
                                </TR>
                            ))
                        }
                        <TR className={"rounded-lg mb-5 overflow-hidden"}>
                            <TD className={"px-5 py-2 border-b-0 font-semibold leading-[20px] border  rounded-md text-success-900  bg-success-150 text-center"}
                                colSpan={7}>Care Treatment</TD>
                        </TR>
                        {
                            [...Array(5)].map((item, ind) => (
                                <TR className={"[&_.td-item]:last:border-1  [&>td:not(:last-child)]:before:w-[1px] [&>td:not(:last-child)]:before:h-[calc(100%-8px)] [&>td:not(:last-child)]:before:bg-success-100 [&>td:not(:last-child)]:before:block [&>td:not(:last-child)]:before:absolute [&>td:not(:last-child)]:before:end-0 [&>td:not(:last-child)]:before:top-1 [&>td:not(:last-child)]:relative"} key={ind}>
                                    <TD className={"px-1 py-1 ps-5 border-b-0 border border-e-0 rounded-tl-md rounded-bl-md xl:min-w-[300px] flex gap-3  justify-start items-center"}>
                                        <InputCheckbox
                                            size={"xl"}
                                            className={"before:rounded-full peer-checked:before:!bg-success-800 peer-checked:before:!border-success-800"}
                                            value={`data_id_${ind + 20}`}
                                            checked={selectedRows.includes(`data_id_${ind + 20}`)}
                                            onChange={() => handleRowSelect(`data_id_${ind + 20}`)}
                                        />
                                        <p className="text-blue-950 font-medium">Short hair</p>
                                    </TD>
                                    <TD className={"text-center border-b-0 border-y border-success-100 px-1 py-1 w-[100px] xl:w-[unset]"}><p className="text-blue-950 text-sm font-medium">40 min.</p></TD>
                                    <TD className={"px-1 py-1 border-b-0 border-y border-success-100 w-[100px] xl:w-[unset]"}>
                                        <ul className="flex items-end text-center justify-center gap-1">
                                            <li className="flex-shrink-0 leading-none"><MaleIcon className="h-5" /></li>
                                            <li className="flex-shrink-0 leading-none"><FeMaleIcon className="h-5" /></li>
                                            <li className="flex-shrink-0 leading-none"><BabyBoyIcon className="h-3.5" /></li>
                                            <li className="flex-shrink-0 leading-none"><BabyGirlIcon className="h-3.5" /></li>
                                        </ul>
                                    </TD>
                                    <TD className={"px-1 border-b-0 border-y rounded-tr-md rounded-br-md border-e border-success-100  py-1 w-[220px] xl:w-[unset]"}>
                                        <ul className="flex items-center gap-1 text-center text-blue-950 font-normal">
                                            <li className="w-1/3 text-danger-700">295 Dkk.</li>
                                            <li>/</li>
                                            <li className="w-1/3">150 Dkk.</li>
                                            <li>/</li>
                                            <li className="w-1/3">295 Dkk.</li>
                                        </ul>
                                    </TD>
                                </TR>
                            ))
                        }
                    </TBody>
                </Table>
            </div>
        </div>
    )
}

export default withTranslation()(ServiceTableWithSelect)