import React from 'react';
import { cn } from '../../../../lib/utils';
import CircleUser from '../icons/line-icon/CircleUser';
import EducationIcon from '../icons/line-icon/EducationIcon';
import HandIcon from '../icons/line-icon/HandIcon';
import HomeIcon from '../icons/line-icon/HomeIcon';
import PriceIcon from '../icons/line-icon/PriceIcon';
import LocationPin4 from '../icons/LocationPin4';
import ServiceFacility from './ServiceFacility';
import { useNavigate } from 'react-router-dom'
import { PATH_AUTH } from '../../../routes/path';

const SalonDetails = ({ colored, className, iconClass, phoneIcon2, salonData, ServiceFacilityClassName, data, navigateUrl, handelClickEvent, ...props }) => {
    const navigate = useNavigate();
    const handleRange = (range) => {
        let htm = ""
        if (range == 1) {
            htm += "Low - "
        }
        if (range == 2) {
            htm += "Medium - "
        }
        if (range == 3) {
            htm += "High - "
        }
        return htm
    }

    const handleAverageServicePrice = (price) => {
        if (price === 1) {
            return <span>$<span className="gray">$$$</span></span>
        } else if (price === 2) {
            return <span>$$<span className="gray">$$</span></span>
        } else if (price === 3) {
            return <span>$$$<span className="gray">$</span></span>
        } else if (price === 4) {
            return <span>$$$$</span>
        }
    }

    return (
        <div className={cn(`p-2.5 border-[1.3px] border-success-100 rounded-[10px] relative h-full ${colored && "bg-light-800"}`, className, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(PATH_AUTH.findSalonProfile, { state: { id: data?.salon_id || data?.id || salonData.id, data: salonData ||data } })}>
            <div className='flex-col justify-between gap-2.5 h-full grid md:grid-cols-1 sm:grid-cols-2 grid-cols-1'>
                <div className='text-sm font-normal text-blue-950 flex items-center gap-2'><CircleUser className={cn('h-5 w-5', iconClass)} stroke_width='2px' />{salonData?.business_type}</div>
                <div className='text-sm font-normal text-blue-950 flex items-center gap-2'><HomeIcon className={cn('h-5 w-5', iconClass)} />{salonData?.address ?? ""}</div>
                <div className='text-sm font-normal text-blue-950 flex items-center gap-2'><LocationPin4 className={cn('h-5 w-5', iconClass)} />{salonData?.service_location === 3 ? 'Salon/On The Go' : salonData?.service_location === 2 ? 'On The Go' : salonData?.service_location === 1 ? 'Salon' : ''}</div>
                <div className='text-sm font-normal text-blue-950 flex items-center gap-2'><EducationIcon className={cn('h-5 w-5', iconClass)} /> {salonData?.education_range?.length > 0 ? salonData?.education_range?.map((range) => (handleRange(range))) : 'No '}{"Education"}</div> {/* for medium - high education */}
                <div className='text-sm font-normal text-blue-950 flex items-center gap-2'><HandIcon className={cn('h-5 w-5', iconClass)} />{salonData?.customer_served ?? ""} Customers</div>
                <div className='text-sm font-normal text-blue-950 flex items-center gap-2'><PriceIcon className={cn('h-5 w-5', iconClass)} stroke_width='3px' />{handleAverageServicePrice(salonData?.average_service_price || salonData?.salon?.average_service_price)} </div>
            </div>
            <ServiceFacility salonData={salonData} className={cn('absolute top-1.5 end-1.5 md:flex hidden', ServiceFacilityClassName)} phoneIcon2={phoneIcon2} />
        </div>
    );
}

export default SalonDetails;
