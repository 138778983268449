import React, { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonPrimary from '../uiComponents/buttons/ButtonPrimary';
import CloseIcon from '../uiComponents/icons/CloseIcon';
import { withTranslation } from 'react-i18next'
import LabelWrap from '../uiComponents/inputs/LabelWrap';
import BarberIcon from '../uiComponents/icons/service-icon/BarberIcon';
import BodyTreatmentIcon from '../uiComponents/icons/service-icon/BodyTreatmentIcon';
import FacialTreatmentIcon from '../uiComponents/icons/service-icon/FacialTreatmentIcon';
import HairdresserIcon from '../uiComponents/icons/service-icon/HairdresserIcon';
import CustomSlider from '../uiComponents/CustomSlider';
import CommonSliderWrapper from '../uiComponents/common/CommonSliderWrapper';
import InputCheckbox from '../uiComponents/inputs/InputCheckbox';
import InfoFIllIcon from '../uiComponents/icons/InfoFIllIcon';
import LocationPin3 from '../uiComponents/icons/LocationPin3';
import RowWrap from '../uiComponents/RowWrap';
import ShopIcon from '../uiComponents/icons/ShopIcon';
import HairRemovalIcon from '../uiComponents/icons/service-icon/HairRemovalIcon';
import HairstylistIcon from '../uiComponents/icons/service-icon/HairstylistIcon';
import LashesBrowsIcon from '../uiComponents/icons/service-icon/LashesBrowsIcon';
import MakeUpIcon from '../uiComponents/icons/service-icon/MakeUpIcon';
import ManicurePedicureIcon from '../uiComponents/icons/service-icon/ManicurePedicureIcon';
import MasseuseIcon from '../uiComponents/icons/service-icon/MasseuseIcon';
import PhysiotherapistIcon from '../uiComponents/icons/service-icon/PhysiotherapistIcon';
import SpaWellnessIcon from '../uiComponents/icons/service-icon/SpaWellnessIcon';
import CategorySelectDropdown from '../uiComponents/inputs/CategorySelectDropdown';
import InputCheckBoxGroup from '../uiComponents/inputs/InputCheckBoxGroup';
import CountHandler from '../uiComponents/common/CountHandler';
import IconShop2 from '../uiComponents/icons/IconShop2';
import avtarUser from '../../../assets/images/user-image/avtar-user.png';
import Http from '../../../Http';
import { CUSTOMER_AUTH_API_URL, FRONT_API_URL, PROFILE_COMPLETE_URL } from '../../api/axios';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { authStore } from '../../contexts/AuthProvider';



// Filter for Salon profile (Service page) 




const FindSalonProfileFilterModal = ({ t, selectCategoryTpes, setCategorytype, modalOpen, servicesIds, setServicesIds, salonData, onApplyFilters, setModalOpen, ...props }) => {
    const [categorySelectItems, setCategorySelectItems] = useState([])
    const sliderRef = useRef(null);
    const [selectedWorkerList, setSelectedWorkerList] = useState([])

    const [genderSelect, setGenderSelect] = useState([])
    const [count, setCount] = useState(0)
    const [selectedRating, setSelectedRating] = useState([])
    const [locationSelect, setLocationSelect] = useState([]);
    const isAuthenticated = authStore((state) => state.isAuthenticated)
    const handleWorkerSelection = (workerId) => {
        if (selectedWorkerList.includes(workerId)) {
            setSelectedWorkerList(selectedWorkerList.filter(id => id !== workerId));
        } else {
            setSelectedWorkerList([...selectedWorkerList, workerId]);
        }
    };
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');



    // const serviceCategory = salonData?.data?.services?.map(category => {
    //     const categoryName = category?.service_type?.name; // Assigning name to category.name
    //     const categories = [{
    //         id: category.id,
    //         label: category?.category.name
    //     }];
    //     // return { category.name: categoryName, services: categoryServices };;
    //     return { name: categoryName, categories: categories };
    // });

    const { data: serviceCategory } = useQuery(
        ['serviceCategoryRefetch'],
        async () => {
            return await Http.get(isAuthenticated ? PROFILE_COMPLETE_URL.servicetypeCategories : FRONT_API_URL.servicetypeCategories).
                then((res) => res.data.data);

        },
        { refetchOnWindowFocus: false }
    );

    const handleSelectServiceIds = (ind) => {
        console.log({ind});
        if (!servicesIds?.includes(ind)) {
            const uniqueServiceIds = [...servicesIds, ind];
            setServicesIds(uniqueServiceIds);
        }
    }

    console.log({ servicesIds });
    const handleSelectCategoryIds = (categoryId) => {
        if (selectCategoryTpes.includes(categoryId)) {
            setCategorytype(selectCategoryTpes.filter(id => id !== categoryId));
        } else {
            setCategorytype([...selectCategoryTpes, categoryId]);
        }
    };

    const gender = [
        { label: t('comman_text.men'), value: "1" },
        { label: t('comman_text.woman'), value: "2" },
        { label: t('comman_text.boy'), value: "4" },
        { label: t('comman_text.girl'), value: "3" },
    ]

    const handleLocationChange = (value) => {
        if (locationSelect.includes(value)) {
            setLocationSelect(locationSelect.filter(item => item !== value));
        } else {
            setLocationSelect([...locationSelect, value]);
        }
    };

    const handleCheckboxFilter = async () => {
        const filterData = {
            category_ids: selectCategoryTpes,
            gender: genderSelect,
            location: locationSelect,
            worker_id: selectedWorkerList
            // sort_field: sortBy,
        }
        // setLoading(true)
        // await handleFilerData(filterData)
        await onApplyFilters(filterData);
        setModalOpen(false);
    };

    return (
        <Transition appear show={modalOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[999]" onClose={() => setModalOpen(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-dark-950 bg-opacity-70" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel className="w-full max-w-[864px] transform rounded-[10px] bg-white lg:p-6 p-4 text-start shadow-110 transition-all">
                                <div className="modal-header pe-7 mb-6">
                                    <h2 className="font-semibold text-xl text-center text-blue-950">Filter</h2>
                                    <button
                                        type={"button"}
                                        aria-label="button"
                                        className="inline-block absolute right-3 top-3 ms-auto outline-[0px]"
                                        onClick={() => setModalOpen(false)}>
                                        <CloseIcon className="w-5 h-5 text-blue-950/50" />
                                    </button>
                                </div>
                                <div className="modal-body flex flex-col justify-center items-center">
                                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-10 gap-5 w-full justify-start items-start mb-3'>
                                        <div className="meetime-form-group  lg:col-span-5 ">
                                            <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={"Customer Serviced"} />
                                            <InputCheckBoxGroup
                                                selectValue={genderSelect}
                                                itemMainClassName={"w-full"}
                                                itemClassName={"text-[13px] lg:text-[15px] text-[15px] justify-between  text-start w-full"}
                                                setCheckValue={(genderList) => {
                                                    setGenderSelect(genderList);
                                                }}
                                                data={gender}
                                                groupClassName={"gap-2 md:gap-3 flex flex-wrap  justfy-start xsm:justify-between border border-success-100 rounded-md py-1.5 px-2.5"}
                                            />
                                        </div>
                                        <div className="meetime-form-group lg:col-span-5 ">
                                            <LabelWrap labelClassName="mb-1.5 w-full text-[15px] font-medium justify-start"
                                                label={t('form_input_label.service_location')}
                                                tooltipText={"Service your customers at the Salon"}
                                            // lableEndIcon={<InfoFIllIcon className="w-5 h-5" />}
                                            />
                                            <RowWrap className="grid xsm:grid-cols-2 w-full md:w-fit sm:gap-x-6 md:gap-x-8 gap-y-2 border border-success-100 rounded-md py-1.5 px-2.5" sm={2}>
                                                <InputCheckbox name="location"
                                                    id={"t('check_box_label.salon')"}
                                                    prefixIcon={<IconShop2 className="w-5 h-5" />}
                                                    text={t('check_box_label.salon')}
                                                    className={"before:rounded-full peer-checked:before:bg-success-800"}
                                                    checked={locationSelect.includes("1")}
                                                    onChange={() => handleLocationChange("1")}
                                                />
                                                <InputCheckbox name="location"
                                                    id={t('check_box_label.on_the_go')}
                                                    prefixIcon={<LocationPin3 className="w-5 h-5" />}
                                                    text={t('check_box_label.on_the_go')}
                                                    checked={locationSelect.includes("2")}
                                                    onChange={() => handleLocationChange("2")}
                                                    className={"before:rounded-full peer-checked:before:bg-success-800"}
                                                />
                                            </RowWrap>
                                        </div>



                                        {/* -----------minimum rating--------------- */}
                                        <div className="meetime-form-group md:col-span-2  lg:col-span-10 w-full ">
                                            <LabelWrap labelClassName=" w-full text-[15px] font-medium" label={t('form_input_label.minimum_rating')} />
                                            <RowWrap className="flex flex-col md:flex-row gap-y-2.5 border border-success-100 rounded-md py-1.5 px-2.5 gap-x-3 sm:gap-x-5 md:gap-x-6 " md={2}>
                                                <div className="meetime-form-group  px-2.5">
                                                    <CountHandler setCount={setCount} count={count} star={true} showMaxValue={true} maxValue={5} />
                                                </div>
                                                <div className="meetime-form-group flex-grow w-full">
                                                    <InputCheckBoxGroup
                                                        selectValue={selectedRating}
                                                        itemClassName={"justify-between  text-start"}
                                                        setCheckValue={(ratingList) => setSelectedRating(ratingList)}
                                                        data={[
                                                            { label: "Overall" },
                                                            { label: t('check_box_label.service') },
                                                            { label: t('check_box_label.quality_price') },
                                                            { label: t('check_box_label.salon') },
                                                            { label: t('check_box_label.atmosphere') },
                                                            { label: t('check_box_label.worker') },
                                                        ]}
                                                        groupClassName={"gap-3 w-full grid grid-cols-2 xsm:grid-cols-3 sm:flex justify-between px-2.5"}
                                                    />
                                                </div>

                                            </RowWrap>
                                        </div>
                                    </div>


                                    {/* --------------------------------------worker slider------------------------ */}
                                    <div className='w-full mb-3'>
                                        <LabelWrap labelClassName={"text-[15px] mb-1 font-medium"} label={"Worker"} />

                                        <div className='w-full '>
                                            <CommonSliderWrapper
                                                ref={sliderRef}
                                                defaultArrows={false}
                                                sildNoShow={true}
                                                // nextArrowClassName="top-9 -end-20 border-[1.6px] !bg-primary1 !border-primary1 !text-white"
                                                // prevArrowClassName="!border-primary1 top-9 !start-auto !-end-10 border-[1.6px] !bg-primary1 !text-white"
                                                groupClassName="sm:max-w-[calc(100%-60px)] flex flex-col justify-center sm:justify-start items-center  sm:flex-row sm:gap-2 sm:items-center"
                                                slidesToShow={4}
                                                xsSlidesToShow={1}
                                                smSlidesToShow={2}
                                                mdSlidesToShow={3}
                                                lgSlidesToShow={salonData?.data?.workers?.length >= 4 ? 4 : salonData?.data?.workers?.length}
                                                // salonData = {salonData}
                                                arrowBtnGroupClassName={"mt-2 sm:mt-0 gap-2 grid grid-cols-2 gap-0 gap-x-3 w-[60px] sm:w-auto"}
                                                countClassName="col-span-2 text-center order-1"
                                                arrowButton={"order-2 h-[30px] w-[30px]"}
                                            >
                                                {
                                                    salonData?.data?.workers?.map((item, index) => (
                                                        <InputCheckbox
                                                            key={item?.provider?.id}
                                                            name="workerList"
                                                            id={`worker_id_${item?.provider?.id}`}
                                                            groupClassName={"flex-grow w-full w-full h-full p-1"}
                                                            className={`bg-light-800 rounded-lg xl:rounded-2xl items-center gap-1 before:hidden after:hidden peer-checked:after:hidden py-1 md:py-1.5 px-2 md:px-2.5 border-[1.3px] border-success-100 w-full font-medium peer-checked:border-success-950 peer-checked:bg-success-350 hover:border-success-950 hover:bg-success-350 transition-all duration-300 h-full leading-none`}
                                                            text={
                                                                <>
                                                                    <div className='w-[45px] h-[45px] sm:h-[55px] sm:w-[55px] overflow-hidden rounded-full object-cover object-center bg-white flex-shrink-0'>
                                                                        <img src={item?.provider?.profile || avtarUser} className='h-full w-full object-cover' alt="" />
                                                                    </div>
                                                                    <span className="text-[15px] flex-grow text-center block break-words">{item?.provider?.first_name} {item?.provider?.last_name}</span>
                                                                </>
                                                            }
                                                            value={`worker_id_${item?.provider?.id}`}
                                                            checked={selectedWorkerList.includes(item?.provider?.id)}
                                                            onChange={() => handleWorkerSelection(item?.provider?.id)}
                                                        />
                                                    ))
                                                }
                                            </CommonSliderWrapper>

                                        </div>
                                    </div>


                                    {/* -------------------------------Service(s)-------------------------------------- */}
                                    <div className="gap-5 w-full">
                                        <LabelWrap labelClassName="mb-2 w-full text-[15px] font-medium" label={"Service(s)"} />
                                        <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-5">
                                            {serviceCategory?.map((category, index) => (
                                                <CategorySelectDropdown
                                                    key={index}
                                                    selcetAll={true}
                                                    categorySelectItems={categorySelectItems}
                                                    handleSelectChange={() => handleSelectServiceIds(category?.categories.map(item => item.id))}
                                                    children={category.categories.map(item => ({ id: item.id, label: item.name, checked: servicesIds?.includes(item.id) }))}
                                                    prefixIcon={category.icon}
                                                    buttonText={language === "en" ? category.name : category.dk_name}
                                                />
                                            ))}
                                        </RowWrap>
                                    </div>

                                    <ButtonPrimary size="sm" className="w-full mt-5" onClick={handleCheckboxFilter}>Apply</ButtonPrimary>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default withTranslation()(FindSalonProfileFilterModal)