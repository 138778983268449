import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import InputCheckbox from "../inputs/InputCheckbox";
import { cn } from "../../../../lib/utils";
import _ from "lodash";

const CheckboxList = ({
  selcetAll = true,
  selectAllId,
  // setServiceChoose,
  serviceType,
  groupClassName,
  inputGroupClassName,
  inputLableClassName,
  subCategoryData,
  salonService,
  categoryChoose,
  setCategoryChoose,
  serviceChoose,
  ...props
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkAll, setCheckAll] = useState(false);  
  
  const handelChecboxOnChange = (event) => {
    let list = checkedList; 
       
    if (event.target.checked) {
      list.push(parseInt(event.target.value));
    } else {
      let index = list.indexOf(parseInt(event.target.value));
      if (index !== -1) {
        list.splice(index, 1);
        setCheckedList(list);
      }
    }

    if (serviceType?.length === checkedList.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }

    if (list.length == 0) {
      setCategoryChoose([]);
    }
    setCheckedList(list);
    salonService({
      category_ids: list,
      service_type_ids: serviceChoose,
    });
  };

  // const handelAllChecboxOnChange = (e) => {
  //   const allCheck = _.map(serviceType, "id");
  //   if (allCheck.length == 0) {
  //     setCategoryChoose([]);
  //   }
  //   if (!e?.target.checked && e?.target.checked !== undefined) {
  //     setCheckAll(false);
  //     subCategoryData([allCheck[0]]);
  //     setCheckedList([allCheck[0]]);
  //     // setServiceChoose([allCheck[0]]);
  //     salonService({
  //       category_ids: allCheck.length == 0 ? [] : categoryChoose,
  //       service_type_ids: [allCheck[0]],
  //     });
  //   } else {
  //     setCheckAll(true);
  //     setCheckedList(allCheck);
  //     // setServiceChoose(allCheck);
  //     subCategoryData(allCheck);
  //     salonService({
  //       category_ids: allCheck.length == 0 ? [] : categoryChoose,
  //       service_type_ids: allCheck,
  //     });
  //   }
  // };

  const handelAllChecboxOnChange = (e) => {
    const allCheck = _.map(serviceType, "id");
    if (allCheck.length == 0) {
      setCategoryChoose([])
    }
    if (!e?.target.checked && e?.target.checked !== undefined) {
      setCheckAll(false);
      setCheckedList([allCheck[0]]);
      salonService({
        category_ids: allCheck.length == 0 ? [] : categoryChoose,
        service_type_ids: [allCheck[0]]
      })
    } else {
      setCheckAll(true);
      setCheckedList(allCheck);
      salonService({
        category_ids: allCheck.length == 0 ? [] : categoryChoose,
        service_type_ids: serviceChoose
      })
    }
  };

  useEffect(() => {
    setCheckAll(false);
    const allCheck = _.map(serviceType, "id");
    if(allCheck.length){
      setCheckedList([allCheck[0]]);
    } else{
      setCheckedList([]);
    }
  }, [serviceType]);

  return (
    <div
      className={cn(
        "flex items-center justify-start gap-2 flex-wrap ",
        groupClassName
      )}
      {...props}
    >
      <InputCheckbox
        className={cn(
          "before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full",
          inputLableClassName
        )}
        // className={checkAll ? "all_select_checkbox" : "all_select_unchecked"}
        checked={checkAll}
        id={selectAllId}
        text={"All"}
        onChange={handelAllChecboxOnChange}
        groupClassName={inputGroupClassName}
      />
      <div
        className="flex items-center justify-start gap-2 flex-wrap"
        // value={checkedList}
        value={categoryChoose}
        onChange={handelChecboxOnChange}
      >
        {subCategoryData && subCategoryData.length > 0
          ? subCategoryData.map((data, ind) => {
              return (
                <InputCheckbox
                  id={data?.id + "_" + ind}
                  value={data?.id}
                  key={ind}
                  text={data?.name}
                  groupClassName={inputGroupClassName}
                  // checked={checkedList?.includes(data?.id)}
                  className={cn(
                    "before:hidden after:hidden border-[1.3px] text-[15px] peer-checked:text-white peer-checked:border-success-950 peer-checked:bg-success-950 border-success-100 py-0.5 px-4 rounded-full",
                    inputLableClassName
                  )}
                />
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default withTranslation()(CheckboxList);
