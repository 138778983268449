import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import FindSalonProfileFilterModal from '../../../modals/FindSalonProfileFilterModal'
import SortByModal from '../../../modals/SortByModal'
import Card from '../../../uiComponents/Card'
import ButtonPrimary from '../../../uiComponents/buttons/ButtonPrimary'
import FilterIcon from '../../../uiComponents/icons/FilterIcon'
import SortIcon from '../../../uiComponents/icons/SortIcon'
import WorkerCard from "../../customer_panel/components/WorkerCard"

const SalonDetailsWorkers = ({ t,workerData,salonData }) => {
    const [FindSalonProfileFilterModalOpen, setFindSalonProfileFilterModalOpen] = useState(false)
    const [SortByModalOpen, setSortByModalOpen] = useState(false)
    const [selectedSortBy, setselectedSortBy] = useState([])

    const SortByOption = [
        {
            id: 'latest',
            label: 'Latest'
        },
        {
            id: 'oldest',
            label: 'Oldest'
        },
        {
            id: "review_asc",
            label: t("comman_text.review_asc"),
        },
        {
            id: "review_desc",
            label: t("comman_text.review_des"),
        },
        {
            id: "customer_served_asc",
            label: t("comman_text.customer_asc"),
        },
        {
            id: "customer_served_desc",
            label: t("comman_text.customer_desc"),
        },
    ]


    return (
        <div>
            <Card className={"mb-2.5 md:mb-4 sm:px-2.5 sm:py-[6.5px] py-[6.5px] px-2.5"}>
                <div className="flex items-center justify-between">
                    <h6 className='text-sm text-blue-950 leading-[1]'>{salonData ? salonData?.data?.workers?.length : workerData?.workers?.length}  Workers </h6>
                    <div className="items-center gap-2 md:flex hidden">
                        <ButtonPrimary size={"sm"} className={"py-1 px-3 rounded-md"} onClick={() => setFindSalonProfileFilterModalOpen(true)}>
                            <span><FilterIcon className="w-5 h-5" /></span>
                            Filter
                        </ButtonPrimary>
                        <ButtonPrimary size={"sm"} className={"py-1 px-3 rounded-md"} onClick={() => setSortByModalOpen(true)}>
                            <span><SortIcon className="w-5 h-5" /></span>
                            Sort By
                        </ButtonPrimary>
                    </div>
                    <div className="items-center gap-2 md:hidden flex">
                        <ButtonPrimary size={"sm"} className={"!p-0 w-[41px] h-[37px] rounded-md"} onClick={() => setFindSalonProfileFilterModalOpen(true)}>
                            <span><FilterIcon className="w-5 h-5" /></span>
                        </ButtonPrimary>
                        <ButtonPrimary size={"sm"} className={"!p-0 w-[41px] h-[37px] rounded-md"} onClick={() => setSortByModalOpen(true)}>
                            <span><SortIcon className="w-5 h-5" /></span>
                        </ButtonPrimary>
                    </div>
                </div>
            </Card>
            
            {workerData?.workers?.map((worker) => (
                <WorkerCard
                    key={worker.id}
                    groupClassName={"mt-2.5 md:mt-4"}
                    badgeSlider={true}
                    badgeTextClass={"text-dark-950 text-sm"}
                    colored
                    workerData={worker}
                />
            ))}
            {/* <WorkerCard groupClassName={"mt-2.5 md:mt-4"} badgeSlider={true} badgeTextClass={"text-dark-950 text-sm"} colored />
            <WorkerCard groupClassName={"mt-2.5 md:mt-4"} badgeSlider={true} badgeTextClass={"text-dark-950 text-sm"} colored />
            <WorkerCard groupClassName={"mt-2.5 md:mt-4"} badgeSlider={true} badgeTextClass={"text-dark-950 text-sm"} colored /> */}



            <FindSalonProfileFilterModal
                modalOpen={FindSalonProfileFilterModalOpen}
                setModalOpen={setFindSalonProfileFilterModalOpen}
                salonData = {salonData}
            />
            <SortByModal
                modalOpen={SortByModalOpen}
                setModalOpen={setSortByModalOpen}
                SortByOption={SortByOption}
                selectedSortBy={selectedSortBy}
                setselectedSortBy={setselectedSortBy}

            />
        </div>
    )
}

export default withTranslation()(SalonDetailsWorkers)