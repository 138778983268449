import React, { useEffect, useState } from 'react';
import StarIcon from '../icons/StarIcon';
import { cn } from '../../../../lib/utils';
import { PATH_AUTH } from '../../../routes/path';
import { useNavigate } from 'react-router-dom';

const Rating = ({ starCount, readOnly, selected, selectedStarColor, defaultColor, changeRating, starClass, groupClass, textClassName, wrapperClass, rate, showPeopleCount = true,salonData,handelClickEvent,...props }) => {
    const navigate = useNavigate();
    const [rating, setRating] = useState(3);
    const [selection, setSelection] = useState(0);
    useEffect(() => {
        setRating(selected);
    }, [selected]);

    useEffect(() => {
        if (changeRating) {
            changeRating(rating);
        }
    }, [rating]);

    return (
        // <div className={cn('pb-[12.5px] pt-[13px] border-[1.3px] border-success-100 rounded-[10px] h-full flex flex-col items-center justify-center', wrapperClass, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(navigateUrl, { state: { id: data.salon_id || data.id, data: data } })}>
        <div className={cn('pb-[12.5px] pt-[13px] border-[1.3px] border-success-100 rounded-[10px] h-full flex flex-col items-center justify-center', wrapperClass, handelClickEvent === false ? "" : "cursor-pointer")} onClick={() => handelClickEvent === false ? "" : navigate(PATH_AUTH.findSalonProfile, { state: { id: salonData?.id, data: salonData } })}>
            <div className={cn('flex gap-1.5 justify-center', groupClass)}>
                {[...Array(starCount || 5)].map((data, ind) => (
                    <span
                        key={"rating-icon-" + ind}
                        className={cn(
                            selection
                                ? selection >= ind + 1
                                    ? selectedStarColor
                                        ? selectedStarColor
                                        : "text-success-800"
                                    : defaultColor
                                        ? defaultColor
                                        : "text-light-750"
                                : rating >= ind + 1
                                    ? selectedStarColor
                                        ? selectedStarColor
                                        : "text-success-800"
                                    : defaultColor
                                        ? defaultColor
                                        : "text-light-750",
                            readOnly ? "" : "cursor-pointer leading-none block")}
                        onClick={() => !readOnly && setRating(ind + 1 || rating)}
                        onMouseOut={() => !readOnly && setSelection(null)}
                        onMouseOver={() => !readOnly && setSelection(ind + 1 || selection)}
                        {...props}
                    >
                        <StarIcon className={cn("w-6 h-6", starClass)} />
                    </span>
                ))}
            </div>
            {/* {rate && <p className={cn('text-blue-950 text-[15px] text-center mt-1.5' , textClassName)}>{rate}/5 {showPeopleCount && <span>(12K)</span>}</p>} */}

            {rate && (
                <p className={cn('text-blue-950 text-[15px] text-center mt-1.5', textClassName)}>
                    {rate?.overall ?? 0}/5 {showPeopleCount && <span>({rate?.total_rating > 1000 ? `${(rate?.total_rating / 1000).toFixed(2)}K` : rate?.total_rating})</span>}
                </p>
            )}
        </div>
    );
}

export default Rating;
