import React, { useState, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react';
import { withTranslation } from 'react-i18next';
import LabelWrap from '../../uiComponents/inputs/LabelWrap';
import CheckboxList from '../../uiComponents/common/CheckboxList';
import ServiceTableWithSelect from '../../uiComponents/common/ServiceTableWithSelect';
import ButtonPrimary from '../../uiComponents/buttons/ButtonPrimary';
import InputWithIcon from '../../uiComponents/inputs/InputWithIcon';
import RowWrap from '../../uiComponents/RowWrap';
import TextareaWrap from '../../uiComponents/inputs/TextareaWrap';
import InputRadioBox from '../../uiComponents/inputs/InputRadioBox';
import Divider from '../../uiComponents/Divider';
import Badge from '../../uiComponents/common/Badge';
import ChoosenServicesList from '../../uiComponents/common/ChoosenServicesList';

const AddNewBookingFirstStep = ({ t, editBookingId, activeStep, setActiveStep, handleModalClose }) => {

    const serviceType = [
        {
            id: "Hairdresser",
            name: "Hairdresser",
        },
        {
            id: "Nail",
            name: "Nail",
        },
        {
            id: "Hair_removal",
            name: "Hair removal",
        },
        {
            id: "Facial_treatment",
            name: "Facial treatment",
        },
    ]
    const subCategory = [
        {
            id: "Haircut",
            name: "Haircut",
        },
        {
            id: "Styling",
            name: "Styling",
        },
        {
            id: "Permanent",
            name: "Permanent",
        },
        {
            id: "Color",
            name: "Color",
        },
        {
            id: "Care_treatment",
            name: "Care_treatment",
        },
    ]

    const [selectedRows, setSelectedRows] = useState([]);


    const handleRowSelect = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter(id => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };


    return (

        <div className={`modal-body ${activeStep === 1 ? "block" : 'hidden'}`}>

            <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8" md={3}>
                <InputWithIcon
                    className="rounded-lg"
                    require
                    size="sm"
                    labelClassName={"text-[15px]"}
                    label={t('form_input_label.first_name')}
                    placeholder={t("input_filed_placeholder.customer_first_name")}
                />
                <InputWithIcon
                    className="rounded-lg"
                    require
                    size="sm"
                    labelClassName={"text-[15px]"}
                    label={t('form_input_label.last_name')}
                    placeholder={t("input_filed_placeholder.customer_last_name")}
                />
                <InputWithIcon
                    className="rounded-lg"
                    require
                    size="sm"
                    labelClassName={"text-[15px]"}
                    label={t('form_input_label.phone_number_')}
                    placeholder={t("input_filed_placeholder.contact_number")}
                />

            </RowWrap>

            <RowWrap className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 lg:gap-8 mt-3" md={2}>
                <TextareaWrap groupClassName={"col-span-2"} className="rounded-lg" rows={3} label="Notes" labelClassName="text-blue-950 text-[15px] font-medium" />

                <div className="meetime-form-group w-full">
                    <LabelWrap labelClassName="!mb-4 !text-[15px]" label={t('Favorite Customer')} require />
                    <div className="flex items-center justify-between flex-wrap !gap-4">
                        <InputRadioBox
                            name="Offer_Request"
                            id={t('radio_label.yes')}
                            text={t("radio_label.yes")}
                            labelClassName={"text-sm"}
                            value={1}
                        />
                        <InputRadioBox
                            name="Offer_Request"
                            id={t('radio_label.no')}
                            text={t("radio_label.no")}
                            labelClassName={"text-sm"}
                            value={0}
                        />
                    </div>
                </div>
            </RowWrap>

            <Divider className={"my-5"} />

            <div className='w-full flex justify-center items-center'>
                <Badge groupClassName={"mb-0"} text="Services" />
            </div>

            {editBookingId && <ChoosenServicesList label={t('form_input_label.choose_services')} />}

            <LabelWrap labelClassName={"font-medium mt-3"} label={<>Choose concerning category or service(s) <span className='text-text_light_gray font-400'>(from the service menu)</span></>}></LabelWrap>
            <div className="form-group  mt-3">
                <LabelWrap labelClassName={"font-medium mb-2"} label={t("form_input_label.service_type")}></LabelWrap>
                <CheckboxList inputLableClassName="border-success-950" selectAllId="one" serviceType={serviceType} />
            </div>
            <div className="form-group mt-3">
                <LabelWrap labelClassName={"font-medium mb-2"} label={t("form_input_label.categories")}></LabelWrap>
                <CheckboxList inputLableClassName="border-success-950" selectAllId="two" serviceType={subCategory} />
            </div>

            <div className="category_table_wrapper mt-3">
                <ServiceTableWithSelect />
            </div>
            <div className='w-full flex flex-col sm:flex-row justify-between gap-x-5 gap-y-3 mt-3'>
                <ButtonPrimary size="sm" className="w-full !bg-danger-950 border-danger-950 " onClick={handleModalClose}>Cancle</ButtonPrimary>
                <ButtonPrimary size="sm" className="w-full" onClick={() => setActiveStep(activeStep + 1)} >Continue</ButtonPrimary>
            </div>

        </div >
    )
}

export default withTranslation()(AddNewBookingFirstStep)
