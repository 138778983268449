import React from 'react';
import { cn } from '../../../../lib/utils';
import { useNavigate } from 'react-router-dom';

const AboutUsDeatils = ({ colored, salonData, groupClassName, workerData, textClassName }) => {
    return (
        <div className={cn(`border-[1.3px] border-success-100 rounded-[10px] h-full p-0 flex items-center justify-center ${colored && "bg-light-800"}`, groupClassName)}>
            <p className={cn('text-blue-950 text-sm text-center', textClassName)}>{salonData ? salonData : ""}</p>
        </div>
    );
}

export default AboutUsDeatils;
